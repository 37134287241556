import { createSlice } from '@reduxjs/toolkit';
import { addWeeks, parseISO, isWithinInterval } from 'date-fns';
import webServices from '../../services/webServices';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isTaskLoading: false,
  error: false,
  success: '',
  taskSuccess: '',
  isLoadingBtnUpld: false,
  taskList: [],
  allTaskList: [],
  taskListDash: [],
  isLoadingDash: false,
  taskStatusListDash: [],
  isLoadingStatusDash: false,
  newTaskList: []
};

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isLoadingDash = true;
    },

    startTaskLoading(state) {
      state.isTaskLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // SUCCESS MESSAGE
    success(state, action) {
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // CREAT SUCCESS
    createSuccess(state, action) {
      state.isTaskLoading = false;
      state.success = action.payload;
      state.taskSuccess = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // RESET LOADER
    resetLoader(state) {
      state.isLoading = false;
      state.isTaskLoading = false;
      state.isDeleteLoading = false;
    },

    // GET TASK LIST
    taskListSuccess(state, action) {
      state.isLoading = false;
      state.taskList = action.payload;
      state.success = '';
      state.taskSuccess = '';
    },

    // GET NEW TASK LIST
    newTaskListSuccess(state, action) {
      state.isLoading = false;
      state.newTaskList = action.payload;
      state.success = '';
      state.taskSuccess = '';
    },

    // DASHBOARD TASK LIST SUCCESS
    taskListDashboardSuccess(state, action) {
      state.success = '';
      state.taskSuccess = '';
      state.taskListDash = action.payload;
      state.isLoadingDash = false;
    },

    // DASHBOARD TASK STATUS LIST SUCCESS
    taskStatusListDashboardSuccess(state, action) {
      state.success = '';
      state.taskSuccess = '';
      state.taskStatusListDash = action.payload;
      state.isLoadingStatusDash = false;
    },

    // GET TASK LIST
    allTaskListSuccess(state, action) {
      state.isLoading = false;
      state.allTaskList = action.payload;
      state.success = '';
      state.taskSuccess = '';
    },

    // CREAT APPLIANCES SUCCESS
    createAppliancesSuccess(state, action) {
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.isTaskLoading = false;
      state.success = action.payload;
      state.taskSuccess = action.payload;
    },

    createTaskSuccess(state, action) {
      state.isDeleteLoading = false;
      state.isTaskLoading = false;
      state.taskSuccess = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  success,
  taskSuccess,
  hasError,
  startLoading,
  startDeleteLoading,
  startTaskLoading,
  taskListSuccess,
  allTaskListSuccess,
  createAppliancesSuccess,
  startLoadingUpld,
  createTaskSuccess,
  taskListDashboardSuccess,
  taskStatusListDashboardSuccess,
  newTaskListSuccess,
  createSuccess,
  resetLoader
} = slice.actions;

// ----------------------------------------------------------------------

// -------------------- TASKS  ------------------------------------------

export function createTask(data, id, addType, itemType) {
  return async (dispatch) => {
    dispatch(startTaskLoading());
    if (addType === 'residenceType') {
      itemType = { type: 'residenceType', icon: 'house' };
    }
    try {
      if (id) {
        data.itemType = itemType;
        const resp = await webServices.updateTasks(id, data);
        if (resp && resp.success === true) {
          dispatch(createSuccess('Modify success'));
        }
      } else {
        data.isReassign = false;
        const resp = await webServices.createTasks(data);
        if (resp && resp.success === true) {
          let url = 'http://localhost:3111/dashboard/';
          const getUsersForSendMailTask = await webServices.getUsersForSendMailTask({
            type: addType,
            isDeleted: false
          });
          if (addType === 'exteriorType') {
            url += 'exterior';
          } else if (addType === 'applianceType') {
            url += 'appliances';
          } else if (addType === 'utilityType') {
            url += 'utilities';
          } else if (addType === 'lawnLeisureType') {
            url += 'lawnLeisure';
          } else if (addType === 'interiorType') {
            url += 'interior';
          } else if (addType === 'furnitureType') {
            url += 'furniture';
          }
          const sendMailData = await {
            subject: resp.data.taskName,
            notes: `Hello from ResiView!<br>You were just assigned a new task to <b>${resp.data.taskName}</b> for your <b>${resp.data.descriptions}</b>. You can click on the link below to view your assigned task and help maintain your home!<br><br><a href="${url}"><button>View your task</button></a>`,
            email: getUsersForSendMailTask.data
          };
          await webServices.sendMail(sendMailData);
          dispatch(createSuccess('Create success and Mail Sent!!'));
        }
      }
      dispatch(resetLoader());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getTasks(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getTasksByItemId(id);
      if (resp && resp.success === true) {
        dispatch(taskListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const data = { otherFiles };
      const resp = await webServices.uploadFileService(id, data);
      if (resp && resp.success === true) {
        dispatch(createSuccess('Modify success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getTasksId() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getTasksId();
      if (resp && resp.success === true) {
        dispatch(taskListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function deleteTasks(id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await webServices.deleteItemsTask(id);
      if (resp && resp.success === true) {
        dispatch(createSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
      dispatch(resetLoader());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getTaskByItemIdWithUserStatus(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getTaskByItemIdWithUserStatus(id);
      if (resp && resp.success === true) {
        dispatch(taskListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function manageTaskStatus(taskStatus, id) {
  return async (dispatch) => {
    dispatch(startTaskLoading());
    try {
      if (id) {
        const resp = await webServices.updateTaskStatus(id, { taskStatus });
        if (resp && resp.success === true) {
          dispatch(createSuccess('Modify success'));
        } else {
          dispatch(hasError(resp.message));
        }
      }
      dispatch(resetLoader());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteTaskStatus(taskId) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await webServices.deleteTaskStatus(taskId);
      if (resp && resp.success === true) {
        dispatch(createSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
      dispatch(resetLoader());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getAllTask() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getAllTask({});
      if (resp && resp.success === true) {
        dispatch(allTaskListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getTaskForDashboard() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getRecentTasks();
      if (resp && resp.success === true) {
        const { data } = resp;
        const getData = [];
        // eslint-disable-next-line array-callback-return
        // @ts-ignore
        // eslint-disable-next-line array-callback-return
        data.map((row) => {
          const { startDate, expirationTime } = row;
          const startDateDateObject = parseISO(startDate);
          const expiryDateDateObject = addWeeks(startDateDateObject, expirationTime);
          const isCurrent = isWithinInterval(new Date(), { start: startDateDateObject, end: expiryDateDateObject });

          // if (isFuture) {
          //   getData.push(row);
          // }
          // else
          if (isCurrent) {
            getData.push(row);
          }
        });
        dispatch(taskListDashboardSuccess(getData));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
// -------------------- END TASKS  ---------------------------------------
