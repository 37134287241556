import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card } from '@mui/material';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  card: { border: '1px solid #ccc', backgroundColor: '#C6F3CD' },
  cardContent: { display: 'flex', justifyContent: 'center', position: 'relative' },
  link: { color: '#212B36', textDecoration: 'none' },
  image: {
    backgroundColor: '#D3D3D3',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '200px'
  },
  iconWrapper: {
    backgroundColor: '#C6F3CD',
    display: 'flex',
    height: 62,
    justifyContent: 'center',
    mask: 'url(/static/icons/shape-avatar.svg) no-repeat center',
    WebkitMask: 'url(/static/icons/shape-avatar.svg) no-repeat center',
    position: 'absolute',
    top: -36,
    width: 144
  },
  icon: { fontSize: '3em', padding: '10px' },
  textWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px'
  },
  addNew: { fontWeight: 'bold', fontSize: 20, textAlign: 'center', paddingTop: '20px' },
  type: { fontSize: 17, color: 'rgb(99, 115, 129)', textAlign: 'center' }
});

AddNewCard.propTypes = {
  type: PropTypes.string,
  createLink: PropTypes.string,
  imgSrc: PropTypes.string
};

export default function AddNewCard({ type, createLink = '', imgSrc }) {
  const classes = useStyles();
  const imgStyle = {
    backgroundImage: `url(${imgSrc})`,
    filter: 'grayscale(90%)'
  };

  return (
    <Card className={classes.card}>
      <Link to={createLink} className={classes.link}>
        <div className={classes.image} style={imgSrc ? imgStyle : {}} />
        <div className={classes.cardContent}>
          <div className={classes.iconWrapper}>
            <i className={`fa-thin fa-circle-plus ${classes.icon}`} />
          </div>
          <div className={classes.textWrapper}>
            <p className={classes.addNew}>Add New</p>
            <p className={classes.type}>{type}</p>
          </div>
        </div>
      </Link>
    </Card>
  );
}
