import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Container, Skeleton, Button, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import { BlogNewPostForm } from '../../../components/_dashboard/blog';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getPost, getComingSoonPostListSuccess, dataDelete } from '../../../redux/slices/post';
import PostListing from './PostListing';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

const TABLE_HEAD = [
  { id: 'name', label: 'Title', aliggetBrandsnRight: false },
  { id: 'description', label: 'Description', aliggetBrandsnRight: false },
  { id: 'content', label: 'Content', aliggetBrandsnRight: false },
  { id: 'coverImage', label: 'Cover Image', aliggetBrandsnRight: false },
  { id: '' }
];

export default function ComingSoonPostAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { postList, success, isLoading } = useSelector((state) => state.post);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');
  const isCreate = pathname.includes('create');

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getPost('comingSoon'));
  }, [dispatch, success, enqueueSnackbar]);

  const handleEdit = (row) => {
    navigate(PATH_DASHBOARD.admin.comingSoonEdit, { state: row });
  };

  const listingComponent = (
    <Page title="Coming Soon">
      <Container>
        <HeaderBreadcrumbs
          heading="Coming Soon"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Coming Soon' }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.admin.comingSoonCreate}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New
            </Button>
          }
        />
        {!isLoading ? (
          <PostListing
            tableHead={TABLE_HEAD}
            dataList={postList}
            listSuccess={getComingSoonPostListSuccess}
            dataDelete={dataDelete}
            handleEdit={handleEdit}
          />
        ) : null}
        {isLoading && SkeletonLoad}
      </Container>
    </Page>
  );

  const formComponent = (
    <Page title={isEdit ? 'Modify Coming Soon Post' : 'Create Coming Soon Post'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Coming Soon"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Coming Soon', href: PATH_DASHBOARD.admin.comingSoon },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />
        <BlogNewPostForm currentData={state} isEdit={isEdit} postType="comingSoon" />
      </Container>
    </Page>
  );

  return isEdit || isCreate ? formComponent : listingComponent;
}
