import { postRequest, putRequest } from './services';

const buildRoute = (path) => (input) => postRequest(path, input);

export const createHomeItem = buildRoute('/home-item');
export const createItem = buildRoute('/create-item');
export const editItem = (id, item) => putRequest(`/item/${id}`, item);
export const finishWalkthrough = buildRoute('/finish-walkthrough');
export const getUserByFirebaseId = buildRoute('/get-user-by-firebase-id');
export const registerHomeowner = buildRoute('/register-homeowner');
export const googleLogin = buildRoute('/google-login');
export const viewBlogPost = buildRoute('/view-blog-post');
export const viewItemList = buildRoute('/view-item-list');
export const viewTaskList = buildRoute('/view-task-list');
