// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from '../../redux/store';
import { modifyStatus } from '../../redux/slices/appliances';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function ConfirmLayout() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { success, error } = useSelector((state) => state.appliances);
  useEffect(() => {
    if (success) enqueueSnackbar(success, { variant: 'success' });
  }, [success, enqueueSnackbar]);

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    const sendData = {
      accountStatus: 'Enabled',
      token: params.token
    };
    dispatch(modifyStatus(sendData, navigate));
  }, [dispatch, navigate, params]);
  return (
    <RootStyle>
      <MainStyle> </MainStyle>
      <MainStyle>Hi,Thanks for confirming your email.</MainStyle>
    </RootStyle>
  );
}
