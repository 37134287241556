import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// firebase
import { firebase } from '../../contexts/firebase-config';

// utils
import axios from '../../utils/axios';

// routes
import { PATH_DASHBOARD, PATH_SERVICE_PRO } from '../../routes/paths';
import webServices from '../../services/webServices';
// const axios = require('axios');

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  allUsers: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  success: '',
  proUsersList: [],
  addressDetails: {},
  addressDetailsResiview: [],
  getZipCodeData: []
};

const apiURL = 'https://api.particlespace.com/api/v1';

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SUCCESS
    hasSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET USERS
    getAllUsersSuccess(state, action) {
      state.isLoading = false;
      state.allUsers = action.payload;
    },
    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    // GET PRO USERS
    getProUsersSuccess(state, action) {
      state.isLoading = false;
      state.proUsersList = action.payload;
      state.success = '';
    },

    // SEARCH ADDRESS SUCCESS
    searchAddressSuccess(state, action) {
      state.isLoading = false;
      state.addressDetails = action.payload;
      state.success = '';
    },
    searchAddressResiviewSuccess(state, action) {
      state.addressDetailsResiview = action.payload;
    },
    getZipCodeData(state, action) {
      state.getZipCodeData = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleFollow,
  deleteUser,
  hasSuccess,
  getProUsersSuccess,
  searchAddressSuccess,
  searchAddressResiviewSuccess,
  getZipCodeData
} = slice.actions;

// ----------------------------------------------------------------------

export function getUserUID() {
  return firebase.auth().currentUser.uid;
}

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ######################################################################
// ----------------------------------------------------------------------

export function getAllUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await webServices.getAllUsers();
      dispatch(slice.actions.getAllUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendMail(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await webServices.sendMail(data);
      dispatch(getAllUsers());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function finishedWalkthrough(navigate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const newData = {
        UID: getUserUID(),
        walkthroughStatus: true
      };
      const resp = await webServices.updateUser(newData);
      if (resp && resp.success === true) {
        localStorage.setItem('walkthroughStatus', true);
        dispatch(hasSuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.app);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function modifyUserAccount(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { UID, displayName, phoneNumber, isPublic, photoURL, oldPhotoURL } = data;
      const newData = {
        UID,
        displayName,
        isPublic,
        phoneNumber,
        photoURL
      };
      if (photoURL) {
        newData.oldPhotoURL = oldPhotoURL;
      }
      newData.phoneNumber = `+1${phoneNumber}`;
      const resp = await webServices.updateUser(newData);
      if (resp && resp.success === true) {
        resp.data.phoneNumber = resp.data.phoneNumber.slice(2);
        localStorage.setItem('displayName', resp.data.displayName);
        localStorage.setItem('photoURL', resp.data.photoURL);
        localStorage.setItem('phone_number', resp.data.phoneNumber);
        window.dispatchEvent(new Event('storage'));
        dispatch(slice.actions.getUsersSuccess(resp.data));
        dispatch(hasSuccess('Modify success'));
      } else {
        dispatch(slice.actions.hasError(resp.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function changePassword(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { oldPassword, newPassword } = data;
      const emailCred = await firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        oldPassword
      );
      await firebase
        .auth()
        .currentUser.reauthenticateWithCredential(emailCred)
        .then(() => {
          // User successfully reauthenticated.
          firebase
            .auth()
            .currentUser.updatePassword(newPassword)
            .then(() => {
              dispatch(hasSuccess('Password change success'));
            })
            .catch((error) => {
              dispatch(slice.actions.hasError(error));
            });
        })
        .catch((error) => {
          // Handle error.
          if (error.code === 'auth/wrong-password') {
            dispatch(slice.actions.hasError('Old Password is invalid'));
          }
        });
    } catch {
      dispatch(slice.actions.hasError('error during change password'));
    }
  };
}

// PRO USER ###############################################################

// -------------------- START COMPaNY -------------------------------------

export function getProUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const id = '634ea14f8e31f78fe524749a';
      const resp = await webServices.getProUsers(id, {});
      if (resp && resp.success === true) {
        dispatch(getProUsersSuccess(resp.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createProUsers(data, navigate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const id = '634ea14f8e31f78fe524749a';
      data.companyId = id;
      const resp = await webServices.createProUsers(data);
      if (resp && resp.success === true) {
        dispatch(hasSuccess('Create success'));
        navigate(PATH_SERVICE_PRO.pro.users);
      } else {
        dispatch(slice.actions.hasError());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function modifyProUsers(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const companyId = '634ea14f8e31f78fe524749a';
      data.companyId = companyId;
      const { id } = previousData;
      const resp = await webServices.updateProUsers(id, data);
      if (resp && resp.success === true) {
        dispatch(hasSuccess('Modify success'));
        navigate(PATH_SERVICE_PRO.pro.users);
      } else {
        dispatch(slice.actions.hasError());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProUsers(ids) {
  return async (dispatch) => {
    try {
      const id = '634ea14f8e31f78fe524749a';
      const resp = await webServices.deleteProUsers(id, { ids });
      if (resp && resp.success === true) {
        dispatch(hasSuccess('Delete success'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function authenticate() {
  return new Promise((resolve) => {
    const params = new URLSearchParams({
      publish_key: process.env.REACT_APP_PUBLISH_KEY,
      secret_key: process.env.REACT_APP_SECRET_KEY
    });
    fetch(`${apiURL}/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          resolve(data.data.token);
        });
      }
    });
  });
}

export function searchProperty2(address, dispatch) {
  return new Promise((resolve) =>
    authenticate().then((token) => {
      dispatch(slice.actions.startLoading());
      const params = new URLSearchParams(address);
      const url = new URL(`${apiURL}/property/validate/address`);
      url.search = params.toString();
      return fetch(url.toString(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: params
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            resolve(data.data.verified);
          });
        }
      });
    })
  );
}

export function searchProperty(values, dispatch) {
  return new Promise((resolve) => {
    try {
      authenticate().then((token) => {
        dispatch(slice.actions.startLoading());
        const { address, state, city, zipcode, abbreviation } = values;
        const params = new URLSearchParams({ address, state: abbreviation, city, zipcode });
        const url = new URL(`${apiURL}/property/search`);
        url.search = params.toString();
        return fetch(url.toString(), {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then((res) => {
            if (res.status === 200) {
              res.json().then(async (data) => {
                dispatch(searchAddressSuccess(data.data));
                const resp = await webServices.searchAddressFromResiview({ address, state, city, zipcode });
                if (resp && resp.success === true) {
                  dispatch(searchAddressResiviewSuccess(resp.data));
                }
                resolve(data.data);
              });
            } else {
              dispatch(slice.actions.hasError('error'));
            }
          })
          .catch((err) => {
            dispatch(slice.actions.hasError(err));
          });
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  });
}

export function searchAddressFromResiview(address) {
  return async (dispatch) => {
    try {
      const resp = await webServices.searchAddressFromResiview(address);
      if (resp && resp.success === true) {
        dispatch(searchAddressResiviewSuccess(resp.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getZipCode(data) {
  return async (dispatch) => {
    try {
      const resp = await webServices.getZipCode(data);
      dispatch(slice.actions.startLoading());
      if (resp && resp.success === true) {
        dispatch(getZipCodeData(resp.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------- END COMPaNY -------------------------------------

//
