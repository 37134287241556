import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField, FormControlLabel, Switch } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import { useDispatch } from '../../../redux/store';
import { createHome } from '../../../redux/slices/residence';

HomeNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentHome: PropTypes.object,
  isLoadingBtnHom: PropTypes.bool,
  success: PropTypes.string
};

export default function HomeNewForm({ isEdit, currentHome, isLoadingBtnHom, success }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const NewHomeSchema = Yup.object().shape({
    bathroomCount: Yup.string().required('Number of bathrooms is required'),
    bedroomCount: Yup.string().required('Number of bedrooms is required'),
    floorCount: Yup.string().required('Number of floors is required'),
    images: Yup.array(),
    lotSize: Yup.string(),
    moveInDate: Yup.date().required('Move in date is required'),
    squareFeet: Yup.string().required('Square feet is required'),
    yearBuilt: Yup.date().required('Year built is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bathroomCount: currentHome?.bathroomCount || '',
      bedroomCount: currentHome?.bedroomCount || '',
      floorCount: currentHome?.floorCount || '',
      lotSize: currentHome?.lotSize || '',
      moveInDate: currentHome?.moveInDate ? parseISO(currentHome?.moveInDate) : null,
      owner: currentHome?.owner || false,
      squareFeet: currentHome?.squareFeet || '',
      yearBuilt: currentHome?.yearBuilt ? parseISO(currentHome?.yearBuilt) : null,
      id: currentHome?.id || ''
    },
    validationSchema: NewHomeSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        dispatch(createHome(values));
      } catch (error) {
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    if (success) {
      enqueueSnackbar(success, { variant: 'success' });
    }
  }, [success, enqueueSnackbar]);

  const handleChangeBathroom = (event) => {
    const bathCount = event.target.value;
    const decimals = bathCount - Math.floor(bathCount);
    if (decimals !== 0 && decimals < 0.9) {
      setFieldValue('bathroomCount', Math.floor(bathCount) + 0.5);
    } else {
      setFieldValue('bathroomCount', bathCount);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <DatePicker
                    {...getFieldProps('yearBuilt')}
                    error={Boolean(touched.yearBuilt && errors.yearBuilt)}
                    helperText={touched.yearBuilt && errors.yearBuilt}
                    type="number"
                    views={['year']}
                    label="Year Built"
                    maxDate={new Date()}
                    value={values.yearBuilt}
                    onChange={(date) => {
                      setFieldValue('yearBuilt', date);
                    }}
                    slotProps={{ textField: { helperText: null } }}
                  />
                  <DatePicker
                    id="moveInDate"
                    name="moveInDate"
                    label="Move In Date"
                    maxDate={Date.now()}
                    value={values.moveInDate}
                    onChange={(date) => {
                      setFieldValue('moveInDate', date);
                    }}
                    slotProps={{ textField: { helperText: null } }}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('owner')} checked={values.owner} />}
                    label="I own this home"
                    sx={{ mt: 2 }}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <NumberFormat
                    {...getFieldProps('floorCount')}
                    thousandsGroupStyle="thousand"
                    decimalSeparator=""
                    label="Number of Floors"
                    variant="outlined"
                    customInput={TextField}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    decimalScale={10}
                    error={Boolean(touched.floorCount && errors.floorCount)}
                    helperText={touched.floorCount && errors.floorCount}
                    value={values.floorCount}
                    onChange={formik.handleChange}
                  />
                  <NumberFormat
                    {...getFieldProps('bedroomCount')}
                    thousandsGroupStyle="thousand"
                    decimalSeparator=""
                    label="Number of Bedrooms"
                    variant="outlined"
                    customInput={TextField}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    decimalScale={10}
                    error={Boolean(touched.bedroomCount && errors.bedroomCount)}
                    helperText={touched.bedroomCount && errors.bedroomCount}
                    value={values.bedroomCount}
                    onChange={formik.handleChange}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <NumberFormat
                    {...getFieldProps('bathroomCount')}
                    thousandsGroupStyle="thousand"
                    decimalScale={1}
                    decimalSeparator="."
                    label="Number of Bathrooms"
                    variant="outlined"
                    customInput={TextField}
                    type="text"
                    thousandSeparator={false}
                    allowNegative={false}
                    error={Boolean(touched.bathroomCount && errors.bathroomCount)}
                    helperText={touched.bathroomCount && errors.bathroomCount}
                    value={values.bathroomCount}
                    // onChange={formik.handleChange}
                    onChange={handleChangeBathroom}
                  />
                  <NumberFormat
                    {...getFieldProps('squareFeet')}
                    thousandsGroupStyle="thousand"
                    decimalScale={0}
                    decimalSeparator="."
                    label="Square feet of house"
                    variant="outlined"
                    customInput={TextField}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    error={Boolean(touched.squareFeet && errors.squareFeet)}
                    helperText={touched.squareFeet && errors.squareFeet}
                    value={values.squareFeet}
                    onChange={formik.handleChange}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <NumberFormat
                    {...getFieldProps('lotSize')}
                    thousandsGroupStyle="thousand"
                    decimalScale={2}
                    decimalSeparator="."
                    label="Size of Lot (acres)"
                    variant="outlined"
                    customInput={TextField}
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    error={Boolean(touched.lotSize && errors.lotSize)}
                    helperText={touched.lotSize && errors.lotSize}
                    value={values.lotSize}
                    onChange={formik.handleChange}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton className="button-primary" type="submit" variant="contained" loading={isLoadingBtnHom}>
                    {!isEdit ? 'Create Home' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
