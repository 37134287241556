import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Card, Grid, Stack, TextField, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO, getUnixTime } from 'date-fns';
// redux
import { useSelector, useDispatch } from '../../../redux/store';
import AlertDialog from './AlertDialog';
import { UploadMultiFile } from '../../../components/upload';
// ----------------------------------------------------------------------

const levelOffEffeor = ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Level 5'];

function expirationTime() {
  const exTime = [];
  for (let index = 1; index <= 13; index += 1) {
    exTime.push(index.toString());
  }
  return exTime;
}

const Frequency = ['Annually', 'Bi-Annually', 'Monthly', 'Quarterly'];

TaskForm.propTypes = {
  currentData: PropTypes.object,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  createData: PropTypes.func,
  deleteData: PropTypes.func,
  deleteLoading: PropTypes.bool,
  type: PropTypes.string,
  typeReference: PropTypes.string,
  typeId: PropTypes.string,
  itemType: PropTypes.object,
  addType: PropTypes.string
};

export default function TaskForm({
  currentData,
  error,
  isLoading,
  createData,
  deleteData,
  deleteLoading,
  itemType,
  addType
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isLoadingBtnUpld } = useSelector((state) => state.tasks);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [deleteFile, setDeleteFile] = useState(null);
  const [fileDialogStatus, setFileDialogStatus] = useState(false);

  const FormSchema = Yup.object().shape({
    taskName: Yup.string().required('Task Name is required'),
    frequency: Yup.string().required('Frequency is required'),
    levelOfEffort: Yup.string().required('Level of effort is required'),
    expirationTime: Yup.string().required('Expiration time is required'),
    startDate: Yup.string().required('Start date is required'),
    status: Yup.string().required('Status is required'),
    advertisement: Yup.string(),
    adlocation: Yup.string().required('Ad location is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      taskName: currentData?.taskName || '',
      frequency: currentData?.frequency || '',
      expirationTime: currentData?.expirationTime || '',
      levelOfEffort: currentData?.levelOfEffort || '',
      startDate: currentData?.startDate || null,
      descriptions: currentData?.descriptions || '',
      otherFiles: currentData?.otherFiles || [],
      status: currentData?.status || 'Active',
      advertisement: currentData?.advertisement || '',
      adlocation: currentData?.adlocation || 'TOP'
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        values.startDate = values.startDate ? format(parseISO(values.startDate), 'yyyy-MM-dd') : null;
        values.itemType = itemType;
        dispatch(createData(values, currentData?.id, addType, itemType));
        setSelectId(currentData?.id);
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    setUploadedFiles(currentData?.otherFiles || []);
    setFieldValue('otherFiles', currentData?.otherFiles);
  }, [currentData, setFieldValue]);
  const handleDelete = () => {
    setDialogStatus(true);
  };

  const handleClose = () => {
    setDialogStatus(false);
  };

  const handleConfrim = () => {
    const { id } = currentData;
    dispatch(deleteData(id));
    setDialogStatus(false);
    setSelectId(id);
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const handleCloseFile = () => {
    setFileDialogStatus(false);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      // eslint-disable-next-line prefer-const
      let otherFiles = [...values.otherFiles];
      setIsFileUploaded(true);
      acceptedFiles.map(async (file) => {
        const base64 = await convertBase64(file);
        otherFiles.push({ originalName: file.name, isImage: false, fileBase64: base64 });
        setFieldValue('otherFiles', otherFiles);
        const newFile = new File([file], `${getUnixTime(new Date())}_-_-${file.name}`, { type: file.type });
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
          originalName: file.name
        });
        setUploadedFiles((t) => [...t, newFile]);
        return true;
      });
    },
    [setFieldValue, values.otherFiles]
  );

  const handleRemove = (file) => {
    setFileDialogStatus(true);
    setDeleteFile(file);
  };

  const handleRemoveAll = () => {
    setFileDialogStatus(true);
    setDeleteFile(false);
  };

  const handleConfrimFile = () => {
    const { id, otherFiles } = currentData;
    if (id) {
      const fileURL = [];
      if (deleteFile) {
        const filteredItemss = uploadedFiles.filter((_file) => _file.originalName !== deleteFile.originalName);
        setUploadedFiles(filteredItemss);
        setFieldValue('otherFiles', filteredItemss);
        if (deleteFile?.downloadURL) fileURL.push(deleteFile?.downloadURL);
      } else if (otherFiles) {
        otherFiles.forEach((el) => {
          if (el.downloadURL) fileURL.push(el.downloadURL);
        });
        setFieldValue('otherFiles', otherFiles);
      }
    }
    setFileDialogStatus(false);
  };

  const onFileSubmit = () => {
    if (currentData?.id) {
      const id = currentData?.id;
      dispatch(createData(values, id, addType, itemType));
    }
  };
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2 }}
                >
                  <TextField
                    fullWidth
                    label="Task Name"
                    {...getFieldProps('taskName')}
                    error={Boolean(touched.taskName && errors.taskName)}
                    helperText={touched.taskName && errors.taskName}
                  />

                  <Autocomplete
                    fullWidth
                    value={values.frequency}
                    onChange={(e, value) => setFieldValue('frequency', value || '')}
                    options={Frequency}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Frequency"
                        variant="outlined"
                        error={Boolean(touched.frequency && errors.frequency)}
                        helperText={touched.frequency && errors.frequency}
                      />
                    )}
                  />

                  <DatePicker
                    label="Start Date"
                    // maxDate={Date.now()}
                    minDate={new Date()}
                    value={values.startDate ? parseISO(values.startDate) : undefined}
                    onChange={(date) => {
                      setFieldValue('startDate', date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                      />
                    )}
                  />

                  <Autocomplete
                    fullWidth
                    value={values.levelOfEffort}
                    onChange={(e, value) => setFieldValue('levelOfEffort', value || '')}
                    options={levelOffEffeor}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Level of effort"
                        variant="outlined"
                        error={Boolean(touched.levelOfEffort && errors.levelOfEffort)}
                        helperText={touched.levelOfEffort && errors.levelOfEffort}
                      />
                    )}
                  />

                  <Autocomplete
                    fullWidth
                    value={values.expirationTime}
                    onChange={(e, value) => setFieldValue('expirationTime', value || '')}
                    options={expirationTime()}
                    getOptionLabel={(option) => option && (option === '1' ? `${option} Week` : `${option} Weeks`)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Expiration Time"
                        variant="outlined"
                        error={Boolean(touched.expirationTime && errors.expirationTime)}
                        helperText={touched.expirationTime && errors.expirationTime}
                      />
                    )}
                  />

                  <Autocomplete
                    fullWidth
                    value={values.status}
                    onChange={(e, value) => setFieldValue('status', value || '')}
                    options={['Active', 'Inactive']}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                      />
                    )}
                  />

                  <TextField
                    fullWidth
                    label="Advertisement"
                    {...getFieldProps('advertisement')}
                    error={Boolean(touched.advertisement && errors.advertisement)}
                    helperText={touched.advertisement && errors.advertisement}
                  />
                  <Autocomplete
                    fullWidth
                    value={values.adlocation}
                    onChange={(e, value) => setFieldValue('adlocation', value || '')}
                    options={['TOP', 'BOTTOM', 'RIGHT']}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ad location"
                        variant="outlined"
                        error={Boolean(touched.adlocation && errors.adlocation)}
                        helperText={touched.adlocation && errors.adlocation}
                      />
                    )}
                  />

                  <TextField
                    {...getFieldProps('descriptions')}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    label="Descriptions"
                  />
                  <Box>
                    <UploadMultiFile
                      showPreview
                      maxSize={16777216}
                      // accept="image/*"
                      files={uploadedFiles}
                      onDrop={handleDrop}
                      onRemove={handleRemove}
                      onRemoveAll={handleRemoveAll}
                      onFileSubmit={onFileSubmit}
                      isLoadingBtnUpld={isLoadingBtnUpld}
                      text="Save copies of receipts or relevant documents"
                      error={Boolean(touched.images && errors.images)}
                      isFileUploaded={currentData?.id && isFileUploaded}
                    />
                    {touched.images && errors.images && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.images && errors.images}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                  <LoadingButton
                    color="error"
                    variant="contained"
                    onClick={handleDelete}
                    loading={selectId === currentData?.id && deleteLoading}
                  >
                    Delete Task
                  </LoadingButton>
                  <LoadingButton type="submit" variant="contained" loading={selectId === currentData?.id && isLoading}>
                    {currentData?.id ? 'Save Changes' : 'Create Task'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
      <AlertDialog
        dialogStatus={dialogStatus}
        handleClose={handleClose}
        handleConfrim={handleConfrim}
        name={values?.taskName}
      />
      <AlertDialog dialogStatus={fileDialogStatus} handleClose={handleCloseFile} handleConfrim={handleConfrimFile} />
    </FormikProvider>
  );
}
