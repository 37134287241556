import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Card, Grid, Stack, TextField } from '@mui/material';

// redux
import { useDispatch } from '../../../redux/store';
import { getRoles } from '../../../utils/role';

// ----------------------------------------------------------------------

NewForm.propTypes = {
  currentData: PropTypes.object,
  modifyData: PropTypes.func,
  error: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default function NewForm({ currentData, modifyData, error, isLoading }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const FormSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    accountStatus: Yup.string().required('Account status is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentData?.name || '',
      role: currentData?.role || '',
      accountStatus: currentData.disabled ? 'Disabled' : 'Enabled'
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        values.UID = currentData.id;
        values.walkthroughStatus = currentData.walkthroughStatus === '' ? false : currentData.walkthroughStatus;
        dispatch(modifyData(values, navigate));
      } catch (error) {
        // console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2 }}
                >
                  <TextField value={values.name} fullWidth disabled label="Email" />
                  <Autocomplete
                    fullWidth
                    value={values.role}
                    onChange={(e, value) => setFieldValue('role', value || '')}
                    options={getRoles()}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Role"
                        variant="outlined"
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                      />
                    )}
                  />
                  <Autocomplete
                    fullWidth
                    value={values.accountStatus}
                    onChange={(e, value) => setFieldValue('accountStatus', value || '')}
                    options={['Enabled', 'Disabled']}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Account Status"
                        variant="outlined"
                        error={Boolean(touched.accountStatus && errors.accountStatus)}
                        helperText={touched.accountStatus && errors.accountStatus}
                      />
                    )}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    Save Changes
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
