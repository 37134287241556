import React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { sortBy } from 'lodash';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  OutlinedInput,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableContainer,
  TablePagination,
  TextField,
  Toolbar
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'react-router-dom';
import { viewItemList } from '../../../api';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { boxShadow: 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px' },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const categories = ['Appliance', 'Exterior', 'Utility', 'Lawn And Leisure', 'Furniture', 'Interior'];

export default function ItemAdmin() {
  const [items, setItems] = React.useState([]);
  const [categoryFilter, setCategoryFilter] = React.useState('');
  const [nameSearchText, setNameSearchText] = React.useState('');
  const [sortProperty, setSortProperty] = React.useState('name');
  const [isSortAsc, setIsSortAsc] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    viewItemList().then(setItems);
  }, [setItems]);

  let filteredItems = items.filter(
    (item) => item.name.toLowerCase().includes(nameSearchText) && item.category.includes(categoryFilter)
  );
  filteredItems = sortBy(filteredItems, [sortProperty]);
  if (!isSortAsc) filteredItems.reverse();

  const itemsToShow = filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const onNameSearchChange = (event) => {
    setNameSearchText(event.target.value);
    setPage(0);
  };

  const onCategoryFilterChange = (_, value) => {
    setCategoryFilter(value || '');
    setPage(0);
  };

  const onPageChange = (_, newPage) => setPage(newPage);

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSortLabelClick = (property) => () => {
    if (property === sortProperty) setIsSortAsc(!isSortAsc);
    setSortProperty(property);
  };

  const createSortLabelProps = (property) => ({
    onClick: onSortLabelClick(property),
    active: sortProperty === property,
    direction: isSortAsc ? 'asc' : 'desc'
  });

  return (
    items && (
      <Page style={{ marginTop: '50px' }}>
        <Container>
          <HeaderBreadcrumbs
            heading="Item Admin"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
              { name: 'Admin', href: PATH_DASHBOARD.admin.role },
              { name: 'Items', href: PATH_DASHBOARD.admin.applianceType }
            ]}
            action={
              <Button
                variant="contained"
                component={Link}
                to={PATH_DASHBOARD.admin.itemsNew}
                startIcon={<Icon icon={plusFill} />}
              >
                Add New
              </Button>
            }
          />
          <Card>
            <RootStyle sx={{}}>
              <Autocomplete
                fullWidth
                onChange={onCategoryFilterChange}
                options={categories}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField fullWidth {...params} label="Category" variant="outlined" />}
                sx={{ marginLeft: 2 }}
              />
              <SearchStyle
                fullWidth
                value={nameSearchText}
                onChange={onNameSearchChange}
                placeholder="Search..."
                startAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
              />
            </RootStyle>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel {...createSortLabelProps('name')}>Name</TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel {...createSortLabelProps('category')}>Category</TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel {...createSortLabelProps('icon')}>Icon</TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemsToShow.map((item) => (
                      <TableRow hover key={item.id} tabIndex={-1} style={{ cursor: 'pointer' }}>
                        <TableCell>
                          <Link to={`/dashboard/admin/items/${item.id}`}>{item.name}</Link>
                        </TableCell>
                        <TableCell align="left">{item.category}</TableCell>
                        <TableCell align="center">
                          {item.icon && <i className={`fa-thin fa-${item.icon} fa-2x`} />}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              onPageChange={onPageChange}
              page={page}
              count={filteredItems.length}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={onRowsPerPageChange}
              component="div"
            />
          </Card>
        </Container>
      </Page>
    )
  );
}
