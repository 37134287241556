import { createSlice } from '@reduxjs/toolkit';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isLoadingBtnUpld: false,
  error: false,
  success: '',
  masterWarrantyTypeList: [],
  warrantyList: [],
  masterWarrantyList: []
};

const slice = createSlice({
  name: 'lawnLeisure',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MASTER LAWN & LEISURE TYPE LIST
    getWarrantyTypeListSuccess(state, action) {
      state.isLoading = false;
      state.masterWarrantyTypeList = action.payload;
      state.success = '';
    },

    getWarrantyListSuccess(state, action) {
      state.isLoading = false;
      state.masterWarrantyList = action.payload;
      state.success = '';
    },

    // CREAT LAWN & LEISURE SUCCESS
    createWarrantySuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // GET LAWN & LEISURE LIST
    getWarrantySuccess(state, action) {
      state.isLoading = false;
      state.warrantyList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  startDeleteLoading,
  getWarrantyTypeListSuccess,
  createWarrantySuccess,
  getWarrantyListSuccess,
  startLoadingUpld,
  getWarrantySuccess
} = slice.actions;

// -------------------- START WARRANTY TYPE ------------------------

export function getWarrantyType() {
  return async (dispatch) => {
    try {
      const params = { type: 'warrantyType' };
      const resp = await webServices.getServiceAll({ params });
      if (resp && resp.success === true) {
        dispatch(getWarrantyTypeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createWarrantyType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, icon, status } = data;
      const newData = {
        name,
        icon,
        type: 'warrantyType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.warrantyType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyWarrantyType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status, icon } = data;
      const newData = {
        name,
        icon,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.warrantyType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteWarrantyType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END START WARRANTY TYPE --------------

// -------------------- WARRANTY  ----------------------------

export function createWarranty(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createService(data);
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Create success'));
        navigate(PATH_DASHBOARD.general.warranty);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyWarranty(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, image } = previousData;
      if (data.image) {
        data.oldImage = image;
      }
      delete data.otherFiles;
      const resp = await webServices.updateService(id, data);
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.warranty);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getWarranty() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type: 'warrantyType' };
      const resp = await webServices.getService({ params });
      if (resp && resp.success === true) {
        dispatch(getWarrantySuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteWarranty(navigate, id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        isDeleted: true
      };
      const resp = await webServices.softDeleteService(id, data);
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Delete success'));
        navigate(PATH_DASHBOARD.general.warranty);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id, navigate) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const data = { otherFiles, type: 'warrantyType' };
      const resp = await webServices.fileUploadWarranty(id, data);
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Uploaded success'));
        dispatch(createWarrantySuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.warrantyEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFiles(fileURL, id, navigate) {
  return async (dispatch) => {
    try {
      const resp = await webServices.fileDeleteService(id, { fileURL });
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Deleted success'));
        dispatch(createWarrantySuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.warrantyEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getWarrantyData() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getWarranty();
      if (resp && resp.success === true) {
        dispatch(getWarrantyListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function modifyWarrantyData(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id } = previousData;
      delete data.image;
      const resp = await webServices.updateWarranty(id, data);
      if (resp && resp.success === true) {
        dispatch(getWarrantyListSuccess('Modify success'));
        await dispatch(getWarrantyData());
        navigate(PATH_DASHBOARD.general.warranty);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteWarrantyData(navigate, id, deleteFile) {
  // return async (dispatch) => {
  //   dispatch(startDeleteLoading());
  //   try {
  //     const data = {
  //       // isDeleted: true,
  //       ids: id,
  //       fileURL: deleteFile
  //     };
  //     const resp = await webServices.deleteWarranty(data);
  //     if (resp && resp.success === true) {
  //       dispatch(getWarrantyListSuccess('Delete success'));
  //       await dispatch(getWarrantyData());
  //       navigate(PATH_DASHBOARD.general.warranty);
  //     }
  //   } catch (error) {
  //     dispatch(hasError(error));
  //   }
  // };
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        // isDeleted: true,
        fileURL: deleteFile
      };
      const resp = await webServices.deleteWarranty(id, data);
      if (resp && resp.success === true) {
        dispatch(getWarrantyListSuccess('Delete success'));
        await dispatch(getWarrantyData());
        navigate(PATH_DASHBOARD.general.warranty);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createWarrantyData(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      data.industryType = data.name;
      delete data.name;
      delete data.image;
      const resp = await webServices.createWarranty(data);
      if (resp && resp.success === true) {
        dispatch(createWarrantySuccess('Create success'));
        navigate(PATH_DASHBOARD.general.warranty);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
// -------------------- END WARRANTY  -----------------------------------
