import { createSlice } from '@reduxjs/toolkit';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  error: false,
  success: '',
  postList: [],
  relatedItemList: [],
  newsList: [],
  comingSoonPostList: []
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREAT SUCCESS
    createSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET POST LIST
    getPostListSuccess(state, action) {
      state.isLoading = false;
      state.postList = action.payload;
      state.success = '';
      state.isDeleteLoading = false;
    },

    // GET COMING SOON POST LIST
    getComingSoonPostListSuccess(state, action) {
      state.isLoading = false;
      state.comingSoonPostList = action.payload;
      state.success = '';
    },

    getNewPostListSuccess(state, action) {
      state.isLoading = false;
      state.newsList = action.payload;
      state.success = '';
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  startDeleteLoading,
  createSuccess,
  getComingSoonPostListSuccess,
  getPostListSuccess,
  getNewPostListSuccess
} = slice.actions;

// -------------------- POST  --------------------------------------

export function getPost(type) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type };
      const resp = await webServices.getPost({ params });
      if (resp && resp.success === true) {
        dispatch(getPostListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createPost(data, navigate, postType) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createPost(data);
      if (resp && resp.success === true) {
        dispatch(createSuccess('Create success'));
        if (postType === 'comingSoon') {
          navigate(PATH_DASHBOARD.admin.comingSoon);
        } else if (postType === 'news') {
          navigate(PATH_DASHBOARD.admin.news);
        } else {
          navigate(PATH_DASHBOARD.admin.blog);
        }
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyPost(data, navigate, previousData, postType) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, coverImage } = previousData;
      if (data.coverImage) {
        data.oldCoverImage = coverImage;
      }
      const resp = await webServices.updatePost(id, data);
      if (resp && resp.success === true) {
        dispatch(createSuccess('Modify success'));
        if (postType === 'comingSoon') {
          navigate(PATH_DASHBOARD.admin.comingSoon);
        } else if (postType === 'news') {
          navigate(PATH_DASHBOARD.admin.news);
        } else {
          navigate(PATH_DASHBOARD.admin.blog);
        }
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function dataDelete(records) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const ids = [];
      records.forEach((el) => {
        const { id } = el;
        ids.push(id);
      });
      const resp = await webServices.deletePost({ ids });
      if (resp && resp.success === true) {
        dispatch(createSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getPostPublically(type) {
  return async (dispatch) => {
    dispatch(getPostListSuccess([]));
    dispatch(startLoading());
    try {
      const params = { type };
      const resp = await webServices.getPostUplically({ params });
      if (resp && resp.success === true) {
        dispatch(getPostListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createPostView(id, type) {
  return async (dispatch) => {
    try {
      await webServices.createPostView({ postId: id, type });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END POST  -----------------------------------
