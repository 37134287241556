import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Container } from '@mui/material';
//
import { varWrapEnter } from '../../animate';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundImage: 'url(/static/about/3D.jpg)',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'right',
    display: 'flex'
  }
}));

// ----------------------------------------------------------------------
AboutHero.propTypes = {
  homeRef: PropTypes.object
};

export default function AboutHero({ homeRef }) {
  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter} ref={homeRef}>
      <Container maxWidth="lg" sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle />
      </Container>
    </RootStyle>
  );
}
