import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha, useTheme, experimentalStyled as styled } from '@mui/material/styles';
import { CardContent, Box, Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { varFadeInRight, MotionContainer } from '../../animate';
import { CarouselControlsPaging1, CarouselControlsArrowsBasic1 } from '../../carousel';

const CarouselImgStyle = styled('img')(({ theme }) => ({
  height: 280,
  width: '100%',
  objectFit: 'cover',
  [theme.breakpoints.up('xl')]: {
    height: 320
  }
}));

AppFeatured.propTypes = {
  homeHacksList: PropTypes.array
};

CarouselItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool
};

function CarouselItem({ item, isActive }) {
  const { image, hack, category, relatedItem } = item;
  return (
    <RouterLink to="#">
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
          }}
        />
        {image ? <CarouselImgStyle alt={hack} src={image} /> : <CarouselImgStyle />}
        <CardContent
          sx={{
            bottom: 0,
            width: '100%',
            textAlign: 'left',
            position: 'absolute',
            color: 'common.white'
          }}
        >
          <MotionContainer open={isActive}>
            <motion.div variants={varFadeInRight}>
              <Typography
                variant="overline"
                sx={{
                  mb: 1,
                  opacity: 0.48,
                  display: 'block'
                }}
              >
                {category}
              </Typography>
            </motion.div>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h5" gutterBottom noWrap>
                {relatedItem}
              </Typography>
            </motion.div>
            <motion.div variants={varFadeInRight}>
              <Typography variant="body2">{hack}</Typography>
            </motion.div>
          </MotionContainer>
        </CardContent>
      </Box>
    </RouterLink>
  );
}

const useStyles = makeStyles({
  card: {
    position: 'relative'
  },
  title: {
    color: 'white',
    position: 'absolute',
    top: 12,
    width: '100%',
    textAlign: 'center',
    opacity: 0.8
  }
});

export default function AppFeatured({ homeHacksList }) {
  const theme = useTheme();
  const carouselRef = useRef(null);
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(theme.direction === 'rtl' ? homeHacksList.length - 1 : 0);

  const settings = {
    speed: 4000,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    onmouseover: false,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (_, next) => setCurrentIndex(next),
    ...CarouselControlsPaging1({
      color: 'primary.main',
      sx: {
        top: theme.spacing(3),
        left: theme.spacing(3),
        bottom: 'auto',
        right: 'auto'
      }
    })
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Card className={classes.card}>
      <Slider ref={carouselRef} {...settings}>
        {homeHacksList.map((item, index) => (
          <CarouselItem key={item.hack} item={item} isActive={index === currentIndex} />
        ))}
      </Slider>

      <CarouselControlsArrowsBasic1 onNext={handleNext} onPrevious={handlePrevious} />
      <h2 className={classes.title}>Home Hacks</h2>
    </Card>
  );
}
