import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
// redux
// routes
// components
// import { AddNewCard, ContactCard } from '../../components/_dashboard/user/cards';
import { makeStyles } from '@mui/styles';
import {
  Card,
  Table,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Skeleton
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import { useDispatch, useSelector } from '../../redux/store';
import { getContact, deleteContact } from '../../redux/slices/contact';
import { PATH_DASHBOARD } from '../../routes/paths';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user/list';
import AlertDialog from './admin/AlertDialog';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <TableRow>
    {[...Array(8)].map((_, index) => (
      <TableCell key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: '50px', borderRadius: 2 }} />
      </TableCell>
    ))}
  </TableRow>
);

const useStyles = makeStyles(() => ({
  iconSize: {
    width: 36,
    objectFit: 'contain',
    zIndex: 11
    // position: 'static',
    // borderRadius: '0',
    // transform: 'translateY(-43%)'
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function Contacts() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { contactList, success, isLoading } = useSelector((state) => state.contact);
  const [deleteData, setDeleteData] = useState({});

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contactList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.companyName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const TABLE_HEAD = [
    { id: 'companyName', label: 'Company Name', alignRight: false },
    { id: 'industryTypeIcon', label: 'Industry', alignRight: false },
    { id: 'industryType', label: 'Industry Name', alignRight: false },
    { id: 'notes', label: 'Notes', alignRight: false },
    { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
    { id: 'pointOfContact', label: 'Point Of Contact', alignRight: false },
    { id: 'rating', label: 'Rating', alignRight: false },
    { id: 'website', label: 'Website', alignRight: false },
    { id: '' }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getContact());
  }, [dispatch, success, enqueueSnackbar]);

  const handleEdit = (contact) => () => {
    navigate(PATH_DASHBOARD.general.contactsEdit, { state: contact });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contactList.length) : 0;

  const filteredUsers = applySortFilter(contactList, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const classes = useStyles();
  const handleConfrim = () => {
    dispatch(deleteContact(navigate, deleteData.id));
    setDialogStatus(false);
    setSelected([]);
    setDeleteData({});
  };

  const handleClose = () => {
    setDialogStatus(false);
  };
  return (
    <Page title="Contact">
      <Container>
        <HeaderBreadcrumbs
          heading="Contact List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Contacts', href: PATH_DASHBOARD.general.contacts },
            { name: 'List' }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.general.contactsCreate}
              startIcon={<Icon icon={plusFill} />}
            >
              New Contact
            </Button>
          }
        />
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            // onDelete={handleDeleteSelected}
          />
          {/* <HeaderBreadcrumbs
          heading="Exterior"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.general.home }, { name: 'Exterior' }]}
        /> */}
          {/* <Grid container spacing={3}> */}
          {/* <Grid key="new_exterior" item xs={12} sm={6} md={4}>
            <AddNewCard type="Exterior" createLink={PATH_DASHBOARD.general.exteriorCreate} />
          </Grid> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={contactList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((contact) => {
                    const { id } = contact;
                    const isItemSelected = selected.indexOf(id) !== -1;
                    const rating = contact.rating === 'ThumbsUp' ? 'thumbs-up' : 'thumbs-down';
                    // let notes = contact.notes.length > 100 ? contact.notes.substring(0, 100) + '...' : contact.notes;
                    return (
                      <TableRow
                        onClick={handleEdit(contact)}
                        contact={contact}
                        hover
                        key={contact.id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                          // checked={isItemSelected}
                          // onChange={(event) => handleClick(event, companyName)}
                          />
                        </TableCell>
                        <TableCell align="left">{contact.companyName}</TableCell>
                        <TableCell align="left">
                          <i className={`fa-thin fa-${contact.industryType.icon} ${classes.iconSize}`} />
                        </TableCell>
                        <TableCell align="left">{contact.industryType.name}</TableCell>
                        <TableCell align="left" style={{ height: 100 }}>
                          {contact.notes.substring(0, 100)}
                          {contact.notes.length > 100 ? '...' : ''}
                        </TableCell>
                        <TableCell align="left">{contact.countryCode.phone + contact.phoneNumber}</TableCell>
                        <TableCell align="left">{contact.pointOfContact}</TableCell>
                        <TableCell align="left">
                          <i className={`fa-thin fa-${rating} ${classes.iconSize}`} />
                        </TableCell>
                        <TableCell align="left">{contact.website}</TableCell>
                        {/* <TableCell align="right">
                        <ContactMoreMenu
                          onDelete={() => handleDelete(contact)}
                          onEdit={() => handleEdit(contact)}
                          itemsToEdit={contact}
                          userName={contact.companyName}
                        />
                      </TableCell> */}
                      </TableRow>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {isLoading && SkeletonLoad}
                  {isUserNotFound && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {/* </Grid> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={contactList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <AlertDialog
            dialogStatus={dialogStatus}
            handleClose={handleClose}
            handleConfrim={handleConfrim}
            name={deleteData?.name}
          />
        </Card>
      </Container>
    </Page>
  );
}
