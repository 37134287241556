import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FormikProvider, Form, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { postRequest } from '../services';
import Page from '../components/Page';

export default function DashboardAdminItemsItemIdTasksNew() {
  const { enqueueSnackbar } = useSnackbar();
  const { itemId } = useParams();
  const task = {
    itemId,
    name: '',
    description: '',
    weeksUntilExpiration: 4,
    frequency: 'Monthly',
    startDate: new Date(),
    levelOfEffort: 1,
    advertisementHtml: '',
    advertisementLocation: ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: task,
    onSubmit: async (values) => {
      try {
        await postRequest(`/item/${itemId}/task`, values);
        enqueueSnackbar('Task saved', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  });

  return (
    <Page>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <TextField {...formik.getFieldProps('name')} label="Name" fullWidth />
          <TextField {...formik.getFieldProps('description')} label="Description" fullWidth />
          <FormControl fullWidth>
            <InputLabel id="weeks-until-expiration">Weeks Until Expiration</InputLabel>
            <Select
              name="weeksUntilExpiration"
              {...formik.getFieldProps('weeksUntilExpiration')}
              labelId="weeks-until-expiration"
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={13}>13</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="frequency">Frequency</InputLabel>
            <Select name="frequency" {...formik.getFieldProps('frequency')} labelId="frequency">
              <MenuItem value="Annually">Annually</MenuItem>
              <MenuItem value="Bi-Annually">Bi-Annually</MenuItem>
              <MenuItem value="Quarterly">Quarterly</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
          <DatePicker {...formik.getFieldProps('startDate')} label="Start Date" className="w-full" />
          <FormControl fullWidth>
            <InputLabel id="level-of-effort">Level of Effort</InputLabel>
            <Select name="levelOfEffort" {...formik.getFieldProps('levelOfEffort')} labelId="level-of-effort">
              <MenuItem value={1}>Level 1</MenuItem>
              <MenuItem value={2}>Level 2</MenuItem>
              <MenuItem value={3}>Level 3</MenuItem>
              <MenuItem value={4}>Level 4</MenuItem>
              <MenuItem value={5}>Level 5</MenuItem>
            </Select>
          </FormControl>
          <TextField {...formik.getFieldProps('advertisementHtml')} label="Advertisement HTML" fullWidth />
          <FormControl fullWidth>
            <InputLabel id="advertisement-location">Advertisement Location</InputLabel>
            <Select
              name="advertisementLocation"
              {...formik.getFieldProps('advertisementLocation')}
              labelId="advertisement-location"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="TOP">TOP</MenuItem>
              <MenuItem value="BOTTOM">BOTTOM</MenuItem>
              <MenuItem value="LEFT">LEFT</MenuItem>
              <MenuItem value="RIGHT">RIGHT</MenuItem>
            </Select>
          </FormControl>
          <button className="button-primary" type="submit">
            Save
          </button>
        </Form>
      </FormikProvider>
    </Page>
  );
}
