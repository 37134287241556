import * as Yup from 'yup';
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/material.css';

// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  Checkbox,
  FormHelperText,
  FormControl,
  FormGroup
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadingButton } from '@mui/lab';
import ReCAPTCHA from 'react-google-recaptcha';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
// config
import { captchaConfig } from '../../../config';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { register } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  // const [phone, setPhone] = useState('');

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone: Yup.string().matches(new RegExp('[0-9]{7}'), 'Phone number is not valid'),
    password: Yup.string().required('Password is required'),
    eula: Yup.boolean().oneOf([true], 'You must accept the terms and conditions')
    // captcha: Yup.string().required('Captcha is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      eula: false,
      captcha: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await register(values.email, values.password, values.firstName, values.lastName, values.phone, values.eula);
        enqueueSnackbar('Register success. Email verification link sent to you email address.', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
          resetForm();
          setFieldValue('captcha', '');
          setFieldValue('eula', false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, setFieldValue, isSubmitting, getFieldProps } = formik;

  const onChangeCaptcha = (value) => {
    setFieldValue('captcha', value);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="phone"
            type="phone"
            label="Phone Number"
            {...getFieldProps('phone')}
            InputProps={{
              startAdornment: <InputAdornment position="start">+1</InputAdornment>
            }}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <FormControl error={Boolean(touched.eula && errors.eula)}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" {...getFieldProps('eula')} />}
                label="I agree to the terms and conditions"
              />
              <FormHelperText>{touched.eula && errors.eula}</FormHelperText>
            </FormGroup>
          </FormControl>

          <FormControl error={Boolean(touched.captcha && errors.captcha)}>
            <FormGroup>
              <ReCAPTCHA sitekey={captchaConfig} onChange={onChangeCaptcha} />
              <FormHelperText>{touched.captcha && errors.captcha}</FormHelperText>
            </FormGroup>
          </FormControl>

          <LoadingButton
            className="button-primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
