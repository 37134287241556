import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { Container, Skeleton, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import RoleNewForm from './RoleNewForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { modifyRole, deleteRole, getUsers, getUserListSuccess } from '../../../redux/slices/appliances';
import UserListing from './UserListing';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

const TABLE_HEAD = [
  { id: 'email', label: 'Email', aliggetBrandsnRight: false },
  { id: 'username', label: 'User Name', aliggetBrandsnRight: false },
  { id: 'role', label: 'Role', aliggetBrandsnRight: false },
  { id: 'emailVerified', label: 'Email Verified', aliggetBrandsnRight: false },
  { id: 'disabled', label: 'Account Status', aliggetBrandsnRight: false },
  { id: 'lastSignInTime', label: 'Last Login', aliggetBrandsnRight: false },
  { id: 'creationTime', label: 'Create Date', aliggetBrandsnRight: false },
  { id: '' }
];

export default function RoleAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { userList, success, error, isLoading } = useSelector((state) => state.appliances);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getUsers());
  }, [dispatch, success, enqueueSnackbar]);

  const handleEdit = (row) => {
    navigate(PATH_DASHBOARD.admin.roleEdit, { state: row });
  };

  const listingComponent = (
    <Page title="User Role">
      <Container>
        <HeaderBreadcrumbs
          heading="User Role"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'User', href: PATH_DASHBOARD.admin.role }
          ]}
        />
        {userList.length > 0 ? (
          <UserListing
            tableHead={TABLE_HEAD}
            title="User Role"
            dataList={userList}
            listSuccess={getUserListSuccess}
            dataDelete={deleteRole}
            handleEdit={handleEdit}
            isAdmin
          />
        ) : (
          SkeletonLoad
        )}
      </Container>
    </Page>
  );

  const formComponent = (
    <Page title="Modify">
      <Container>
        <HeaderBreadcrumbs
          heading="User Role"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'User', href: PATH_DASHBOARD.admin.role },
            { name: 'Edit' }
          ]}
        />
        <RoleNewForm currentData={state} modifyData={modifyRole} error={error} isLoading={isLoading} />
      </Container>
    </Page>
  );

  return isEdit ? formComponent : listingComponent;
}
