import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Card, Grid, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
// redux
import { useSelector, useDispatch } from '../../../redux/store';
import { getCompanyType } from '../../../redux/slices/companyType';
import { PATH_SERVICE_PRO } from '../../../routes/paths';

// ----------------------------------------------------------------------

const COMPANY_SIZE = ['1-5', '6-15', '16-30', '31-50', '51-75', '76-100', '101-150', '151-200', '200+'];

CompanyForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  createData: PropTypes.func,
  modifyData: PropTypes.func
};

export default function CompanyForm({ isEdit, currentData, createData, modifyData }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { companyTypeList, error, success, isLoading } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getCompanyType());
  }, [dispatch]);

  const NewSchema = Yup.object().shape({
    name: Yup.string().required('Company name is required'),
    companyType: Yup.string().required('Company type is required'),
    companySize: Yup.string().required('Company size is required'),
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('Zip code is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    yearFounded: Yup.string().required('Year founded is required'),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .required('Website is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentData?.name || '',
      companyType: currentData?.companyType || '',
      companySize: currentData?.companySize || '',
      address: currentData?.address || '',
      state: currentData?.state || '',
      city: currentData?.city || '',
      zipCode: currentData?.zipCode || '',
      website: currentData?.website || '',
      phoneNumber: currentData?.phoneNumber || '',
      yearFounded: currentData?.yearFounded || ''
    },
    validationSchema: NewSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        values.yearFounded = values.yearFounded ? format(parseISO(values.yearFounded), 'yyyy') : null;
        if (isEdit) {
          dispatch(modifyData(values, navigate, currentData, PATH_SERVICE_PRO.pro.company));
        } else {
          dispatch(createData(values, navigate));
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (success) enqueueSnackbar(success, { variant: 'success' });
  }, [success, enqueueSnackbar]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2 }}
            >
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  disabled
                  fullWidth
                  label="Company Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Autocomplete
                  fullWidth
                  value={{ name: values.companyType }}
                  onChange={(e, value) => setFieldValue('companyType', value?.name || '')}
                  options={companyTypeList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Type"
                      variant="outlined"
                      error={Boolean(touched.companyType && errors.companyType)}
                      helperText={touched.companyType && errors.companyType}
                    />
                  )}
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Autocomplete
                  fullWidth
                  value={values.companySize}
                  onChange={(e, value) => setFieldValue('companySize', value || '')}
                  options={COMPANY_SIZE}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Size"
                      variant="outlined"
                      error={Boolean(touched.companySize && errors.companySize)}
                      helperText={touched.companySize && errors.companySize}
                    />
                  )}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Address"
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="State"
                  {...getFieldProps('state')}
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="City"
                  {...getFieldProps('city')}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Zip Code"
                  {...getFieldProps('zipCode')}
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  helperText={touched.zipCode && errors.zipCode}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Website"
                  {...getFieldProps('website')}
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  {...getFieldProps('phoneNumber')}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <DatePicker
                  {...getFieldProps('yearFounded')}
                  error={Boolean(touched.yearFounded && errors.yearFounded)}
                  helperText={touched.yearFounded && errors.yearFounded}
                  type="number"
                  views={['year']}
                  label="Year Founded"
                  maxDate={Date.now()}
                  value={values.yearFounded}
                  onChange={(date) => {
                    setFieldValue('yearFounded', date);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} helperText={null} />}
                />
              </Stack>
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                {!isEdit ? 'Create Company' : 'Save Changes'}
              </LoadingButton>
            </Box>
          </Card>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
