import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// material
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Box
} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
// components
import TasksForm from './TasksForm';
import { getTasksId, taskListSuccess, createTask, deleteTasks } from '../../../redux/slices/tasks';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user/list';
import AlertDialog from './AlertDialog';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

HomeHacksListing.propTypes = {
  tableHead: PropTypes.array,
  dataList: PropTypes.array,
  listSuccess: PropTypes.func,
  handleEdit: PropTypes.func,
  dataDelete: PropTypes.func
};

export default function HomeHacksListing({ tableHead, dataList, listSuccess, handleEdit, dataDelete }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const { taskList, isDeleteLoading, taskSuccess, isTaskLoading } = useSelector((state) => state.tasks);
  const { success } = useSelector((state) => state.homeHacks);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('home-hacks');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    if (taskSuccess !== '') {
      enqueueSnackbar(taskSuccess, { variant: 'success' });
    }
    if (isEdit) {
      dispatch(getTasksId());
    }
  }, [dispatch, success, enqueueSnackbar, taskSuccess, isEdit, state]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDelete = (row) => {
    setDeleteData(row);
    setDialogStatus(true);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

  const filteredUsers = applySortFilter(dataList, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleClose = () => {
    setDialogStatus(false);
  };

  const handleConfrim = () => {
    if (selected.length > 0) {
      dataList = dataList.filter((data) => !selected.includes(data.id));
      dispatch(dataDelete(selected));
    } else {
      dataList = dataList.filter((data) => data !== deleteData);
      dispatch(dataDelete([deleteData]));
    }
    dispatch(listSuccess(dataList));
    setDialogStatus(false);
    setSelected([]);
    setDeleteData({});
  };

  const handleDeleteSelected = () => {
    setDialogStatus(true);
  };

  const handleAddTask = () => {
    const newData = [{}, ...taskList];
    dispatch(taskListSuccess(newData));
  };

  const taskForm = () =>
    taskList.map((el, i) => (
      <div key={i}>
        <TasksForm
          currentData={el}
          createData={createTask}
          deleteData={deleteTasks}
          deleteLoading={isDeleteLoading}
          isLoading={isTaskLoading}
          itemType={state}
          addType="residenceType"
        />
      </div>
    ));
  return (
    <Page title="Master Residence">
      <Container>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDelete={handleDeleteSelected}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={dataList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, category, image, relatedItem, hack, status } = row;
                    const isItemSelected = selected.indexOf(row) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2">{hack}</Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {relatedItem}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {category}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {image ? <img alt="img" src={image} style={{ width: 70 }} /> : null}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={status === 'Active' ? 'success' : 'error'}
                          >
                            {status}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            onDelete={() => handleDelete(row)}
                            onEdit={() => handleEdit(row)}
                            userName={name}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <AlertDialog
            dialogStatus={dialogStatus}
            handleClose={handleClose}
            handleConfrim={handleConfrim}
            name={deleteData?.name}
          />
        </Card>
        {isEdit && (
          <Box sx={{ mt: 4 }}>
            <Button variant="contained" onClick={handleAddTask}>
              Add Task
            </Button>
          </Box>
        )}
        {isEdit && taskForm()}
      </Container>
    </Page>
  );
}
