import { useLocation } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// components
import ContactNewForm from '../../components/_dashboard/contact/ContactNewForm';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

export default function ContactCreate() {
  // const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');

  return (
    <Page title={isEdit ? 'Modify Contact' : 'Create Contact'}>
      <Container>
        <HeaderBreadcrumbs
          heading={isEdit ? 'Modify Contact' : 'Create a new Contact'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Contact', href: PATH_DASHBOARD.general.contacts },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />

        <ContactNewForm isEdit={isEdit} currentContact={state} />
      </Container>
    </Page>
  );
}
