import React from 'react';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { FaqsList } from '../components/_external-pages/faqs';
import { MotionInView, varFadeInDown } from '../components/animate';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {}
}));

// ----------------------------------------------------------------------

Faqs.propTypes = {
  faqsRef: PropTypes.object
};

export default function Faqs({ faqsRef }) {
  return (
    <RootStyle id="faqs" ref={faqsRef} sx={{ border: '1px solid transparent' }}>
      <Container sx={{ mt: 15, mb: 10 }}>
        <MotionInView variants={varFadeInDown}>
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            Frequently Asked Questions
          </Typography>
        </MotionInView>
        <FaqsList />
      </Container>
    </RootStyle>
  );
}
