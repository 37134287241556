import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Card, Grid, Stack, TextField, Button, FormHelperText, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { getUnixTime } from 'date-fns';
// redux
import { useSelector, useDispatch } from '../../../redux/store';
import { getRelatedItem, createHomeHacks, modifyHomeHacks } from '../../../redux/slices/homeHacks';

// ----------------------------------------------------------------------
const CATEGORY = {
  Appliance: 'applianceType',
  Exterior: 'exteriorType',
  Utility: 'utilityType',
  Furniture: 'furnitureType',
  Industry: 'industryType',
  Interior: 'interiorType',
  'Lawan & Leisure': 'lawnLeisureType'
};

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  // padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

NewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object
};

export default function NewForm({ currentData, isEdit }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { relatedItemList, error, isLoading } = useSelector((state) => state.homeHacks);
  const [uploadedImg, setUploadedImg] = useState(currentData?.image || null);
  const [imgTypeError, setImgTypeError] = useState(false);

  const FormSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    relatedItem: Yup.string().required('Related Item is required'),
    hack: Yup.string().required('Hack is required'),
    status: Yup.string().required('Status is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: currentData?.category || '',
      relatedItem: currentData?.relatedItem || '',
      hack: currentData?.hack || '',
      status: currentData?.status || 'Active'
      // image: currentData?.image || ''
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (isEdit) {
          dispatch(modifyHomeHacks(values, navigate, currentData));
        } else {
          dispatch(createHomeHacks(values, navigate));
        }
      } catch (error) {
        // console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const handleChange = async (event) => {
    const originalFile = event.target.files[0];
    const base64 = await convertBase64(originalFile);

    if (['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'].includes(originalFile.type)) {
      // rename file
      const newFile = new File([originalFile], `${getUnixTime(new Date())}-${originalFile.name}`, {
        type: originalFile.type
      });
      const img = URL.createObjectURL(newFile);
      setFieldValue('image', { originalName: originalFile.name, fileBase64: base64 });
      setUploadedImg(img);
      setImgTypeError(false);
    } else {
      setImgTypeError(true);
    }
  };

  const handleChangeCategory = (value) => {
    dispatch(getRelatedItem(CATEGORY[value]));
    setFieldValue('category', value);
    setFieldValue('relatedItem', '');
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    value={values.category}
                    onChange={(e, value) => handleChangeCategory(value)}
                    options={Object.keys(CATEGORY)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        variant="outlined"
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                      />
                    )}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    value={{ name: values.relatedItem }}
                    onChange={(e, value) => setFieldValue('relatedItem', value?.name || '')}
                    options={relatedItemList}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Related Item"
                        variant="outlined"
                        error={Boolean(touched.relatedItem && errors.relatedItem)}
                        helperText={touched.relatedItem && errors.relatedItem}
                      />
                    )}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    value={values.hack}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={3}
                    label="Hack"
                    {...getFieldProps('hack')}
                    error={Boolean(touched.hack && errors.hack)}
                    helperText={touched.hack && errors.hack}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    value={values.status}
                    onChange={(e, value) => setFieldValue('status', value || '')}
                    options={['Active', 'Inactive']}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                      />
                    )}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    {!isEdit ? 'Create New' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3 }}>
              <Button variant="contained" component="label">
                {!isEdit || !uploadedImg ? 'Upload Image' : 'Change Image'}
                <input type="file" accept="image/*" hidden onChange={handleChange} />
              </Button>
              <Box sx={{ mt: 4 }}>
                <DropZoneStyle>
                  {!uploadedImg ? (
                    <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                      No Image Uploaded
                    </Typography>
                  ) : (
                    <img alt={uploadedImg} src={uploadedImg} style={{ borderRadius: '8px' }} />
                  )}
                </DropZoneStyle>
                {imgTypeError && (
                  <FormHelperText error sx={{ px: 2 }}>
                    Upload Image Only
                  </FormHelperText>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
