import { Form, FormikProvider, useFormik } from 'formik';
import { parseISO } from 'date-fns';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Card,
  Grid,
  Stack,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import Page from '../components/Page';
import { deleteRequest, fetchData, postRequest, putRequest } from '../services';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../routes/paths';

export default function DashboardHomeItemsId() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState({
    homeItem: {
      itemId: '',
      description: '',
      brand: '',
      purchaseDate: null,
      installer: '',
      lastMaintenanceDate: null,
      remainsWithResidence: false,
      quantity: 1,
      notes: ''
    },
    items: []
  });
  const [newFile, setNewFile] = React.useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data.homeItem,
    onSubmit: async (values) => {
      try {
        await putRequest(location.pathname, values);
        enqueueSnackbar('Saved!', { variant: 'success' });
        navigate('/dashboard/home-items');
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  });

  React.useEffect(() => {
    fetchData(location.pathname).then((itemData) => {
      setData({
        ...itemData,
        homeItem: {
          ...itemData.homeItem,
          purchaseDate: itemData.homeItem.purchaseDate ? parseISO(itemData.homeItem.purchaseDate) : null,
          lastMaintenanceDate: itemData.homeItem.lastMaintenanceDate
            ? parseISO(itemData.homeItem.lastMaintenanceDate)
            : null
        }
      });
      setIsLoading(false);
    });
  }, [setData, location, setIsLoading]);

  const handleFileChange = (event) => {
    setNewFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (newFile) {
      try {
        // Send the file name to the server to get the signed URL
        const data = await postRequest(`${location.pathname}/file`, {
          filename: newFile.name,
          filetype: newFile.type
        });

        // Extract the signed URL from the response
        const { uploadUrl } = data;

        // Use the signed URL to upload the file
        const externalAxios = axios.create();
        await externalAxios.put(uploadUrl, newFile, {
          headers: {
            'Content-Type': newFile.type
          }
        });

        enqueueSnackbar('File saved!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error uploading file', { variant: 'error' });
      }
    }
  };

  const handleDownload = (fileId) => {
    try {
      fetchData(`${location.pathname}/file/${fileId}`).then((data) => window.open(data.url, '_blank'));
    } catch (error) {
      enqueueSnackbar('Error downloading file', { variant: 'error' });
    }
  };

  const handleDelete = (fileId) => {
    if (window.confirm(`Are you sure you want to delete this file?`)) {
      try {
        deleteRequest(`${location.pathname}/file/${fileId}`);
        enqueueSnackbar('File deleted!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting file', { variant: 'error' });
      }
    }
  };

  return isLoading ? null : (
    <Page title={formik.values.description}>
      <HeaderBreadcrumbs
        heading={formik.values.description}
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
          { name: 'Home Items', href: '/dashboard/home-items' },
          { name: formik.values.description }
        ]}
      />
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel id="itemId-label">Item Type</InputLabel>
                      <Select name="itemId" {...formik.getFieldProps('itemId')} labelId="itemId-label">
                        {data.items.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Brand"
                      {...formik.getFieldProps('brand')}
                      error={Boolean(formik.touched.brand && formik.errors.brand)}
                      helperText={formik.touched.brand && formik.errors.brand}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Description"
                      {...formik.getFieldProps('description')}
                      error={Boolean(formik.touched.description && formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <DatePicker
                      name="purchaseDate"
                      label="Purchase Date"
                      maxDate={Date.now()}
                      {...formik.getFieldProps('purchaseDate')}
                      onChange={(date) => {
                        formik.setFieldValue('purchaseDate', date);
                      }}
                      format="MM/dd/yyyy"
                      slotProps={{ textField: { fullWidth: true, helperText: null } }}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField fullWidth label="Company (Installer)" {...formik.getFieldProps('installer')} />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <DatePicker
                      name="lastMaintenanceDate"
                      label="Last Maintenance Date"
                      maxDate={Date.now()}
                      {...formik.getFieldProps('lastMaintenanceDate')}
                      onChange={(date) => {
                        formik.setFieldValue('lastMaintenanceDate', date);
                      }}
                      slotProps={{ textField: { fullWidth: true, helperText: null } }}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField fullWidth label="Quantity" {...formik.getFieldProps('quantity')} />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      {...formik.getFieldProps('notes')}
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={4}
                      label="Notes"
                    />
                  </Stack>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.remainsWithResidence}
                          onChange={(val) => {
                            formik.setFieldValue('remainsWithResidence', val);
                          }}
                          {...formik.getFieldProps('remainsWithResidence')}
                          color="info"
                        />
                      }
                      label="Remains with residence"
                    />
                    <button type="submit" className="button-primary">
                      Save
                    </button>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <div className="space-y-2 card p-2">
        <h2 className="text-xl font-semibold text-gray-800">Files</h2>
        <p className="text-gray-600 text-sm mb-4">Upload and manage files associated with this item.</p>
        {data.homeItem &&
          data.homeItem.files.map((file) => (
            <div key={file.fileId} className="flex items-center justify-between p-2 border-b border-gray-200">
              <span className="text-gray-700">{file.name}</span>
              <div className="flex items-center space-x-2">
                <button
                  className="text-green-600 hover:text-green-800 focus:outline-none"
                  onClick={() => handleDownload(file.fileId)}
                  type="button"
                >
                  <i className="fas fa-download" />
                </button>
                <button
                  className="text-red-600 hover:text-red-800 focus:outline-none"
                  onClick={() => handleDelete(file.fileId)}
                  type="button"
                >
                  <i className="fas fa-trash-alt" />
                </button>
              </div>
            </div>
          ))}
        <div className="flex items-center space-x-2">
          <label className="block" htmlFor="file-input">
            <span className="sr-only">Choose File</span>
            <input
              id="file-input"
              type="file"
              name="file"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                 file:mr-4 file:py-2 file:px-4
                 file:rounded-full file:border-0
                 file:text-sm file:font-semibold
                 file:bg-blue-50 file:text-blue-700
                 hover:file:bg-blue-100"
            />
          </label>
          <button
            type="button"
            onClick={handleFileUpload}
            disabled={!newFile}
            className="flex items-center px-4 py-2 border border-transparent
               text-sm font-medium rounded-md shadow-sm text-white
               bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2
               focus:ring-offset-2 focus:ring-blue-500"
          >
            <i className="fas fa-upload mr-2" />
            Upload
          </button>
        </div>
      </div>
    </Page>
  );
}
