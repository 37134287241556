import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Card, Grid, Stack, TextField } from '@mui/material';
// redux
import { useSelector, useDispatch } from '../../../redux/store';
import { getCompanyType } from '../../../redux/slices/companyType';
import { PATH_SERVICE_PRO } from '../../../routes/paths';

// ----------------------------------------------------------------------

CompanyForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  createData: PropTypes.func,
  modifyData: PropTypes.func
};

export default function CompanyForm({ isEdit, currentData, createData, modifyData }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { companyTypeList, error, success, isLoading } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getCompanyType());
  }, [dispatch]);

  const NewSchema = Yup.object().shape({
    name: Yup.string().required('Company name is required'),
    companyType: Yup.string().required('Company type is required'),
    email: Yup.string().email().required('Admin email is required'),
    fullName: Yup.string().required('Admin full name is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentData?.name || '',
      companyType: currentData?.companyType || '',
      email: currentData?.email || '',
      fullName: currentData?.fullName || ''
    },
    validationSchema: NewSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (isEdit) {
          dispatch(modifyData(values, navigate, currentData, PATH_SERVICE_PRO.admin.listCompany));
        } else {
          dispatch(createData(values, navigate));
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (success) enqueueSnackbar(success, { variant: 'success' });
  }, [success, enqueueSnackbar]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2 }}
            >
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Company Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Autocomplete
                  fullWidth
                  value={{ name: values.companyType }}
                  onChange={(e, value) => setFieldValue('companyType', value?.name || '')}
                  options={companyTypeList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Type"
                      variant="outlined"
                      error={Boolean(touched.companyType && errors.companyType)}
                      helperText={touched.companyType && errors.companyType}
                    />
                  )}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Admin Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Admin Full Name"
                  {...getFieldProps('fullName')}
                  error={Boolean(touched.fullName && errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                />
              </Stack>
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                {!isEdit ? 'Add Company' : 'Save Changes'}
              </LoadingButton>
            </Box>
          </Card>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
