import React, { useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import Page from '../../../components/Page';
import { StepForm1, StepForm2, StepForm3, StepForm4, StepForm5, StepForm6 } from './forms';
import { useSelector } from '../../../redux/store';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function WalkThrough() {
  const { addressDetails, addressDetailsResiview, isLoading } = useSelector((state) => state.user);

  const [stepStatus, setStepStatustep] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false
  });

  const handleNext = (type) => {
    resetSetps(type);
  };
  const handlePrevious = (type) => {
    resetSetps(type);
  };

  const resetSetps = (type) => {
    const steps = {
      ...stepStatus,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false
    };
    steps[type] = true;
    setStepStatustep(steps);
  };

  return (
    <RootStyle title="WalkThrough">
      <Container maxWidth="md" sx={{ boxShadow: 4, marginTop: '0' }}>
        <ContentStyle sx={{ minHeight: 'auto', padding: '60px 0' }}>
          {stepStatus.step1 && <StepForm1 handleNext={handleNext} />}
          {stepStatus.step2 && (
            <StepForm2
              handleNext={handleNext}
              addressDetails={addressDetails}
              addressDetailsResiview={addressDetailsResiview}
              isLoading={isLoading}
              handlePrevious={handlePrevious}
            />
          )}
          {stepStatus.step3 && <StepForm4 handleNext={handleNext} handlePrevious={handlePrevious} />}
          {stepStatus.step4 && <StepForm3 handleNext={handleNext} handlePrevious={handlePrevious} />}
          {stepStatus.step5 && <StepForm5 handleNext={handleNext} handlePrevious={handlePrevious} />}
          {stepStatus.step6 && <StepForm6 handleNext={handleNext} handlePrevious={handlePrevious} />}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
