import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from '../../../redux/store';
import {
  getContact,
  createContact,
  modifyContact,
  deleteContact,
  getIndustryType
} from '../../../redux/slices/contact';
import AlertDialog from '../../../pages/dashboard/admin/AlertDialog';

ContactNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentContact: PropTypes.object
};

const useStyles = makeStyles(() => ({
  iconSize: {
    width: 36,
    objectFit: 'contain',
    zIndex: 11,
    '&:hover': {
      color: 'rgb(0, 171, 85)',
      transitionDelay: '1',
      transform: 'scale(1.1)'
    }
  },
  iconSizeBlue: {
    width: 36,
    objectFit: 'contain',
    zIndex: 11,
    color: 'rgb(0, 171, 85)',
    '&:hover': {
      color: 'rgb(0, 171, 85)',
      transitionDelay: '1',
      transform: 'scale(1.1)'
    }
  }
}));

export default function ContactNewForm({ isEdit, currentContact }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { masterIndustryTypeList, error, success, isLoading, isDeleteLoading } = useSelector((state) => state.contact);
  const [dialogStatus, setDialogStatus] = useState(false);
  useEffect(() => {
    dispatch(getContact());
    dispatch(getIndustryType(''));
  }, [dispatch]);

  const schema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required'),
    name: Yup.mixed().required('Industry is required'),
    countryCode: Yup.object().required('Phone Number is required'),
    phoneNumber: Yup.number().required('Phone Number is required'),
    pointOfContact: Yup.mixed().required('Point Of Contact is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: currentContact?.companyName || '',
      industryType: currentContact?.industryType || '',
      phoneNumber: currentContact?.phoneNumber || '',
      pointOfContact: currentContact?.pointOfContact || '',
      rating: currentContact?.rating === 'ThumbsUp' ? 'ThumbsUp' : '',
      notes: currentContact?.notes || '',
      website: currentContact?.website || '',
      countryCode: currentContact?.countryCode || { code: 'US', label: 'United States', phone: '1' },
      name: currentContact?.industryType || ''
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        values.industryType = values.name;
        if (values.phoneNumber.length !== 10) {
          touched.phoneNumber = '10 Digits are required';
          errors.phoneNumber = '10 Digits are required';
        } else if (isEdit) {
          dispatch(modifyContact(values, navigate, currentContact));
        } else {
          dispatch(createContact(values, navigate));
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });
  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;
  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (success) enqueueSnackbar(success, { variant: 'success' });
  }, [success, enqueueSnackbar]);

  const handleDelete = () => {
    setDialogStatus(true);
  };

  const handleClose = () => {
    setDialogStatus(false);
  };
  const handleConfrim = () => {
    const { id } = currentContact;
    dispatch(deleteContact(navigate, id));
    setDialogStatus(false);
  };
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    {...getFieldProps('companyName')}
                    error={Boolean(touched.companyName && errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    value={values.name ? { name: values.name.name } : null}
                    onChange={(e, value) => setFieldValue('name', value || '')}
                    options={masterIndustryTypeList}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Industry Type"
                        variant="outlined"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    )}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    {...getFieldProps('phoneNumber')}
                    label="Phone Number"
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Point Of Contact"
                    {...getFieldProps('pointOfContact')}
                    error={Boolean(touched.pointOfContact && errors.pointOfContact)}
                    helperText={touched.pointOfContact && errors.pointOfContact}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Website"
                    {...getFieldProps('website')}
                    error={Boolean(touched.website && errors.website)}
                    helperText={touched.website && errors.website}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    {...getFieldProps('notes')}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    label="Notes"
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                  />
                </Stack>
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: !isEdit ? 'flex-end' : 'space-between',
                    flexWrap: isEdit ? { xs: 'wrap', sm: '' } : ''
                  }}
                >
                  {isEdit ? (
                    <LoadingButton
                      className="bg-red-500 text-white"
                      color="error"
                      variant="contained"
                      loading={isDeleteLoading}
                      onClick={handleDelete}
                    >
                      Delete
                    </LoadingButton>
                  ) : null}

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: !isEdit ? '80%' : 'auto',
                      margin: { xs: '0 20px', sm: '0' }
                    }}
                  >
                    <Stack style={{ paddingLeft: !isEdit ? 80 : 0 }} direction="row" spacing={2}>
                      <Stack>
                        {values.rating === 'ThumbsUp' ? (
                          <button type="button" onClick={() => setFieldValue('rating', 'ThumbsUp')}>
                            <i className={`${classes.iconSizeBlue} fa-regular fa-thumbs-up`} />
                          </button>
                        ) : (
                          <button type="button" onClick={() => setFieldValue('rating', 'ThumbsUp')}>
                            <i className={`${classes.iconSize} fa-thin fa-thumbs-up`} />
                          </button>
                        )}
                      </Stack>
                      <Stack>
                        {values.rating === 'ThumbsDown' ? (
                          <button type="button" onClick={() => setFieldValue('rating', 'ThumbsDown')}>
                            <i className={`${classes.iconSizeBlue} fa-regular fa-thumbs-down`} />
                          </button>
                        ) : (
                          <button type="button" onClick={() => setFieldValue('rating', 'ThumbsDown')}>
                            <i className={`${classes.iconSize} fa-thin fa-thumbs-down`} />
                          </button>
                        )}
                      </Stack>
                    </Stack>
                  </Box>

                  <Box>
                    <LoadingButton
                      className="button-primary"
                      sx={{ whiteSpace: 'nowrap', marginTop: { xs: '15px', sm: '' } }}
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      // onClick={handleSubmit}
                    >
                      {!isEdit ? 'Create Contact' : 'Save Changes'}
                    </LoadingButton>
                  </Box>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
      <AlertDialog
        dialogStatus={dialogStatus}
        handleClose={handleClose}
        handleConfrim={handleConfrim}
        name={values?.companyName}
      />
    </FormikProvider>
  );
}
