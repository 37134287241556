import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import AppliancesNewForm from '../../components/_dashboard/appliances/AppliancesNewForm';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import TaskItemsListing from './TaskItemsListing';
import { fetchData } from '../../services';

export default function AppliancesCreate() {
  const { pathname, state } = useLocation();
  const [brands, setBrands] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const isEdit = pathname.includes('edit');
  const appliances = items.filter((item) => item.category === 'Appliance');

  React.useEffect(() => {
    fetchData('/brands').then(setBrands);
    fetchData('/items').then(setItems);
  }, [setBrands, setItems]);

  return (
    <Page title={isEdit ? 'Modify Appliances' : 'Create Appliances'}>
      <Container>
        <HeaderBreadcrumbs
          heading={isEdit ? 'Modify Appliance' : 'Create a new Appliance'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Appliance', href: PATH_DASHBOARD.general.appliances },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />

        {brands.length && appliances.length ? (
          <AppliancesNewForm isEdit={isEdit} currentAppliance={state} brands={brands} appliances={appliances} />
        ) : null}
        {isEdit && <TaskItemsListing itemId={state?.serviceType?.id} />}
      </Container>
    </Page>
  );
}
