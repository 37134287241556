import PropTypes from 'prop-types';
// material
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';

// ----------------------------------------------------------------------

AlertDialog.propTypes = {
  dialogStatus: PropTypes.bool,
  handleClose: PropTypes.func,
  name: PropTypes.string,
  handleConfrim: PropTypes.func
};

export default function AlertDialog({ dialogStatus, handleClose, name, handleConfrim }) {
  return (
    <div>
      <Dialog open={dialogStatus} onClose={handleClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete <strong>{name}</strong> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleConfrim} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
