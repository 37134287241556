// material
import { experimentalStyled as styled } from '@mui/material/styles';
import React, { useRef } from 'react';

// components
import MainNavbar from '../layouts/main/MainNavbar';
import MainFooter from '../layouts/main/MainFooter';
import Page from '../components/Page';
import Faqs from './Faqs';
import { AboutHero, AboutWhat } from '../components/_external-pages/about';
import { LandingMinimal } from '../components/_external-pages/landing';
// import MegaMenu from './components-overview/extra/MegaMenu';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({}));

// ----------------------------------------------------------------------

export default function About() {
  // const aboutRef = useRef(null);
  const menuRef = {
    homeRef: useRef(null),
    aboutRef: useRef(null),
    faqsRef: useRef(null),
    teamRef: useRef(null),
    pagesRef: useRef(null)
  };
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <RootStyle title="ResiView">
      <MainNavbar handleScroll={handleScroll} menuRef={menuRef} />
      <AboutHero homeRef={menuRef.homeRef} />
      <AboutWhat aboutRef={menuRef.aboutRef} />
      <LandingMinimal />
      <Faqs faqsRef={menuRef.faqsRef} />
      {/* <AboutTeam teamRef={menuRef.teamRef} /> */}
      {/* <AboutTestimonials /> */}
      <MainFooter />
    </RootStyle>
  );
}
