import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Box, Typography, Switch, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import NumberFormat from 'react-number-format';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingScreen from '../../../../components/LoadingScreen';
import services from '../../../../services/webServices';

StepForm2.propTypes = {
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
  isLoading: PropTypes.bool
};

const SkeletonLoad = <LoadingScreen />;

const INITIAL_HOME = {
  bathroomCount: '',
  bedroomCount: '',
  floorCount: '',
  lotSize: '',
  moveInDate: null,
  owner: false,
  squareFeet: '',
  yearBuilt: null
};

export default function StepForm2({ handlePrevious, handleNext, isLoading }) {
  const [homeId, setHomeId] = React.useState(null);
  const [home, setHome] = React.useState(INITIAL_HOME);

  useEffect(() => {
    services.getHome().then((response) => {
      if (!response || !response.data) return;
      const homeData = response.data;
      setHomeId(homeData.id);
      setHome({
        bathroomCount: homeData.bathroomCount,
        bedroomCount: homeData.bedroomCount,
        floorCount: homeData.floorCount,
        lotSize: homeData.lotSize,
        moveInDate: homeData.moveInDate ? new Date(homeData.moveInDate) : null,
        owner: homeData.owner,
        squareFeet: homeData.squareFeet,
        yearBuilt: homeData.yearBuilt ? new Date(homeData.yearBuilt) : null
      });
    });
  }, []);

  const NewSchema = Yup.object().shape({
    bathroomCount: Yup.string().required('Number of bathrooms is required'),
    bedroomCount: Yup.string().required('Number of bedrooms is required'),
    floorCount: Yup.string().required('Number of floors is required'),
    lotSize: Yup.string().required('Lot Size is required'),
    moveInDate: Yup.date().required('Move in date is required'),
    squareFeet: Yup.string().required('Square feet is required'),
    yearBuilt: Yup.date().required('Year built is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: home,
    // initialValues: {
    //   bathroomCount: home?.bathroomCount || addressDetails?.property?.baths,
    //   bedroomCount: home?.bedroomCount || addressDetails?.property?.beds,
    //   floorCount: home?.floorCount || '',
    //   lotSize: home?.lotSize || addressDetails?.property?.lot_size_ft,
    //   moveInDate: (home?.yearBuilt ? new Date(home.yearBuild) : null) || addressDetails?.last_sold_date,
    //   owner: home?.owner || false,
    //   squareFeet: home?.squareFeet || addressDetails?.property?.building_size,
    //   yearBuilt: (home?.yearBuilt ? new Date(home.yearBuild) : null) || addressDetails?.property?.year_built,
    //   id: home?.id || ''
    // },
    validationSchema: NewSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        if (homeId) await services.updateHome(homeId, values);
        else await services.createHome(values);
        handleNext('step3');
      } catch (error) {
        setErrors(error);
      }
    }
  });

  const handleChangeBathroom = (event) => {
    const bathCount = event.target.value;
    const decimals = bathCount - Math.floor(bathCount);
    if (decimals !== 0 && decimals < 0.9) {
      setFieldValue('bathroomCount', (Math.floor(bathCount) + 0.5).toString());
    } else {
      setFieldValue('bathroomCount', bathCount);
    }
  };

  const { errors, values, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
            Great!
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            Lets get a little info about your home.
          </Typography>
        </Box>
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {isLoading && SkeletonLoad}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <DatePicker
                {...getFieldProps('yearBuilt')}
                onChange={(date) => {
                  formik.setFieldValue('yearBuilt', date);
                }}
                views={['year']}
                label="Year Built"
                maxDate={Date.now()}
              />
              <DatePicker
                {...getFieldProps('moveInDate')}
                onChange={(date) => {
                  formik.setFieldValue('moveInDate', date);
                }}
                label="Move In Date"
                maxDate={Date.now()}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <FormControlLabel
                control={<Switch {...getFieldProps('owner')} checked={values.owner} />}
                label="I own this home"
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <NumberFormat
                type="string"
                {...getFieldProps('floorCount')}
                thousandsGroupStyle="thousand"
                decimalSeparator=""
                label="Number of Floors"
                variant="outlined"
                customInput={TextField}
                thousandSeparator
                allowNegative={false}
                decimalScale={10}
                error={Boolean(touched.floorCount && errors.floorCount)}
                helperText={touched.floorCount && errors.floorCount}
                onChange={formik.handleChange}
              />
              <NumberFormat
                type="string"
                {...getFieldProps('bedroomCount')}
                thousandsGroupStyle="thousand"
                decimalSeparator=""
                label="Number of Bedrooms"
                variant="outlined"
                customInput={TextField}
                thousandSeparator
                allowNegative={false}
                decimalScale={10}
                error={Boolean(touched.bedroomCount && errors.bedroomCount)}
                helperText={touched.bedroomCount && errors.bedroomCount}
                onChange={formik.handleChange}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <NumberFormat
                type="string"
                {...getFieldProps('bathroomCount')}
                thousandsGroupStyle="thousand"
                decimalScale={1}
                decimalSeparator="."
                label="Number of Bathrooms"
                variant="outlined"
                customInput={TextField}
                thousandSeparator={false}
                allowNegative={false}
                error={Boolean(touched.bathroomCount && errors.bathroomCount)}
                helperText={touched.bathroomCount && errors.bathroomCount}
                onChange={handleChangeBathroom}
              />
              <NumberFormat
                type="string"
                {...getFieldProps('squareFeet')}
                thousandsGroupStyle="thousand"
                decimalScale={0}
                decimalSeparator="."
                label="Square feet of house"
                variant="outlined"
                customInput={TextField}
                thousandSeparator
                allowNegative={false}
                error={Boolean(touched.squareFeet && errors.squareFeet)}
                helperText={touched.squareFeet && errors.squareFeet}
                onChange={formik.handleChange}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <NumberFormat
                type="string"
                {...getFieldProps('lotSize')}
                thousandsGroupStyle="thousand"
                decimalScale={2}
                decimalSeparator="."
                label="Size of Lot (acres)"
                variant="outlined"
                customInput={TextField}
                thousandSeparator
                allowNegative={false}
                error={Boolean(touched.lotSize && errors.lotSize)}
                helperText={touched.lotSize && errors.lotSize}
                onChange={formik.handleChange}
              />
            </Stack>
          </Stack>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <LoadingButton
              size="large"
              type="button"
              variant="outlined"
              sx={{ width: 120, marginRight: '15px', marginLeft: '15px' }}
              onClick={() => handlePrevious('step1')}
              loading={isLoading}
            >
              Previous
            </LoadingButton>
            <LoadingButton
              className="button-primary"
              size="large"
              type="button"
              variant="contained"
              sx={{ width: 120, marginRight: '15px', marginLeft: '15px' }}
              onClick={handleSubmit}
            >
              Next
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
