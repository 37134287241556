import { fetchData, postRequest, putRequest, deleteRequest } from './index';

function getMasterData(data) {
  return fetchData('/masterData', data);
}

function createMasterData(data) {
  return postRequest('/masterData', data);
}

function updateMasterData(id, data) {
  return putRequest(`/masterData/${id}`, data);
}

function deleteMasterData(data) {
  return deleteRequest('/masterData', data);
}

function getUsers() {
  return fetchData('/users');
}

function CreateUser() {
  return postRequest('/users');
}

function updateUser(data) {
  return putRequest(`/users`, data);
}

function sendSignUpMail(data) {
  return postRequest(`/users/signUpMail`, data);
}

function confirmUser(data) {
  return putRequest(`/common`, data);
}

function getToNewUser(data) {
  return putRequest(`/common/getToNewUser`, data);
}

function deleteUser(data) {
  return deleteRequest('/users', data);
}

function getAllUsers() {
  return fetchData('/users/getAllUser');
}

function sendMail(data) {
  return postRequest('/users/sendMail', data);
}
// Service
function getService(data) {
  return fetchData('/service', data);
}

function createService(data) {
  return postRequest('/service', data);
}

function updateService(id, data) {
  return putRequest(`/service/${id}`, data);
}

function deleteService(data) {
  return deleteRequest('/service', data);
}

function softDeleteService(id, data) {
  return putRequest(`/service/delete/${id}`, data);
}

function fileUploadService(id, data) {
  return putRequest(`/service/fileUpload/${id}`, data);
}

function fileDeleteService(id, data) {
  return putRequest(`/service/fileDelete/${id}`, data);
}

function getRecentEdited(data) {
  return fetchData('/service/recentEdited', data);
}
// Service

// Master Residence
function getHomeHack(data) {
  return fetchData('/homeHack', data);
}
function getHomeHackAll(data) {
  return fetchData('/homeHack/getHomeHackAll', data);
}
function createHomeHack(data) {
  return postRequest('/homeHack', data);
}

function updateHomeHack(id, data) {
  return putRequest(`/homeHack/${id}`, data);
}

function deleteHomeHack(data) {
  return deleteRequest('/homeHack', data);
}
// end Master Residence

// Contact start
function getContact(data) {
  return fetchData('/contacts', data);
}

function createContact(data) {
  return postRequest('/contacts', data);
}

function updateContact(id, data) {
  return putRequest(`/contacts/${id}`, data);
}

function deleteContact(id, data) {
  return deleteRequest(`/contacts/${id}`, data);
}
// Address
function getAddress(data) {
  return fetchData('/address', data);
}

function createAddress(data) {
  return postRequest('/address', data);
}

function updateAddress(id, data) {
  return putRequest(`/address/${id}`, data);
}
// End Address

// Home
function getHome(data) {
  return fetchData('/home', data);
}

function createHome(data) {
  return postRequest('/home', data);
}

function updateHome(id, data) {
  return putRequest(`/home/${id}`, data);
}

function fileUploadHome(id, data) {
  return putRequest(`/home/fileUpload/${id}`, data);
}

function fileDeleteHome(id, data) {
  return putRequest(`/home/fileDelete/${id}`, data);
}
// End Home

// Tasks
function getTasks(data) {
  return fetchData('/tasks', data);
}

function getTasksByItemId(id, data) {
  return fetchData(`/tasks/item/${id}`, data);
}

function getTasksId(id, data) {
  return fetchData(`/tasks/item`, data);
}

function getTaskByItemIdWithUserStatus(id, data) {
  return fetchData(`/tasks/taskByItemIdWithUserStatus/${id}`, data);
}

function createTasks(data) {
  return postRequest('/tasks', data);
}

function updateTasks(id, data) {
  return putRequest(`/tasks/${id}`, data);
}
function uploadFileService(id, data) {
  return putRequest(`/tasks/uploadFileService/${id}`, data);
}

function deleteItemsTask(id, data) {
  return deleteRequest(`/tasks/item/${id}`, data);
}

function updateTaskStatus(id, data) {
  return putRequest(`/tasks/updateStatus/${id}`, data);
}

function deleteTaskStatus(id, data) {
  return deleteRequest(`/tasks/deleteStatus/${id}`, data);
}

function getAllTask(data) {
  return fetchData(`/tasks`, data);
}

function getRecentTasks() {
  return fetchData(`/tasks/tasksForDashboard`, {});
}
// End Tasks

// Contact start
function getWarranty(data) {
  return fetchData('/warranty', data);
}

function createWarranty(data) {
  return postRequest('/warranty', data);
}

function updateWarranty(id, data) {
  return putRequest(`/warranty/${id}`, data);
}

function deleteWarranty(id, data) {
  return deleteRequest(`/warranty/${id}`, data);
}

function fileUploadWarranty(id, data) {
  return putRequest(`/warranty/fileUpload/${id}`, data);
}

function getServiceAll() {
  return fetchData(`/warranty/getService`);
}

function getUsersForSendMailTask(data) {
  return postRequest('/service/getUsersForSendMailTask', data);
}

// Company
function getCompany(data) {
  return fetchData('/company', data);
}

function createCompany(data) {
  return postRequest('/company', data);
}

function updateCompany(id, data) {
  return putRequest(`/company/${id}`, data);
}

function deleteCompany(data) {
  return deleteRequest('/company', data);
}
// Company

// Pro users
function getProUsers(id, data) {
  return fetchData(`/users/pro/${id}`, data);
}

function createProUsers(data) {
  return postRequest('/users/pro', data);
}

function updateProUsers(id, data) {
  return putRequest(`/users/pro/${id}`, data);
}

function deleteProUsers(id, data) {
  return putRequest(`/users/pro/delete/${id}`, data);
}
// Pro users

// Search Address
function searchAddressFromResiview(data) {
  return postRequest('/users/searchAddress', data);
}

// End Blog or News Post
function getPost(data) {
  return fetchData(`/post`, data);
}

function createPost(data) {
  return postRequest('/post', data);
}

function updatePost(id, data) {
  return putRequest(`/post/${id}`, data);
}

function deletePost(data) {
  return deleteRequest(`/post`, data);
}

function getPostUplically(data) {
  return fetchData(`/post/public`, data);
}

function createPostView(data) {
  return postRequest('/post/views', data);
}

function getZipCode(data) {
  return postRequest(`/zipcode`, data);
}

// End Blog or News Post

const webServices = {
  getMasterData,
  createMasterData,
  updateMasterData,
  deleteMasterData,
  getUsers,
  CreateUser,
  getAllUsers,
  sendMail,
  updateUser,
  confirmUser,
  getToNewUser,
  deleteUser,
  getService,
  createService,
  updateService,
  deleteService,
  softDeleteService,
  fileUploadService,
  fileDeleteService,
  getHomeHack,
  getHomeHackAll,
  createHomeHack,
  updateHomeHack,
  deleteHomeHack,
  getRecentEdited,
  getContact,
  createContact,
  updateContact,
  deleteContact,
  getAddress,
  createAddress,
  updateAddress,
  getHome,
  createHome,
  updateHome,
  fileUploadHome,
  fileDeleteHome,
  getTasks,
  createTasks,
  updateTasks,
  getTasksByItemId,
  getTasksId,
  deleteItemsTask,
  updateTaskStatus,
  uploadFileService,
  getTaskByItemIdWithUserStatus,
  deleteTaskStatus,
  getAllTask,
  getRecentTasks,
  getWarranty,
  createWarranty,
  updateWarranty,
  deleteWarranty,
  fileUploadWarranty,
  getServiceAll,
  getUsersForSendMailTask,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
  getProUsers,
  createProUsers,
  updateProUsers,
  deleteProUsers,
  searchAddressFromResiview,
  getPost,
  createPost,
  updatePost,
  deletePost,
  getPostUplically,
  createPostView,
  sendSignUpMail,
  getZipCode
};

export default webServices;
