import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserByFirebaseId, googleLogin, registerHomeowner } from '../api';
import { servicePro } from '../utils/role';
import { firebase } from './firebase-config';
import webServices from '../services/webServices';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  resiviewUser: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resendSignUpMail: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};
export function getUserUID() {
  return firebase.auth().currentUser.uid;
}
function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [resiviewUser, setResiviewUser] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const { currentUser } = firebase.auth();
    if (!currentUser) return;
    const firebaseId = currentUser.uid;
    getUserByFirebaseId({ firebaseId }).then(setResiviewUser);
  }, []);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user && user.emailVerified) {
          user.getIdTokenResult().then((res) => {
            user.claims = res.claims;
            user.signInProvider = res.signInProvider;
            if (typeof window !== 'undefined' && !localStorage.getItem('walkthroughStatus'))
              localStorage.setItem('walkthroughStatus', res.claims.walkthroughStatus);
            if (typeof window !== 'undefined' && !localStorage.getItem('phone_number'))
              localStorage.setItem('phone_number', res.claims.phone_number);
            getUserByFirebaseId({ firebaseId: user.uid }).then((user) => {
              if (user) setResiviewUser(user);
            });
            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user }
            });
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider);
    const user = {
      email: result.user.email,
      firebaseId: result.user.uid,
      firstName: '',
      lastName: '',
      displayName: result.user.displayName,
      phoneNumber: result.user.phoneNumber || ''
    };
    await googleLogin(user);
    getUserByFirebaseId({ firebaseId: user.firebaseId }).then(setResiviewUser);
    return result;
  };

  const register = (email, password, firstName, lastName, phone) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (res) => {
        const UID = res.user.uid;
        await registerHomeowner({
          email,
          firebaseId: UID,
          firstName,
          lastName,
          displayName: `${firstName} ${lastName}`,
          phoneNumber: phone
        });
        firebase.auth().signOut();
      });

  const logout = async () => {
    localStorage.clear();
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };
  const resendSignUpMail = async (email) => {
    await webServices.sendSignUpMail({
      email
    });
  };

  const auth = { ...state.user };
  const walkthroughStatus = typeof window !== 'undefined' ? localStorage.getItem('walkthroughStatus') : null;
  return (
    <>
      <AuthContext.Provider
        value={{
          ...state,
          method: 'firebase',
          user: {
            id: auth.uid,
            email: auth.email,
            photoURL: auth.photoURL,
            displayName: auth.displayName || auth.email,
            role: auth?.claims?.role || 'Homeowner',
            phoneNumber: auth.phoneNumber,
            isPublic: auth?.isPublic || false,
            authProvider: auth?.signInProvider || '',
            completed_walkthrough: walkthroughStatus !== 'undefined' ? JSON.parse(walkthroughStatus) : false,
            isServicePro: pathname.includes('service-pro') || servicePro().includes(auth?.claims?.role)
          },
          resiviewUser,
          login,
          register,
          loginWithGoogle,
          logout,
          resetPassword,
          resendSignUpMail
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
}

export { AuthContext, AuthProvider };
