import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion, AnimatePresence } from 'framer-motion';
// material
import { alpha, experimentalStyled as styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  List,
  Link,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip
} from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
//
import { MIconButton } from '../@material-extend';
import { varFadeInRight } from '../animate';
import { UploadIllustration } from '../../assets';
// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
  onFileSubmit: PropTypes.func,
  isLoadingBtnUpld: PropTypes.bool,
  isFileUploaded: PropTypes.bool,
  text: PropTypes.string
};

const imagesTyps = ['image/jpeg', 'image/png', 'image/gif'];

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  onFileSubmit,
  isLoadingBtnUpld,
  isFileUploaded,
  text = null,
  sx,
  ...other
}) {
  const hasFile = files.length > 0;
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {text != null ? text : 'Drop or Select file'}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Drag files here or click&nbsp;
            <Link underline="always">browse</Link>&nbsp;to select from your machine
          </Typography>
        </Box>
      </DropZoneStyle>
      {fileRejections.length > 0 && <ShowRejectionItems />}
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {files.map((file) => {
              const { name, size, type, originalName, preview, fileBase64 } = file;
              const key = fileBase64 !== undefined ? fileBase64 : name;
              const iff = (condition, then, otherwise) => (condition ? then : otherwise);
              if (showPreview) {
                return (
                  <div key={key} style={{ marginRight: 5, marginBottom: 5 }}>
                    <ListItem
                      key={key}
                      component={motion.div}
                      {...varFadeInRight}
                      sx={{
                        p: 0,
                        m: 0.5,
                        width: 80,
                        height: 80,
                        borderRadius: 1.5,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-flex'
                      }}
                    >
                      <Tooltip title="View/Download" arrow>
                        <a
                          rel="noreferrer"
                          href={fileBase64}
                          download={originalName}
                          style={{ height: '100%', width: '100%' }}
                        >
                          <Paper
                            variant="outlined"
                            component="img"
                            src={
                              fileBase64 !== undefined
                                ? fileBase64
                                : iff(imagesTyps.includes(type), preview, '/static/doc_placeholder.png')
                            }
                            sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/static/doc_placeholder.png';
                            }}
                          />
                        </a>
                      </Tooltip>
                      <Box sx={{ top: 6, right: 6, position: 'absolute', zIndex: '999' }}>
                        <MIconButton
                          size="small"
                          onClick={() => onRemove(file)}
                          sx={{
                            p: '2px',
                            color: 'common.white',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                            '&:hover': {
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                            }
                          }}
                        >
                          <Icon icon={closeFill} />
                        </MIconButton>
                      </Box>
                    </ListItem>

                    <Typography
                      variant="body2"
                      sx={{ color: '#46AC55', fontSize: 11, textAlign: 'center', marginTop: '-5px' }}
                    >
                      {originalName}
                    </Typography>
                  </div>
                );
              }

              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  {...varFadeInRight}
                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper'
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>
                  <ListItemText
                    primary={isString(file) ? file : name}
                    secondary={isString(file) ? '' : fData(size)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    <MIconButton edge="end" size="small" onClick={() => onRemove(file)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </div>
        </AnimatePresence>
      </List>
      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={onRemoveAll} sx={{ mr: 1.5 }}>
            Remove all
          </Button>
          {/* <Button variant="contained" onClick={onFileSubmit}>
            Upload files
          </Button> */}
          {isFileUploaded ? (
            <LoadingButton
              type={text ? 'button' : 'submit'}
              variant="contained"
              loading={isLoadingBtnUpld}
              onClick={onFileSubmit}
            >
              Upload files
            </LoadingButton>
          ) : null}
        </Stack>
      )}
    </Box>
  );
}
