import { Container } from '@mui/material';
// components
import SendEmailForm from '../../../components/_dashboard/sendEmail/SendEmailForm';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function MasterSendEmailNotificationAction() {
  return (
    <Page title="Send Email Notification">
      <Container>
        <HeaderBreadcrumbs
          heading="Send Email Notification"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Interior', href: PATH_DASHBOARD.general.interior }
          ]}
        />
        <SendEmailForm />
      </Container>
    </Page>
  );
}
