import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Container, Skeleton, Button, Box } from '@mui/material';
// routes
import { PATH_SERVICE_PRO } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import NewForm from './UserForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import {
  getProUsers,
  createProUsers,
  modifyProUsers,
  deleteProUsers,
  getProUsersSuccess
} from '../../../redux/slices/user';
import Listing from './UserListing';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

const TABLE_HEAD = [
  { id: 'name', label: 'Full Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Account Type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

export default function UserAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { proUsersList, success, error, isLoading } = useSelector((state) => state.user);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');
  const isCreate = pathname.includes('create');

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getProUsers());
  }, [dispatch, success, enqueueSnackbar]);

  const handleEdit = (row) => {
    navigate(PATH_SERVICE_PRO.pro.usersEdit, { state: row });
  };

  const listingComponent = (
    <Page title="Users">
      <Container>
        <HeaderBreadcrumbs
          heading="Users"
          links={[
            { name: 'Dashboard', href: PATH_SERVICE_PRO.dashboard },
            { name: 'Pro', href: PATH_SERVICE_PRO.pro.company },
            { name: 'Users', href: PATH_SERVICE_PRO.pro.users }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_SERVICE_PRO.pro.usersCreate}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New
            </Button>
          }
        />
        {!isLoading > 0 ? (
          <Listing
            tableHead={TABLE_HEAD}
            title="Users"
            dataList={proUsersList}
            listSuccess={getProUsersSuccess}
            dataDelete={deleteProUsers}
            handleEdit={handleEdit}
          />
        ) : (
          SkeletonLoad
        )}
      </Container>
    </Page>
  );

  const formComponent = (
    <Page title={isEdit ? 'Modify' : 'Create'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Users"
          links={[
            { name: 'Dashboard', href: PATH_SERVICE_PRO.dashboard },
            { name: 'Pro', href: PATH_SERVICE_PRO.pro.company },
            { name: 'Users', href: PATH_SERVICE_PRO.pro.users },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />
        <NewForm
          isEdit={isEdit}
          currentData={state}
          createData={createProUsers}
          modifyData={modifyProUsers}
          error={error}
          isLoading={isLoading}
          isIcon={false}
        />
      </Container>
    </Page>
  );

  return isEdit || isCreate ? formComponent : listingComponent;
}
