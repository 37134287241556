import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import { getYear, differenceInYears, parseISO } from 'date-fns';
// material
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField, Typography, Autocomplete } from '@mui/material';
import NumberFormat from 'react-number-format';
// redux
import { useSelector, useDispatch } from '../../../redux/store';
import {
  searchProperty,
  searchAddressSuccess
  // searchAddressResiviewSuccess,
  // searchAddressFromResiview
} from '../../../redux/slices/user';
import { getStateList } from '../../../redux/slices/residence';

// ----------------------------------------------------------------------
const TYPES = {
  applianceType: 'Appliance',
  utilityType: 'Utilities',
  exteriorType: 'Exterior',
  interiorType: 'Interior'
};
export default function AddressSearch() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { masterStateList } = useSelector((state) => state.residence);
  const { addressDetails, addressDetailsResiview, isLoading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getStateList());
    return () => {
      dispatch(searchAddressSuccess({}));
      // dispatch(searchAddressResiviewSuccess({}));
    };
  }, [dispatch]);

  const NewAddressSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: '',
      state: '',
      city: '',
      zipcode: '',
      abbreviation: ''
    },
    validationSchema: NewAddressSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const { address, state, city, zipcode, abbreviation } = values;
        // dispatch(searchAddressFromResiview({ address, state, city, zipcode }));
        searchProperty({ address, state, abbreviation, city, zipcode }, dispatch);
      } catch (error) {
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const APIkey = process.env.REACT_APP_MAP_GOOGLE;
  const location = encodeURIComponent(
    `${addressDetails?.address?.address} ${addressDetails?.address?.city} ${addressDetails?.address?.state} ${addressDetails?.address?.zipCode}`
  );
  const srcString = `https://www.google.com/maps/embed/v1/place?key=${APIkey}&q=${location}&zoom=18&maptype=satellite`;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  const handleChangeState = (e, value) => {
    setFieldValue('abbreviation', value?.abbreviation || '');
    setFieldValue('state', value?.name || '');
  };
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Address"
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                  <Autocomplete
                    fullWidth
                    // value={{ name: values.state }}
                    // onChange={(e, value) => setFieldValue('state', `${value?.name}` || '')}
                    onChange={handleChangeState}
                    options={masterStateList}
                    getOptionLabel={(option) => `${option.name} (${option.abbreviation})`}
                    // isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        variant="outlined"
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    )}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                  <NumberFormat
                    fullWidth
                    {...getFieldProps('zipcode')}
                    thousandsGroupStyle="thousand"
                    decimalSeparator=""
                    label="Zip/Code"
                    variant="outlined"
                    customInput={TextField}
                    type="text"
                    thousandSeparator={false}
                    allowNegative={false}
                    decimalScale={5}
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                    value={values.zipcode}
                    onChange={formik.handleChange}
                  />
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    Search Address
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
            {/* address from apis */}
            {addressDetails?.address?.address && (
              <Card sx={{ mt: 5, py: 3, px: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={6}>
                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Year Build:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.year_built || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Last Sold:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.last_sold_date || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Beds:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.beds || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Square Feets:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.building_size || '-'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Age:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.year_built
                          ? getYear(new Date()) - addressDetails?.property?.year_built
                          : '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Classification:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.classification ? addressDetails?.classification.replace('_', '') : '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Bath:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.baths || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Lot Size:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.lot_size_ft || '-'}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid container spacing={3} sx={{ mt: 0 }}>
                  <Grid item xs={6} md={6}>
                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Heating:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.heating || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Roof:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.roof || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Flooring:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.flooring || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Windows:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.window_features || '-'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Cooling:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.cooling || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Material:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.material || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Patio:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.patio_details || '-'}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid container spacing={3} sx={{ mt: 0 }}>
                  <Grid item xs={6} md={6}>
                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Appliances:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.appliances || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Interior:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.interior_features || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Basement:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.basement || '-'}
                      </Typography>
                    </Stack>

                    <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Garage:
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {addressDetails?.property?.garage_size || '-'}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            )}

            {/* servies */}
            {addressDetailsResiview.length > 0 &&
              addressDetailsResiview.map((element, index) => (
                <Box key={index} sx={{ mt: index > 0 ? 0 : 5, py: 3, px: 3 }}>
                  <Typography variant="h6" sx={{ mb: 0.5 }}>
                    {TYPES[element._id]} :
                  </Typography>
                  <Grid container spacing={3}>
                    {element?.services &&
                      element?.services.map((el) => (
                        <Grid key={el._id} item xs={12} md={6} lg={4}>
                          <Card sx={{ py: 2, px: 2 }}>
                            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                Type:
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {el?.serviceType?.name}
                              </Typography>
                            </Stack>

                            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                Brand:
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {el?.brand}
                              </Typography>
                            </Stack>

                            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                Age:
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {el?.purchaseDate
                                  ? `${differenceInYears(new Date(), parseISO(el?.purchaseDate))} year(s)`
                                  : ''}
                              </Typography>
                            </Stack>

                            <Stack direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 1 }}>
                              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                Last Maintenance Date:
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {el?.lastMaintenanceDate}
                              </Typography>
                            </Stack>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              ))}
          </Grid>
          <Grid item xs={12} md={4}>
            {addressDetails?.address?.address && (
              <Card sx={{ pt: 2, pb: 1, pl: 2 }}>
                <Box>
                  <div>
                    <iframe title="maps" frameBorder="0" src={srcString} />
                  </div>
                </Box>
              </Card>
            )}

            {addressDetails?.images && (
              <Typography variant="h6" sx={{ mb: 0.5, mt: 3 }}>
                Property Photos :
              </Typography>
            )}
            {addressDetails?.images &&
              [...new Set(addressDetails?.images)].map((el, i) => (
                <img key={i} alt={el} src={el} style={{ marginTop: '20px' }} />
              ))}
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
