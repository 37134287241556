import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import HomeItemCard from '../components/_dashboard/user/cards/HomeItemCard';
import { PATH_DASHBOARD } from '../routes/paths';
import Page from '../components/Page';
import { AddNewCard } from '../components/_dashboard/user/cards';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { fetchData } from '../services';

export default function DashboardHomeItems() {
  const [homeItems, setHomeItems] = React.useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryQuery = searchParams.get('category');
  const category = getCategory(categoryQuery);
  const title = getTitle(categoryQuery);

  React.useEffect(() => {
    fetchData(`/dashboard/home-items${location.search}`).then(setHomeItems);
  }, [setHomeItems, location]);

  return (
    <Page title={title}>
      <Container>
        <HeaderBreadcrumbs
          heading={title}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.general.home }, { name: title }]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AddNewCard
              type={category}
              createLink={`/dashboard/home-items/new?category=${categoryQuery}`}
              imgSrc={getImage(categoryQuery)}
            />
          </Grid>
          {homeItems.map((homeItem) => (
            <Grid key={homeItem.id} item xs={12} sm={6} md={4} style={{ cursor: 'pointer' }}>
              <Link to={`/dashboard/home-items/${homeItem.id}`}>
                <HomeItemCard item={homeItem} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}

function getTitle(categoryQuery = '') {
  if (categoryQuery === 'appliance') return 'Appliances';
  if (categoryQuery === 'utility') return 'Utilities';
  if (categoryQuery === 'lawnAndLeisure') return 'Lawn & Leisure';
  if (categoryQuery === 'exterior') return 'Exteriors';
  if (categoryQuery === 'interior') return 'Interiors';
  if (categoryQuery === 'furniture') return 'Furniture';
  return 'Home Items';
}

function getCategory(categoryQuery = '') {
  if (categoryQuery === 'appliance') return 'Appliance';
  if (categoryQuery === 'utility') return 'Utility';
  if (categoryQuery === 'lawnAndLeisure') return 'Lawn & Leisure';
  if (categoryQuery === 'exterior') return 'Exterior';
  if (categoryQuery === 'interior') return 'Interior';
  if (categoryQuery === 'furniture') return 'Furniture';
  return 'Appliance';
}

function getImage(categoryQuery = '') {
  if (categoryQuery === 'appliance') return '/static/appliance.jpeg';
  if (categoryQuery === 'utility') return '/static/utility.jpeg';
  if (categoryQuery === 'lawnAndLeisure') return '/static/lawn-and-leisure.jpeg';
  if (categoryQuery === 'exterior') return '/static/exterior.jpeg';
  if (categoryQuery === 'interior') return '/static/interior.jpeg';
  if (categoryQuery === 'furniture') return '/static/furniture.jpeg';
  return 'Home Items';
}
