import PropTypes from 'prop-types';
// material
import { Box, Chip } from '@mui/material';

// ----------------------------------------------------------------------

BlogPostTags.propTypes = {
  post: PropTypes.object,
  sx: PropTypes.object
};

export default function BlogPostTags({ post, sx }) {
  const { tags } = post;

  return <Box sx={{ py: 3, ...sx }}>{tags && tags.map((tag) => <Chip key={tag} label={tag} sx={{ m: 0.5 }} />)}</Box>;
}
