// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SERVICE_PRO = '/service-pro';
const ROOTS = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_ROOTS = {
  root: ROOTS,
  blog: {
    root: path(ROOTS, '/blog'),
    posts: path(ROOTS, '/blog/posts'),
    post: path(ROOTS, '/blog/posts/:title')
  },
  news: {
    root: path(ROOTS, '/news'),
    posts: path(ROOTS, '/news/posts'),
    post: path(ROOTS, '/news/posts/:title')
  },
  comingSoon: path(ROOTS, '/coming-soon')
};

export const PATH_PAGE = {
  home: '/',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/home'),
    address: path(ROOTS_DASHBOARD, '/address'),
    residence: path(ROOTS_DASHBOARD, '/residence'),
    home: path(ROOTS_DASHBOARD, '/home'),
    appliances: path(ROOTS_DASHBOARD, '/appliances'),
    appliancesCreate: path(ROOTS_DASHBOARD, '/appliances/create'),
    appliancesEdit: path(ROOTS_DASHBOARD, '/appliances/edit'),
    utilities: path(ROOTS_DASHBOARD, '/utilities'),
    utilitiesCreate: path(ROOTS_DASHBOARD, '/utilities/create'),
    utilitiesEdit: path(ROOTS_DASHBOARD, '/utilities/edit'),
    warranties: path(ROOTS_DASHBOARD, '/warranties'),
    exterior: path(ROOTS_DASHBOARD, '/exterior'),
    exteriorCreate: path(ROOTS_DASHBOARD, '/exterior/create'),
    exteriorEdit: path(ROOTS_DASHBOARD, '/exterior/edit'),
    services: path(ROOTS_DASHBOARD, '/services'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    walkthrough: path(ROOTS_DASHBOARD, '/walkthrough'),
    tasks: path(ROOTS_DASHBOARD, '/tasks'),
    lawnLeisure: path(ROOTS_DASHBOARD, '/lawnLeisure'),
    lawnLeisureCreate: path(ROOTS_DASHBOARD, '/lawnLeisure/create'),
    lawnLeisureEdit: path(ROOTS_DASHBOARD, '/lawnLeisure/edit'),
    furniture: path(ROOTS_DASHBOARD, '/furniture'),
    furnitureCreate: path(ROOTS_DASHBOARD, '/furniture/create'),
    furnitureEdit: path(ROOTS_DASHBOARD, '/furniture/edit'),
    interior: path(ROOTS_DASHBOARD, '/interior'),
    interiorCreate: path(ROOTS_DASHBOARD, '/interior/create'),
    interiorEdit: path(ROOTS_DASHBOARD, '/interior/edit'),
    contacts: path(ROOTS_DASHBOARD, '/contacts'),
    contactsCreate: path(ROOTS_DASHBOARD, '/contacts/create'),
    contactsEdit: path(ROOTS_DASHBOARD, '/contacts/edit'),
    warranty: path(ROOTS_DASHBOARD, '/warranty'),
    warrantyCreate: path(ROOTS_DASHBOARD, '/warranty/create'),
    warrantyEdit: path(ROOTS_DASHBOARD, '/warranty/edit')
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    role: path(ROOTS_DASHBOARD, '/admin/role'),
    roleEdit: path(ROOTS_DASHBOARD, '/admin/role/edit'),
    brands: path(ROOTS_DASHBOARD, '/admin/brands'),
    brandsCreate: path(ROOTS_DASHBOARD, '/admin/brands/create'),
    brandsEdit: path(ROOTS_DASHBOARD, '/admin/brands/edit'),
    applianceType: path(ROOTS_DASHBOARD, '/admin/appliance-type'),
    applianceTypeCreate: path(ROOTS_DASHBOARD, '/admin/appliance-type/create'),
    applianceTypeEdit: path(ROOTS_DASHBOARD, '/admin/appliance-type/edit'),
    industryType: path(ROOTS_DASHBOARD, '/admin/industry-type'),
    industryTypeCreate: path(ROOTS_DASHBOARD, '/admin/industry-type/create'),
    industryTypeEdit: path(ROOTS_DASHBOARD, '/admin/industry-type/edit'),
    items: path(ROOTS_DASHBOARD, '/admin/items'),
    itemsNew: path(ROOTS_DASHBOARD, '/admin/items/new'),
    exteriorType: path(ROOTS_DASHBOARD, '/admin/exterior-type'),
    exteriorTypeCreate: path(ROOTS_DASHBOARD, '/admin/exterior-type/create'),
    exteriorTypeEdit: path(ROOTS_DASHBOARD, '/admin/exterior-type/edit'),
    utilitiesType: path(ROOTS_DASHBOARD, '/admin/utilities-type'),
    utilitiesTypeCreate: path(ROOTS_DASHBOARD, '/admin/utilities-type/create'),
    utilitiesTypeEdit: path(ROOTS_DASHBOARD, '/admin/utilities-type/edit'),
    homeHacks: path(ROOTS_DASHBOARD, '/admin/home-hacks'),
    homeHacksCreate: path(ROOTS_DASHBOARD, '/admin/home-hacks/create'),
    homeHacksEdit: path(ROOTS_DASHBOARD, '/admin/home-hacks/edit'),
    lawnLeisureType: path(ROOTS_DASHBOARD, '/admin/lawnLeisure-type'),
    lawnLeisureTypeCreate: path(ROOTS_DASHBOARD, '/admin/lawnLeisure-type/create'),
    lawnLeisureTypeEdit: path(ROOTS_DASHBOARD, '/admin/lawnLeisure-type/edit'),
    furnitureType: path(ROOTS_DASHBOARD, '/admin/furniture-type'),
    furnitureTypeCreate: path(ROOTS_DASHBOARD, '/admin/furniture-type/create'),
    furnitureTypeEdit: path(ROOTS_DASHBOARD, '/admin/furniture-type/edit'),
    interiorType: path(ROOTS_DASHBOARD, '/admin/interior-type'),
    interiorTypeCreate: path(ROOTS_DASHBOARD, '/admin/interior-type/create'),
    interiorTypeEdit: path(ROOTS_DASHBOARD, '/admin/interior-type/edit'),
    emailNotication: path(ROOTS_DASHBOARD, '/admin/email-notification'),
    blog: path(ROOTS_DASHBOARD, '/admin/blog'),
    blogCreate: path(ROOTS_DASHBOARD, '/admin/blog/create'),
    blogEdit: path(ROOTS_DASHBOARD, '/admin/blog/edit'),
    news: path(ROOTS_DASHBOARD, '/admin/news'),
    newsCreate: path(ROOTS_DASHBOARD, '/admin/news/create'),
    newsEdit: path(ROOTS_DASHBOARD, '/admin/news/edit'),
    comingSoon: path(ROOTS_DASHBOARD, '/admin/coming-soon'),
    comingSoonCreate: path(ROOTS_DASHBOARD, '/admin/coming-soon/create'),
    comingSoonEdit: path(ROOTS_DASHBOARD, '/admin/coming-soon/edit')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};

export const PATH_SERVICE_PRO = {
  dashboard: path(ROOTS_SERVICE_PRO, '/dashboard'),
  addressSearch: path(ROOTS_SERVICE_PRO, '/address-search'),
  customers: path(ROOTS_SERVICE_PRO, '/customers'),
  suggestedOutreach: path(ROOTS_SERVICE_PRO, '/suggested-outreach'),
  pro: {
    company: path(ROOTS_SERVICE_PRO, '/pro/company'),
    companyCreate: path(ROOTS_SERVICE_PRO, '/pro/company/create'),
    companyEdit: path(ROOTS_SERVICE_PRO, '/pro/company/edit'),
    users: path(ROOTS_SERVICE_PRO, '/pro/users'),
    usersCreate: path(ROOTS_SERVICE_PRO, '/pro/users/create'),
    usersEdit: path(ROOTS_SERVICE_PRO, '/pro/users/edit')
  },
  admin: {
    addCompany: path(ROOTS_SERVICE_PRO, '/admin/company/add'),
    listCompany: path(ROOTS_SERVICE_PRO, '/admin/company'),
    editCompany: path(ROOTS_SERVICE_PRO, '/admin/company/edit'),
    companyType: path(ROOTS_SERVICE_PRO, '/admin/company-type'),
    companyTypeCreate: path(ROOTS_SERVICE_PRO, '/admin/company-type/create'),
    companyTypeEdit: path(ROOTS_SERVICE_PRO, '/admin/company-type/edit')
  }
};
