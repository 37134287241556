/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Stack, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import ClearIcon from '@mui/icons-material/Clear';

import { parseISO } from 'date-fns';
import { putRequest } from '../../services';

const TASKSTATUS = ['Open', 'Completed', 'Skipped', 'Not Applicable'];
const COMPLETEDBY = ['Homeowner', 'Service Pro'];

TaskItemModal.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  open: PropTypes.bool,
  parentCallBack: PropTypes.func
};

export default function TaskItemModal({ open, currentData, parentCallBack }) {
  const formik = useFormik({
    initialValues: {
      completionDate: currentData.completionDateISO ? parseISO(currentData.completionDateISO) : null,
      completedBy: currentData.completedBy,
      status: currentData.status,
      notes: currentData.notes
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        await putRequest(`/home-item-task/${currentData.id}`, values);
      } catch (error) {
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  const handleClose = () => {
    parentCallBack(false);
  };

  return (
    <>
      <Dialog className="cstm-popup" open={open} maxWidth="md" fullWidth>
        {/* Right Aligned Code */}
        {currentData?.adlocation === 'TOP' ? (
          <DialogContent className="top-aligned">
            <div dangerouslySetInnerHTML={{ __html: currentData.advertisement }} />
          </DialogContent>
        ) : (
          <div />
        )}

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            Task Details
            <ClearIcon color="action" onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </div>
        </DialogTitle>

        <FormikProvider value={formik}>
          <Form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            className={currentData?.adlocation === 'RIGHT' ? 'only-for-right' : ''}
          >
            <Box>
              <DialogContent>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField fullWidth label="Task Name" value={currentData?.name || ''} disabled />
                    <DatePicker
                      id="completionDate"
                      name="completionDate"
                      label="Date Complete"
                      maxDate={currentData.expiryDate}
                      minDate={currentData.startDate}
                      value={values.completionDate}
                      onChange={(date) => {
                        setFieldValue('completionDate', date);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth helperText={null} />}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Autocomplete
                      fullWidth
                      value={values.status}
                      onChange={(e, value) => setFieldValue('status', value || '')}
                      options={TASKSTATUS}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          variant="outlined"
                          error={Boolean(touched.status && errors.status)}
                          helperText={touched.status && errors.status}
                        />
                      )}
                    />
                    {values.status === 'Completed' && (
                      <Autocomplete
                        fullWidth
                        value={values.completedBy}
                        onChange={(e, value) => setFieldValue('completedBy', value || '')}
                        options={COMPLETEDBY}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Completed By"
                            variant="outlined"
                            error={Boolean(touched.completedBy && errors.completedBy)}
                            helperText={touched.completedBy && errors.completedBy}
                          />
                        )}
                      />
                    )}
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField {...getFieldProps('notes')} fullWidth multiline minRows={3} maxRows={4} label="Notes" />
                  </Stack>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: currentData?.statusByUsers?.UID ? 'space-between' : 'flex-end',
                    width: '100%'
                  }}
                >
                  {currentData?.statusByUsers?.UID && (
                    <LoadingButton color="error" variant="contained">
                      Delete
                    </LoadingButton>
                  )}
                  <LoadingButton className="button-primary" type="submit" variant="contained" loading={false}>
                    Save Changes
                  </LoadingButton>
                </Box>
              </DialogActions>
            </Box>

            {currentData?.adlocation && currentData?.adlocation === 'RIGHT' ? (
              <DialogContent className="right-aligned">
                <div dangerouslySetInnerHTML={{ __html: currentData.advertisement }} />
              </DialogContent>
            ) : (
              <div />
            )}
          </Form>
        </FormikProvider>

        {currentData?.adlocation && currentData?.adlocation === 'BOTTOM' ? (
          <DialogContent className="bottom-aligned">
            <div dangerouslySetInnerHTML={{ __html: currentData.advertisement }} />
          </DialogContent>
        ) : (
          <div />
        )}
      </Dialog>
    </>
  );
}
