import toastr from 'toastr';
// firebase
import { firebase } from '../contexts/firebase-config';
// eslint-disable-next-line react-hooks/rules-of-hooks
// const navigate = useNavigate();
const axios = require('axios').default;

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// Add a request interceptor
axios.interceptors.request.use(
  async (config) => {
    let token;
    if (process.env.REACT_APP_USER_ID) {
      token = process.env.REACT_APP_USER_ID;
    } else {
      const user = firebase.auth().currentUser;
      token = await user?.getIdToken();
    }
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => {
    // Do something with request error
    toastr.error('ERROR! Not able to send any request due to an exception.');
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        await firebase.auth().signOut();
        localStorage.clear();
        window.location.href = '/';
      }
      if (err.response.data) {
        if (err.response.data.message) {
          toastr.error(`ERROR! ${err.response.data.message}`);
        } else {
          toastr.error('ERROR! Server side exception has occoured.');
        }
      } else {
        toastr.error('ERROR! An exception has occoured.');
      }
    } else if (err.request) {
      toastr.error('ERROR! Server is not responding, please check your internet connection.');
    } else {
      toastr.error('ERROR! Not able to complete the request due to an exception.');
    }
    return Promise.reject(err);
  }
);

export const fetchData = async (url, data) => {
  let result;
  await axios
    .get(url, data)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => error);
  return result;
};

export const postRequest = (url, data) =>
  axios
    .post(url, data)
    .then((response) => response.data)
    .catch((error) => error);

export const putRequest = (url, data) =>
  axios
    .put(url, data)
    .then((res) => res.data)
    .catch((error) => error);

export const deleteRequest = (url, data) =>
  axios
    .delete(url, { data })
    .then((res) => res.data)
    .catch((error) => error);
