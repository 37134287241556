import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import appliancesReducer from './slices/appliances';
import residenceReducer from './slices/residence';
import exteriorReducer from './slices/exterior';
import lawnLeisureReducer from './slices/lawnLeisure';
import furnitureReducer from './slices/furniture';
import interiorReducer from './slices/interior';
import utilitiesReducer from './slices/utilities';
import homeHacksReducer from './slices/homeHacks';
import contactReducer from './slices/contact';
import tasksReducer from './slices/tasks';
import warrantyReducer from './slices/warranty';
import postReducer from './slices/post';
// service pro
import companyTypeReducer from './slices/companyType';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  appliances: appliancesReducer,
  residence: residenceReducer,
  exterior: exteriorReducer,
  utilities: utilitiesReducer,
  homeHacks: homeHacksReducer,
  lawnLeisure: lawnLeisureReducer,
  furniture: furnitureReducer,
  interior: interiorReducer,
  contact: contactReducer,
  tasks: tasksReducer,
  warranty: warrantyReducer,
  company: companyTypeReducer,
  post: postReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
