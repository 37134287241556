import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Container, Skeleton, Button, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import NewForm from './NewForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import {
  createIndustryType,
  modifyIndustryType,
  deleteIndustryType,
  getIndustryType,
  getIndustryTypeListSuccess
} from '../../../redux/slices/contact';
import Listing from './Listing';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'icon', label: 'Icon', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

export default function MasterIndustryTypeAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { masterIndustryTypeList, error, success, isLoading } = useSelector((state) => state.contact);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');
  const isCreate = pathname.includes('create');

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getIndustryType());
  }, [dispatch, success, enqueueSnackbar, isEdit, state]);

  const handleEdit = (row) => {
    navigate(PATH_DASHBOARD.admin.industryTypeEdit, { state: row });
  };

  const listingComponent = (
    <Page title="Master Industry Type">
      <Container>
        <HeaderBreadcrumbs
          heading="Master Industry Type"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Master Industry Type', href: PATH_DASHBOARD.admin.industryType }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.admin.industryTypeCreate}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New
            </Button>
          }
        />
        {masterIndustryTypeList.length > 0 ? (
          <Listing
            tableHead={TABLE_HEAD}
            title="Master Industry Type"
            dataList={masterIndustryTypeList}
            listSuccess={getIndustryTypeListSuccess}
            dataDelete={deleteIndustryType}
            handleEdit={handleEdit}
          />
        ) : (
          SkeletonLoad
        )}
      </Container>
    </Page>
  );

  const formComponent = (
    <Page title={isEdit ? 'Modify' : 'Create'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Master Industry Type"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Master Industry Type', href: PATH_DASHBOARD.admin.industryType },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />
        <NewForm
          isEdit={isEdit}
          currentData={state}
          createData={createIndustryType}
          modifyData={modifyIndustryType}
          error={error}
          isLoading={isLoading}
          isIcon
        />
      </Container>
    </Page>
  );

  return isEdit || isCreate ? formComponent : listingComponent;
}
