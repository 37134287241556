import { Form, FormikProvider, useFormik } from 'formik';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Card, Container, Grid, Stack, TextField, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation } from 'react-router-dom';
import React from 'react';
import Page from '../components/Page';
import { createHomeItem } from '../api';
import { fetchData } from '../services';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../routes/paths';

export default function DashboardHomeItemsNew() {
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = React.useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = getTitle(searchParams.get('category'));

  const formik = useFormik({
    initialValues: {
      item: '',
      description: '',
      brand: '',
      purchaseDate: null,
      installer: '',
      lastMaintenanceDate: null,
      quantity: 1,
      notes: '',
      remainsWithResidence: false
    },
    onSubmit: async (values) => {
      try {
        await createHomeItem(values);
        enqueueSnackbar('Saved!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  });

  React.useEffect(() => {
    const url = `${location.pathname}${location.search}`;
    fetchData(url).then(setItems);
  }, [setItems, location]);

  return (
    <Page title={title}>
      <Container>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Home Items', href: '/dashboard/home-items' },
            { name: title }
          ]}
        />
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <Autocomplete
                        fullWidth
                        onChange={(e, value) => {
                          formik.setFieldValue('item', value?.id || '');
                        }}
                        options={items}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Item Type"
                            variant="outlined"
                            error={Boolean(formik.touched.item && formik.errors.item)}
                            helperText={formik.touched.item && formik.errors.item}
                          />
                        )}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        label="Brand"
                        {...formik.getFieldProps('brand')}
                        error={Boolean(formik.touched.brand && formik.errors.brand)}
                        helperText={formik.touched.brand && formik.errors.brand}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Description"
                        {...formik.getFieldProps('description')}
                        error={Boolean(formik.touched.description && formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <DatePicker
                        name="purchaseDate"
                        label="Purchase Date"
                        maxDate={Date.now()}
                        {...formik.getFieldProps('purchaseDate')}
                        onChange={(date) => {
                          formik.setFieldValue('purchaseDate', date);
                        }}
                        format="MM/dd/yyyy"
                        slotProps={{ textField: { fullWidth: true, helperText: null } }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField fullWidth label="Company (Installer)" {...formik.getFieldProps('installer')} />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <DatePicker
                        name="lastMaintenanceDate"
                        label="Last Maintenance Date"
                        maxDate={Date.now()}
                        {...formik.getFieldProps('lastMaintenanceDate')}
                        onChange={(date) => {
                          formik.setFieldValue('lastMaintenanceDate', date);
                        }}
                        slotProps={{ textField: { fullWidth: true, helperText: null } }}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField fullWidth label="Quantity" {...formik.getFieldProps('quantity')} />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        {...formik.getFieldProps('notes')}
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={4}
                        label="Notes"
                      />
                    </Stack>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.remainsWithResidence}
                            onChange={(val) => {
                              formik.setFieldValue('remainsWithResidence', val);
                            }}
                            {...formik.getFieldProps('remainsWithResidence')}
                            color="info"
                          />
                        }
                        label="Remains with residence"
                      />
                      <button type="submit" className="button-primary">
                        Save
                      </button>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}

function getTitle(categoryQuery = '') {
  if (categoryQuery === 'appliance') return 'New Appliance';
  if (categoryQuery === 'utility') return 'New Utility';
  if (categoryQuery === 'lawnAndLeisure') return 'New Lawn & Leisure';
  if (categoryQuery === 'exterior') return 'New Exterior';
  if (categoryQuery === 'interior') return 'New Interior';
  if (categoryQuery === 'furniture') return 'New Furniture';
  return 'New Home Item';
}
