import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { Container, Skeleton, Box } from '@mui/material';
// routes
import { PATH_SERVICE_PRO } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import NewForm from './CompanyForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import {
  getCompany,
  createCompany,
  modifyCompany,
  deleteCompany,
  getCompanyType,
  getListSuccess
} from '../../../redux/slices/companyType';
import Listing from './CompanyListing';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

const TABLE_HEAD = [
  { id: 'name', label: 'Company Name', alignRight: false },
  { id: 'companyType', label: 'Company Type', alignRight: false },
  { id: 'companySize', label: 'Company Size', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'zipCode', label: 'Zip Code', alignRight: false },
  { id: 'website', label: 'Website', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'yearFounded', label: 'Founded Year', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

export default function CompanyAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { List, success, error, isLoading } = useSelector((state) => state.company);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');
  const isCreate = pathname.includes('create');

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getCompany());
  }, [dispatch, success, enqueueSnackbar]);

  const handleEdit = (row) => {
    navigate(PATH_SERVICE_PRO.pro.companyEdit, { state: row });
  };

  const listingComponent = (
    <Page title="Company">
      <Container>
        <HeaderBreadcrumbs
          heading="Company"
          links={[
            { name: 'Dashboard', href: PATH_SERVICE_PRO.dashboard },
            { name: 'Pro', href: PATH_SERVICE_PRO.pro.company },
            { name: 'Company', href: PATH_SERVICE_PRO.pro.company }
          ]}
        />
        {!isLoading > 0 ? (
          <Listing
            tableHead={TABLE_HEAD}
            title="Company"
            dataList={List}
            listSuccess={getListSuccess}
            dataDelete={deleteCompany}
            handleEdit={handleEdit}
            isAdmin
          />
        ) : (
          SkeletonLoad
        )}
      </Container>
    </Page>
  );

  const formComponent = (
    <Page title={isEdit ? 'Modify' : 'Create'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Company"
          links={[
            { name: 'Dashboard', href: PATH_SERVICE_PRO.dashboard },
            { name: 'Pro', href: PATH_SERVICE_PRO.pro.company },
            { name: 'Company', href: PATH_SERVICE_PRO.pro.company },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />
        <NewForm
          isEdit={isEdit}
          currentData={state}
          createData={createCompany}
          modifyData={modifyCompany}
          companyType={getCompanyType}
          error={error}
          isLoading={isLoading}
          isIcon={false}
        />
      </Container>
    </Page>
  );

  return isEdit || isCreate ? formComponent : listingComponent;
}
