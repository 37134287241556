import { createSlice } from '@reduxjs/toolkit';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';
// firebase
import { firebase } from '../../contexts/firebase-config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isTaskLoading: false,
  error: false,
  success: '',
  taskSuccess: '',
  masterBrandList: [],
  masterApplianceTypeList: [],
  appliancesList: [],
  userList: [],
  isLoadingBtnUpld: false,
  taskList: [],
  allTaskList: [],
  recentEditList: []
};

const slice = createSlice({
  name: 'appliance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startTaskLoading(state) {
      state.isTaskLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // SUCCESS MESSAGE
    success(state, action) {
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MASTER BRAND LIST
    getBrandListSuccess(state, action) {
      state.isLoading = false;
      state.masterBrandList = action.payload;
      state.success = '';
    },

    // GET MASTER APPLIANCE TYPE LIST
    getApplianceTypeListSuccess(state, action) {
      state.isLoading = false;
      state.masterApplianceTypeList = action.payload;
      state.success = '';
    },

    // GET TASK LIST
    taskListSuccess(state, action) {
      state.isLoading = false;
      state.taskList = action.payload;
      state.success = '';
      state.taskSuccess = '';
    },

    // GET TASK LIST
    allTaskListSuccess(state, action) {
      state.isLoading = false;
      state.allTaskList = action.payload;
      state.success = '';
      state.taskSuccess = '';
    },

    // CREAT APPLIANCES SUCCESS
    createAppliancesSuccess(state, action) {
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.isTaskLoading = false;
      state.success = action.payload;
      state.taskSuccess = action.payload;
      state.isLoadingBtnUpld = false;
    },

    createTaskSuccess(state, action) {
      state.isDeleteLoading = false;
      state.isTaskLoading = false;
      state.taskSuccess = action.payload;
    },

    // GET APPLIANCES LIST
    getAppliancesListSuccess(state, action) {
      state.isLoading = false;
      state.appliancesList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    },

    // GET RECENT EDIT LIST
    getRecentEditListSuccess(state, action) {
      state.isLoading = false;
      state.recentEditList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    },

    // GET USER LIST
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
      state.success = '';
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  success,
  taskSuccess,
  hasError,
  startLoading,
  startDeleteLoading,
  startTaskLoading,
  getBrandListSuccess,
  getApplianceTypeListSuccess,
  taskListSuccess,
  allTaskListSuccess,
  createAppliancesSuccess,
  getAppliancesListSuccess,
  getUserListSuccess,
  startLoadingUpld,
  createTaskSuccess,
  getRecentEditListSuccess
} = slice.actions;

export function getUserUID() {
  return firebase.auth().currentUser.uid;
}

// -------------------- START BRAND -------------------------------------

export function getBrands() {
  return async (dispatch) => {
    try {
      const params = { type: 'brand' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getBrandListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createBrand(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, status } = data;
      const newData = {
        name,
        type: 'brand',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.brands);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyBrand(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status } = data;
      const newData = {
        name,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.brands);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteBrand(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END BRAND -------------------------------------

// -------------------- START APPLIANCE TYPE --------------------------

export function getApplianceType() {
  return async (dispatch) => {
    try {
      const params = { type: 'applianceType' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getApplianceTypeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createAppliancesType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, icon, status } = data;
      const newData = {
        name,
        icon,
        type: 'applianceType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.applianceType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyAppliancesType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status, icon } = data;
      const newData = {
        name,
        icon,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.applianceType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteAppliancesType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
// -------------------- END APPLIANCE TYPE ------------------------------

// -------------------- START ROLE --------------------------------------

export function getUsers() {
  return async (dispatch) => {
    try {
      const resp = await webServices.getUsers();
      if (resp && resp.success === true) {
        dispatch(getUserListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyRole(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { UID, role, accountStatus, walkthroughStatus } = data;
      const newData = {
        UID,
        role,
        accountStatus: accountStatus === 'Disabled',
        walkthroughStatus
      };
      const resp = await webServices.updateUser(newData);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.role);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyStatus(data) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { token, accountStatus } = data;
      const newData = {
        token,
        accountStatus: accountStatus === 'Disabled'
      };
      const resp = await webServices.confirmUser(newData);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Mail is sent to the attempted user'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getToNewUser(data) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { token } = data;
      const newData = {
        token
      };
      const resp = await webServices.getToNewUser(newData);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Mail is sent to the attempted user'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteRole(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteUser({ ids });
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
// -------------------- END ROLE ----------------------------------------

// -------------------- APPLIANCE  --------------------------------------

export function createAppliances(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createService(data);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Create success'));
        navigate(PATH_DASHBOARD.general.appliances);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyAppliances(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, image } = previousData;
      if (data.image) {
        data.oldImage = image;
      }
      delete data.otherFiles;
      const resp = await webServices.updateService(id, data);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.appliances);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getAppliances() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type: 'applianceType' };
      const resp = await webServices.getService({ params });
      if (resp && resp.success === true) {
        dispatch(getAppliancesListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteAppliances(navigate, id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        isDeleted: true
      };
      const resp = await webServices.softDeleteService(id, data);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Delete success'));
        navigate(PATH_DASHBOARD.general.appliances);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id, navigate) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const data = { otherFiles, type: 'applianceType' };
      const resp = await webServices.fileUploadService(id, data);
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Uploaded success'));
        dispatch(createAppliancesSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.appliancesEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFiles(fileURL, id, navigate) {
  return async (dispatch) => {
    try {
      const resp = await webServices.fileDeleteService(id, { fileURL });
      if (resp && resp.success === true) {
        dispatch(createAppliancesSuccess('Deleted success'));
        dispatch(createAppliancesSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.appliancesEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END APPLIANCE  ----------------------------------

export function getRecentEdited() {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const resp = await webServices.getRecentEdited({});
      if (resp && resp.success === true) {
        dispatch(getRecentEditListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
