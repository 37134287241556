import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Card, Grid, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { useSelector, useDispatch } from '../../../redux/store';
import {
  getWarrantyType,
  createWarrantyData,
  modifyWarrantyData,
  deleteWarrantyData
} from '../../../redux/slices/warranty';

import AlertDialog from '../../../pages/dashboard/admin/AlertDialog';

WarrantyNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentWarranty: PropTypes.object
};

export default function WarrantyNewForm({ isEdit, currentWarranty }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { masterWarrantyTypeList, error, success, isLoading, isDeleteLoading } = useSelector((state) => state.warranty);

  const [dialogStatus, setDialogStatus] = useState(false);

  useEffect(() => {
    dispatch(getWarrantyType());
  }, [dispatch]);

  const schema = Yup.object().shape({
    item: Yup.object().required('Item Name is required'),
    phoneNumber: Yup.number().required('Phone Number is required'),
    warrantyType: Yup.string().required('Warranty Provider is required')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item: currentWarranty?.warrantyDetail?.item || '',
      warrantyType: currentWarranty?.warrantyDetail?.warrantyProvider || '',
      endDate: currentWarranty?.warrantyDetail?.endDate ? parseISO(currentWarranty.warrantyDetail.endDate) : null,
      modelNumber: currentWarranty?.warrantyDetail?.modelNumber || '',
      serialNumber: currentWarranty?.warrantyDetail?.serialNumber || '',
      notes: currentWarranty?.warrantyDetail?.notes || '',
      phoneNumber: currentWarranty?.warrantyDetail?.phoneNumber || '',
      image: currentWarranty?.warrantyDetail?.image || '',
      otherFiles: currentWarranty?.warrantyDetail?.otherFiles || []
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (values.phoneNumber.length !== 10) {
          touched.phoneNumber = 'Only 10 Digit is required';
          errors.phoneNumber = 'Only 10 Digit is required';
        } else {
          values.warrantyProvider = values.warrantyType;
          delete values.warrantyType;
          if (isEdit) {
            dispatch(modifyWarrantyData(values, navigate, currentWarranty));
          } else {
            dispatch(createWarrantyData(values, navigate));
          }
        }
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
      }
    }
  });
  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (success) enqueueSnackbar(success, { variant: 'success' });
  }, [success, enqueueSnackbar]);

  const handleDelete = () => {
    setDialogStatus(true);
  };

  const handleClose = () => {
    setDialogStatus(false);
  };

  const handleConfrim = () => {
    const { id } = currentWarranty;
    dispatch(deleteWarrantyData(navigate, [id], values.otherFiles));
    setDialogStatus(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    value={masterWarrantyTypeList.find((option) => option.name === values.item?.name) || null}
                    onChange={(e, value) => setFieldValue('item', value || '')}
                    options={masterWarrantyTypeList}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item"
                        variant="outlined"
                        error={Boolean(touched.item && errors.item)}
                        helperText={touched.item && errors.item}
                      />
                    )}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Warranty Provider"
                    {...getFieldProps('warrantyType')}
                    error={Boolean(touched.warrantyType && errors.warrantyType)}
                    helperText={touched.warrantyType && errors.warrantyType}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    {...getFieldProps('phoneNumber')}
                    label="Phone Number"
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <DatePicker
                    id="endDate"
                    name=""
                    label="End Date"
                    minDate={Date.now()}
                    {...getFieldProps('endDate')}
                    onChange={(date) => {
                      setFieldValue('endDate', date);
                    }}
                    slotProps={{ textField: { fullWidth: true, helperText: null } }}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Model Number" {...getFieldProps('modelNumber')} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Serial Number" {...getFieldProps('serialNumber')} />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField {...getFieldProps('notes')} fullWidth multiline minRows={4} maxRows={4} label="Notes" />
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: !isEdit ? 'flex-end' : 'space-between' }}>
                  {isEdit ? (
                    <LoadingButton color="error" variant="contained" loading={isDeleteLoading} onClick={handleDelete}>
                      Delete Item
                    </LoadingButton>
                  ) : null}
                  {/* <FormControlLabel
                    style={{ marginRight: !isEdit ? 130 : 0 }}
                    control={
                      <Switch
                        checked={values.remainWithResidence}
                        onChange={(val) => {
                          setFieldValue('remainWithResidence', val);
                        }}
                        {...getFieldProps('remainWithResidence')}
                        color="info"
                      />
                    }
                    label="Remains with residence"
                  /> */}
                  <LoadingButton className="button-primary" type="submit" variant="contained" loading={isLoading}>
                    {!isEdit ? 'Create Warranty' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
      <AlertDialog
        dialogStatus={dialogStatus}
        handleClose={handleClose}
        handleConfrim={handleConfrim}
        name={values?.warrantyType}
      />
    </FormikProvider>
  );
}
