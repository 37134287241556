import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// material
import { Container, Grid, Skeleton } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getWarrantyData } from '../../redux/slices/warranty';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { AddNewCard, WarrantyCard } from '../../components/_dashboard/user/cards';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(8)].map((_, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

export default function Warranty() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { masterWarrantyList, success, isLoading } = useSelector((state) => state.warranty);

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getWarrantyData());
  }, [dispatch, success, enqueueSnackbar]);

  const handleEdit = (warranty) => () => {
    navigate(PATH_DASHBOARD.general.warrantyEdit, { state: warranty });
  };

  return (
    <Page title="Warranty">
      <Container>
        <HeaderBreadcrumbs
          heading="Warranty"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.general.home }, { name: 'Warranty' }]}
        />
        <Grid container spacing={3}>
          <Grid key="new_lawnLeisure" item xs={12} sm={6} md={4}>
            <AddNewCard type="Warranty" createLink={PATH_DASHBOARD.general.warrantyCreate} />
          </Grid>
          {masterWarrantyList.map((warranty) => (
            <Grid key={warranty.id} item xs={12} sm={6} md={4} style={{ cursor: 'pointer' }}>
              <WarrantyCard warranty={warranty} onClick={handleEdit(warranty)} />
            </Grid>
          ))}

          {isLoading && SkeletonLoad}
        </Grid>
      </Container>
    </Page>
  );
}
