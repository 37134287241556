import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { createHomeItem } from '../../../../api';
import { fetchData } from '../../../../services';

StepForm3.propTypes = {
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func
};

export default function StepForm3({ handlePrevious, handleNext }) {
  const [appliances, setAppliances] = React.useState([]);

  React.useEffect(() => {
    fetchData('/appliance').then(setAppliances);
  }, []);

  const formSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    brand: Yup.string().required('Brand is required'),
    itemId: Yup.mixed().required('Appliance Type is required')
  });

  const formik = useFormik({
    initialValues: {
      description: '',
      brand: '',
      itemId: ''
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        await createHomeItem({
          item: values.itemId,
          brand: values.brand,
          description: values.description,
          installer: '',
          quantity: 1,
          notes: '',
          remainsWithResidence: true
        });
        handleNext('step5');
      } catch (error) {
        setErrors(error);
      }
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
            You're doing great!
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            Now lets add your first appliance.
          </Typography>
        </Box>
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <Autocomplete
                fullWidth
                onChange={(e, value) => setFieldValue('itemId', value?._id || '')}
                options={appliances}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Appliance Type"
                    variant="outlined"
                    error={Boolean(touched.itemId && errors.itemId)}
                    helperText={touched.itemId && errors.itemId}
                  />
                )}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                {...getFieldProps('brand')}
                label="Brand"
                variant="outlined"
                fullWidth
                error={Boolean(touched.brand && errors.brand)}
                helperText={touched.brand && errors.brand}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                label="Description"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Stack>
          </Stack>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button
              size="large"
              type="button"
              variant="outlined"
              sx={{ width: 120, marginRight: '15px', marginLeft: '15px' }}
              onClick={() => handlePrevious('step3')}
            >
              Previous
            </Button>
            <LoadingButton
              className="button-primary"
              size="large"
              type="submit"
              variant="contained"
              sx={{ width: 120, marginRight: '15px', marginLeft: '15px' }}
              onClick={handleSubmit}
            >
              Next
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
