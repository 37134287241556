import React, { useEffect, useState } from 'react';
import { Container, Skeleton, Box } from '@mui/material';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import TaskList from './TaskList';
import { viewTaskList } from '../../api';

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

export default function Tasks() {
  const [data, setData] = useState(null);

  useEffect(() => {
    viewTaskList().then((data) => {
      setData(data);
    });
  }, [setData]);

  return (
    <Page title="Tasks">
      <Container>
        <HeaderBreadcrumbs
          heading="Tasks"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Tasks', href: PATH_DASHBOARD.general.tasks },
            { name: 'List' }
          ]}
        />

        {data ? <TaskList tasks={data.tasks} /> : SkeletonLoad}
      </Container>
    </Page>
  );
}
