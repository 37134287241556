import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React from 'react';
import { useSnackbar } from 'notistack';
// import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
// import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card, Grid, Stack, TextField, FormControlLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Switch from '@mui/material/Switch';
// import { useDispatch } from '../../../redux/store';
// import { deleteAppliances } from '../../../redux/slices/appliances';

// import AlertDialog from '../../../pages/dashboard/admin/AlertDialog';
import { createHomeItem } from '../../../api';

// const DropZoneStyle = styled('div')(({ theme }) => ({
//   outline: 'none',
//   display: 'flex',
//   textAlign: 'center',
//   alignItems: 'center',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: theme.palette.background.neutral,
//   border: `1px dashed ${theme.palette.grey[500_32]}`,
//   '&:hover': { opacity: 0.72, cursor: 'pointer' },
//   [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
// }));

AppliancesNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentAppliance: PropTypes.object,
  appliances: PropTypes.array,
  brands: PropTypes.array
};

export default function AppliancesNewForm({ isEdit, appliances, brands }) {
  // const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const dispatch = useDispatch();
  // const [uploadedImg, setUploadedImg] = useState(currentAppliance?.image || null);
  // const [imgTypeError, setImgTypeError] = useState(false);
  // const [dialogStatus, setDialogStatus] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [isFileUploaded, setIsFileUploaded] = useState(false);
  // const [deleteFile, setDeleteFile] = useState(null);
  // const [fileDialogStatus, setFileDialogStatus] = useState(false);

  const NewAppliancesSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    brand: Yup.string().required('Brand is required'),
    item: Yup.mixed().required('Appliance Type is required')
  });

  const formik = useFormik({
    initialValues: {
      item: '',
      description: '',
      brand: '',
      purchaseDate: null,
      company: '',
      lastMaintenanceDate: null,
      quantity: 1,
      notes: '',
      remainsWithResidence: false
    },
    validationSchema: NewAppliancesSchema,
    onSubmit: async (values) => {
      try {
        await createHomeItem(values);
        enqueueSnackbar('Saved!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  // const convertBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });

  // const handleChange = async (event) => {
  //   const originalFile = event.target.files[0];
  //   const base64 = await convertBase64(originalFile);

  //   const newFile = new File([originalFile], `${moment().unix()}-${originalFile.name}`, { type: originalFile.type });
  //   const img = URL.createObjectURL(newFile);
  //   setFieldValue('image', { isImage: true, originalName: originalFile.name, fileBase64: base64 });
  //   setUploadedImg(img);
  //   setImgTypeError(false);
  // };

  // const handleDelete = () => {
  //   setDialogStatus(true);
  // };

  // const handleClose = () => {
  //   setDialogStatus(false);
  // };

  // const handleConfrim = () => {
  //   const { id } = currentAppliance;
  //   dispatch(deleteAppliances(navigate, id));
  //   setDialogStatus(false);
  // };

  // const handleCloseFile = () => {
  //   setFileDialogStatus(false);
  // };

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const otherFiles = [];
  //     setIsFileUploaded(true);
  //     acceptedFiles.map(async (file) => {
  //       const base64 = await convertBase64(file);
  //       otherFiles.push({ originalName: file.name, isImage: false, fileBase64: base64 });
  //       setFieldValue('otherFiles', otherFiles);

  //       const newFile = new File([file], `${moment().unix()}_-_-${file.name}`, { type: file.type });
  //       Object.assign(newFile, {
  //         preview: URL.createObjectURL(newFile),
  //         originalName: file.name
  //       });
  //       setUploadedFiles((t) => [...t, newFile]);
  //       return true;
  //     });
  //   },
  //   [setFieldValue]
  // );

  // const handleRemove = (file) => {
  //   setFileDialogStatus(true);
  //   setDeleteFile(file);
  // };

  // const handleRemoveAll = () => {
  //   setFileDialogStatus(true);
  //   setDeleteFile(false);
  // };

  // const handleConfrimFile = () => {
  //   const { id, otherFiles } = currentAppliance;
  //   if (id) {
  //     const fileURL = [];
  //     if (deleteFile) {
  //       const filteredItemss = uploadedFiles.filter((_file) => _file !== deleteFile);
  //       setUploadedFiles(filteredItemss);
  //       if (deleteFile?.downloadURL) fileURL.push(deleteFile?.downloadURL);
  //     } else {
  //       setUploadedFiles([]);
  //       otherFiles.forEach((el) => {
  //         if (el.downloadURL) fileURL.push(el.downloadURL);
  //       });
  //     }
  //     if (fileURL.length > 0) dispatch(deleteFiles(fileURL, id, navigate));
  //     // else setIsFileUploaded(false);
  //   }
  //   setFileDialogStatus(false);
  // };

  // const onFileSubmit = () => {
  //   if (currentAppliance?.id) {
  //     const id = currentAppliance?.id;
  //     const { otherFiles } = values;
  //     dispatch(uploadFiles(otherFiles, id, navigate));
  //   }
  // };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    onChange={(e, value) => {
                      setFieldValue('item', value.id || '');
                    }}
                    options={appliances}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Appliance Type"
                        variant="outlined"
                        error={Boolean(touched.item && errors.item)}
                        helperText={touched.item && errors.item}
                      />
                    )}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Autocomplete
                    fullWidth
                    onChange={(e, value) => setFieldValue('brand', value || '')}
                    options={brands}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Brand"
                        variant="outlined"
                        error={Boolean(touched.brand && errors.brand)}
                        helperText={touched.brand && errors.brand}
                      />
                    )}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Description"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <DatePicker
                    name="purchaseDate"
                    label="Purchase Date"
                    maxDate={Date.now()}
                    {...getFieldProps('purchaseDate')}
                    onChange={(date) => {
                      setFieldValue('purchaseDate', date);
                    }}
                    format="MM/dd/yyyy"
                    slotProps={{ textField: { fullWidth: true, helperText: null } }}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Company (Installer)" {...getFieldProps('company')} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <DatePicker
                    name="lastMaintenanceDate"
                    label="Last Maintenance Date"
                    maxDate={Date.now()}
                    {...getFieldProps('lastMaintenanceDate')}
                    onChange={(date) => {
                      setFieldValue('lastMaintenanceDate', date);
                    }}
                    slotProps={{ textField: { fullWidth: true, helperText: null } }}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Quantity" {...getFieldProps('quantity')} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField {...getFieldProps('notes')} fullWidth multiline minRows={4} maxRows={4} label="Notes" />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: !isEdit ? 'flex-end' : 'space-between' }}>
                  <FormControlLabel
                    style={{ marginRight: !isEdit ? 130 : 0 }}
                    control={
                      <Switch
                        checked={values.remainsWithResidence}
                        onChange={(val) => {
                          setFieldValue('remainsWithResidence', val);
                        }}
                        {...getFieldProps('remainsWithResidence')}
                        color="info"
                      />
                    }
                    label="Remains with residence"
                  />
                  <button type="submit" className="button-primary">
                    {!isEdit ? 'Create Appliance' : 'Save Changes'}
                  </button>
                </Box>
              </Stack>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3 }}>
              <Button variant="contained" component="label">
                {!isEdit || !uploadedImg ? 'Upload Image' : 'Change Image'}
                <input type="file" accept="image/*" hidden onChange={handleChange} />
              </Button>
              <Box sx={{ mt: 4 }}>
                <DropZoneStyle>
                  {!uploadedImg ? (
                    <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                      No Image Uploaded
                    </Typography>
                  ) : (
                    <img alt={uploadedImg} src={uploadedImg} style={{ borderRadius: '8px' }} />
                  )}
                </DropZoneStyle>
                {imgTypeError && (
                  <FormHelperText error sx={{ px: 2 }}>
                    Upload Image Only
                  </FormHelperText>
                )}
              </Box>
            </Card>
            <Card sx={{ py: 10, px: 3, mt: 5 }}>
              <Box>
                {/* <UploadMultiFile
                  showPreview
                  maxSize={16777216}
                  files={uploadedFiles}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                  onFileSubmit={onFileSubmit}
                  isLoadingBtnUpld={isLoadingBtnUpld}
                  text="Save copies of receipts or relevant documents"
                  error={Boolean(touched.images && errors.images)}
                  isFileUploaded={isEdit && isFileUploaded}
                /> 
                {touched.images && errors.images && (
                  <FormHelperText error sx={{ px: 2 }}>
                    {touched.images && errors.images}
                  </FormHelperText>
                )}
              </Box>
            </Card>
          </Grid> 
    */}
        </Grid>
      </Form>
      {/* <AlertDialog
        dialogStatus={dialogStatus}
        handleClose={handleClose}
        handleConfrim={handleConfrim}
        name={values?.name}
      />
      <AlertDialog dialogStatus={fileDialogStatus} handleClose={handleCloseFile} handleConfrim={handleConfrimFile} />
      */}
    </FormikProvider>
  );
}
