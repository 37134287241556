import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Card, Grid, Stack, TextField, Typography } from '@mui/material';

// redux
import { useDispatch } from '../../../redux/store';

// ----------------------------------------------------------------------

NewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  createData: PropTypes.func,
  modifyData: PropTypes.func,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  isIcon: PropTypes.bool
};

export default function NewForm({ isEdit, currentData, createData, modifyData, error, isLoading, isIcon }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const FormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    icon: Yup.string().required('Icon Name is required'),
    status: Yup.string().required('Status is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentData?.name || '',
      icon: isIcon ? currentData?.icon || '' : 'icon',
      status: currentData?.status === false ? 'Inactive' : 'Active'
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (isEdit) {
          dispatch(modifyData(values, navigate, currentData));
        } else {
          dispatch(createData(values, navigate));
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  // spacing={{ xs: 3, sm: 2 }}
                  sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2 }}
                >
                  <TextField
                    fullWidth
                    label="Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  {isIcon ? (
                    <TextField
                      fullWidth
                      label="Fontawesome Icon Name"
                      {...getFieldProps('icon')}
                      error={Boolean(touched.icon && errors.icon)}
                      helperText={touched.icon && errors.icon}
                    />
                  ) : null}
                  <Autocomplete
                    fullWidth
                    value={values.status}
                    onChange={(e, value) => setFieldValue('status', value || '')}
                    options={['Active', 'Inactive']}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                        error={Boolean(touched.status && errors.status)}
                        helperText={touched.status && errors.status}
                      />
                    )}
                  />
                  {isIcon ? (
                    <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                      Icon name example:
                      <img alt="icon_example" src="/static/icon_name_example.png" style={{ width: '60%' }} />
                    </Typography>
                  ) : null}
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    {!isEdit ? 'Create' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
