import { createSlice } from '@reduxjs/toolkit';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isLoadingBtnUpld: false,
  error: false,
  success: '',
  masterExteriorTypeList: [],
  exteriorList: []
};

const slice = createSlice({
  name: 'exterior',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MASTER EXTERIOR TYPE LIST
    getExteriorTypeListSuccess(state, action) {
      state.isLoading = false;
      state.masterExteriorTypeList = action.payload;
      state.success = '';
    },

    // CREAT EXTERIOR SUCCESS
    createExteriorSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // GET EXTERIOR LIST
    getExteriorListSuccess(state, action) {
      state.isLoading = false;
      state.exteriorList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  startDeleteLoading,
  getExteriorTypeListSuccess,
  createExteriorSuccess,
  getExteriorListSuccess,
  startLoadingUpld
} = slice.actions;

// -------------------- START EXTERIOR TYPE -----------------------------

export function getExteriorType() {
  return async (dispatch) => {
    try {
      const params = { type: 'exteriorType' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getExteriorTypeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createExteriorsType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, icon, status } = data;
      const newData = {
        name,
        icon,
        type: 'exteriorType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.exteriorType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyExteriorsType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status, icon } = data;
      const newData = {
        name,
        icon,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.exteriorType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteExteriorsType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END EXTERIOR TYPE ------------------------------

// -------------------- EXTERIOR ---------------------------------------

export function createExterior(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createService(data);
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Create success'));
        navigate(PATH_DASHBOARD.general.exterior);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyExterior(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, image } = previousData;
      if (data.image) {
        data.oldImage = image;
      }
      delete data.otherFiles;
      const resp = await webServices.updateService(id, data);
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.exterior);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getExteriors() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type: 'exteriorType' };
      const resp = await webServices.getService({ params });
      if (resp && resp.success === true) {
        dispatch(getExteriorListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteExterior(navigate, id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        isDeleted: true
      };
      const resp = await webServices.softDeleteService(id, data);
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Delete success'));
        navigate(PATH_DASHBOARD.general.exterior);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id, navigate) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const data = { otherFiles, type: 'exteriorType' };
      const resp = await webServices.fileUploadService(id, data);
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Uploaded success'));
        dispatch(createExteriorSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.exteriorEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFiles(fileURL, id, navigate) {
  return async (dispatch) => {
    try {
      const resp = await webServices.fileDeleteService(id, { fileURL });
      if (resp && resp.success === true) {
        dispatch(createExteriorSuccess('Deleted success'));
        dispatch(createExteriorSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.exteriorEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END EXTERIOR -----------------------------------
