import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Card, Grid, Stack, TextField, Checkbox, FormControlLabel } from '@mui/material';
// redux
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from '../../../redux/store';
import { getAllUsers, sendMail, deleteUser } from '../../../redux/slices/user';
import AlertDialog from '../../../pages/dashboard/admin/AlertDialog';
import { getRoles } from '../../../utils/role';

// ----------------------------------------------------------------------

SendEmailForm.propTypes = {
  isEdit: PropTypes.bool,
  currentInterior: PropTypes.object
};

export default function SendEmailForm({ isEdit, currentInterior }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { allUsers, error, isLoading } = useSelector((state) => state.user);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);
  const schema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    notes: Yup.string().required('Notes is required'),
    email: Yup.array().required('Email to send is required')
  });

  useEffect(() => {
    setFilteredUsers(allUsers);
  }, [allUsers]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: currentInterior?.email || '',
      subject: currentInterior?.subject || '',
      notes: currentInterior?.notes || '',
      selectAllValue: false
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const pushUsers = [];
        if (values.selectAllValue) {
          // eslint-disable-next-line array-callback-return
          values.email = allUsers.map((e) => {
            pushUsers.push({
              email: e.email,
              name: e.displayName
            });
          });
        } else {
          const getEmail = values.email;
          // eslint-disable-next-line array-callback-return
          getEmail.map((e) => {
            pushUsers.push({
              email: e.email,
              name: e.displayName
            });
          });
        }
        values.email = pushUsers;
        dispatch(sendMail(values));
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;
  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  const handleClose = () => {
    setDialogStatus(false);
  };
  const handleFilterByStatus = (e, value) => {
    if (value) {
      const filterData = allUsers.filter((val) => val.role === value);
      setFilteredUsers(filterData);
    } else {
      setFilteredUsers(allUsers);
    }
  };
  const handleConfrim = () => {
    const { id } = currentInterior;
    dispatch(deleteUser(navigate, id));
    setDialogStatus(false);
  };
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack>
                  <Autocomplete
                    fullWidth
                    onChange={(e, value) => handleFilterByStatus(e, value)}
                    options={getRoles()}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} label="Filter By Status" variant="outlined" />
                    )}
                  />
                </Stack>
                {!values.selectAllValue ? (
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <Autocomplete
                      fullWidth
                      multiple
                      options={filteredUsers}
                      onChange={(e, value) => setFieldValue('email', value || '')}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          {option.email}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Users Listing"
                          placeholder="Select Users to send mail"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      )}
                    />
                  </Stack>
                ) : null}
                <Stack>
                  <FormControlLabel
                    style={{ marginRight: 130 }}
                    control={
                      <Switch
                        checked={values.selectAllValue}
                        onChange={(val) => {
                          setFieldValue('selectAllValue', val);
                        }}
                        {...getFieldProps('selectAllValue')}
                        color="info"
                      />
                    }
                    label="Select All Users"
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField fullWidth label="Subject" {...getFieldProps('subject')} />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField {...getFieldProps('notes')} fullWidth multiline minRows={4} maxRows={4} label="Notes" />
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: !isEdit ? 'flex-end' : 'space-between' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading} onClick={handleSubmit}>
                    Send Mail Notification
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
      <AlertDialog
        dialogStatus={dialogStatus}
        handleClose={handleClose}
        handleConfrim={handleConfrim}
        name={values?.name}
      />
    </FormikProvider>
  );
}
