import { orderBy, filter } from 'lodash';
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect, useCallback, useState } from 'react';
// material
import { Box, Grid, Skeleton, Container, Stack } from '@mui/material';
import MainNavbar from '../../layouts/main/MainNavbar';
// redux
import { useDispatch, useSelector } from '../../redux/store';
// import { getPostsInitial, getMorePosts } from '../../redux/slices/blog';
import { getPostPublically } from '../../redux/slices/post';
// routes
import { PATH_ROOTS } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../../components/_dashboard/blog';
import EmptyContent from '../../components/EmptyContent';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

// ----------------------------------------------------------------------

const applySort = (posts, sortBy) => {
  if (sortBy === 'latest') {
    return orderBy(posts, ['createdAt'], ['desc']);
  }
  if (sortBy === 'oldest') {
    return orderBy(posts, ['createdAt'], ['asc']);
  }
  if (sortBy === 'popular') {
    return orderBy(posts, ['view'], ['desc']);
  }
  return posts;
};

const SkeletonLoad = (
  <Grid container spacing={3} sx={{ mt: 2 }}>
    {[...Array(4)].map((_, index) => (
      <Grid item xs={12} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ height: 200, borderRadius: 2 }} />
        <Box sx={{ display: 'flex', mt: 1.5 }}>
          <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
          <Skeleton variant="text" sx={{ mx: 1, flexGrow: 1 }} />
        </Box>
      </Grid>
    ))}
  </Grid>
);

export default function BlogPosts() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isPost = pathname.includes('blog');
  const isNews = pathname.includes('news');
  const [filters, setFilters] = useState('latest');
  const getType = () => (isNews ? 'news' : 'comingSoon');
  const [postType] = useState(isPost ? 'blog' : getType());

  // const { hasMore, index, step } = useSelector((state) => state.blog);
  const { postList, isLoading } = useSelector((state) => state.post);

  const [orderBy] = useState('name');
  const [order] = useState('asc');
  const [list, setList] = useState([]);

  useEffect(() => {
    // dispatch(getPostsInitial(index, step));
    dispatch(getPostPublically(postType));
  }, [dispatch, postType]);

  const onScroll = useCallback(() => dispatch(getPostPublically(postType)), [dispatch, postType]);

  useEffect(() => {
    setList(postList);
  }, [isPost, postList]);

  const handleChangeSort = (event) => {
    const sortedPosts = applySort(postList, event.target.value);
    setList(sortedPosts);
    setFilters(event.target.value);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangeSearch = (event) => {
    const filterList = applySortFilter(postList, getComparator(order, orderBy), event.target.value);
    setList(filterList);
  };

  return (
    <Page title="Posts">
      <MainNavbar />
      <Container>
        <HeaderBreadcrumbs
          heading="Blog"
          links={[{ name: 'Blog', href: PATH_ROOTS.blog.root }, { name: 'Posts' }]}
          // action={
          //   <Button
          //     variant="contained"
          //     component={RouterLink}
          //     to={PATH_ROOTS.blog.newPost}
          //     startIcon={<Icon icon={plusFill} />}
          //   >
          //     New Post
          //   </Button>
          // }
        />

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch postList={postList} isPost={isPost} handleChangeSearch={handleChangeSearch} />
          <BlogPostsSort query={filters} options={SORT_OPTIONS} onSort={handleChangeSort} />
        </Stack>
        <InfiniteScroll
          next={onScroll}
          // hasMore={hasMore}
          loader={SkeletonLoad}
          dataLength={postList.length}
          style={{ overflow: 'inherit' }}
        >
          <Grid container spacing={3}>
            {list.map((post, index) => (
              <BlogPostCard key={post.id} post={post} index={index} postType={postType} />
            ))}
            {isLoading && SkeletonLoad}
          </Grid>
          {!isLoading && list.length < 1 && <EmptyContent title="No record found" />}
        </InfiniteScroll>
      </Container>
    </Page>
  );
}
