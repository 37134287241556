import { createSlice } from '@reduxjs/toolkit';
import webServices from '../../services/webServices';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingBtnHom: false,
  isLoadingBtnUpld: false,
  error: false,
  success: '',
  address: {},
  home: {},
  masterStateList: []
};

const slice = createSlice({
  name: 'residence',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingHom(state) {
      state.isLoadingBtnHom = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // SUCCESS MESSAGE
    success(state, action) {
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
      state.isLoadingBtnHom = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ADDRESS DETAILS
    getAddressSuccess(state, action) {
      state.isLoading = false;
      state.address = action.payload;
      state.success = '';
    },

    // GET HOME DETAILS
    getHomeSuccess(state, action) {
      state.isLoadingBtnHom = false;
      state.home = action.payload;
      state.success = '';
    },

    // GET MASTER STATE LIST
    getStateListSuccess(state, action) {
      state.isLoading = false;
      state.masterStateList = action.payload.sort((a, b) => (a.stateName > b.stateName ? 1 : -1));
      state.success = '';
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  success,
  hasError,
  startLoading,
  isLoadingBtnHom,
  startLoadingHom,
  isLoadingBtnUpld,
  startLoadingUpld,
  createAddressSuccess,
  getAddressSuccess,
  getHomeSuccess,
  getStateListSuccess
} = slice.actions;

// -------------------- START ADDRESS --------------------------------

export function getAddress() {
  return async (dispatch) => {
    try {
      const resp = await webServices.getAddress({});
      if (resp && resp.success === true) {
        dispatch(getAddressSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createAddress(data) {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      let msg;
      let resp;
      const { id } = data;
      delete data.id;
      if (id) {
        resp = await webServices.updateAddress(id, data);
        msg = 'Update success';
        if (resp && resp.success === true) {
          dispatch(success(msg));
          dispatch(getAddress());
          return true;
        }
        dispatch(hasError(resp.message));
        return false;
      }
      resp = await webServices.createAddress(data);
      msg = 'Create success';
      if (resp && resp.success === true) {
        dispatch(success(msg));
        dispatch(getAddress());
        return true;
      }
      // dispatch(hasError(resp.message));
      return false;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getStateList() {
  return async (dispatch) => {
    try {
      const params = { type: 'state' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getStateListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
// -------------------- END ADDRESS ------------------------------------

// -------------------- START HOME -------------------------------------

export function getHome() {
  return async (dispatch) => {
    try {
      const resp = await webServices.getHome({});
      if (resp && resp.success === true) {
        dispatch(getHomeSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createHome(data) {
  return async (dispatch) => {
    dispatch(startLoadingHom());
    try {
      let msg;
      let resp;
      const { id } = data;
      delete data.id;
      if (id) {
        resp = await webServices.updateHome(id, data);
        msg = 'Update success';
      } else {
        resp = await webServices.createHome(data);
        msg = 'Create success';
      }

      if (resp && resp.success === true) {
        dispatch(success(msg));
        dispatch(getHome());
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const resp = await webServices.fileUploadHome(id, { otherFiles });
      if (resp && resp.success === true) {
        dispatch(success('Uploaded success'));
        dispatch(getHome());
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFiles(fileURL, id) {
  return async (dispatch) => {
    try {
      const resp = await webServices.fileDeleteHome(id, { fileURL });
      if (resp && resp.success === true) {
        dispatch(success('Deleted success'));
        dispatch(getHome());
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END HOME -------------------------------------
