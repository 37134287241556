import React, { useEffect } from 'react';
import { Container, Grid, Skeleton } from '@mui/material';
import Page from '../components/Page';
import { AppWelcome, AppFeatured } from '../components/_dashboard/general-app';
import { AnalyticsTasks, RecentlyEditedTimeline } from '../components/_dashboard/general-analytics';
import { fetchData } from '../services';

export default function DashboardHome() {
  const [data, setData] = React.useState(null);

  useEffect(() => {
    fetchData('/dashboard/home').then(setData);
  }, [setData]);

  function HomeHacks() {
    return (
      <>
        {data && data.homeHacks.length ? (
          <AppFeatured homeHacksList={data.homeHacks} />
        ) : (
          <Skeleton animation="wave" sx={{ top: 0, height: '100%', transform: 'none' }} />
        )}
      </>
    );
  }
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {data && (
              <AppWelcome displayName={data.user.displayName} completeWalkThrough={data.user.isWalkthroughComplete} />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <HomeHacks />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {data && data.recentlyEditedItems ? (
              <RecentlyEditedTimeline title="Recently Edited Items" list={data.recentlyEditedItems} />
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {data && data.currentTasks ? <AnalyticsTasks title="Tasks" list={data.currentTasks} /> : null}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
