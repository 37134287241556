// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Navigate } from 'react-router-dom';
// components
import Page from '../components/Page';
import About from './About';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD, PATH_SERVICE_PRO } from '../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)({
  height: '100%'
});

// ----------------------------------------------------------------------
export default function LandingPage() {
  const { isAuthenticated, user } = useAuth();
  let navigation;
  if (isAuthenticated) {
    navigation = ['Admin', 'Homeowner'].includes(user.role) ? (
      <Navigate to={PATH_DASHBOARD.general.app} />
    ) : (
      <Navigate to={PATH_SERVICE_PRO.dashboard} />
    );
  } else {
    navigation = <About />;
  }
  return (
    <RootStyle title="Resiview" id="move_top">
      {navigation}
    </RootStyle>
  );
}
