import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography } from '@mui/material';
import firebase from 'firebase/app';
import { PATH_DASHBOARD } from '../../routes/paths';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';
import useAuth from '../../hooks/useAuth';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
export function getUserUID() {
  return firebase.auth().currentUser.uid;
}
export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { resiviewUser } = useAuth();

  const renderContent = (
    <>
      <Scrollbar
        sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
      >
        <Box sx={{ mb: 2, mx: 2.5 }}>
          <Box sx={{ px: 10, py: 2.5 }}>
            <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', width: 100 }}>
              <img alt="Logo" src="/static/brand/Logo-04.png" />
            </Box>
          </Box>
          <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
            <AccountStyle>
              <MyAvatar />
              {resiviewUser && (
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {resiviewUser.displayName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {resiviewUser.role}
                  </Typography>
                </Box>
              )}
            </AccountStyle>
          </Link>
        </Box>

        {resiviewUser && <NavSection navConfig={sidebarConfig} user={resiviewUser} />}
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
