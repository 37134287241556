import { createSlice } from '@reduxjs/toolkit';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isLoadingBtnUpld: false,
  error: false,
  success: '',
  masterlawnLeisureList: [],
  lawnLeisureList: []
};

const slice = createSlice({
  name: 'lawnLeisure',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MASTER LAWN & LEISURE TYPE LIST
    getLawnLeisureTypeListSuccess(state, action) {
      state.isLoading = false;
      state.masterlawnLeisureList = action.payload;
      state.success = '';
    },

    // CREAT LAWN & LEISURE SUCCESS
    createLawnLeisureSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // GET LAWN & LEISURE LIST
    getLawnLeisureListSuccess(state, action) {
      state.isLoading = false;
      state.lawnLeisureList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  startDeleteLoading,
  getLawnLeisureTypeListSuccess,
  createLawnLeisureSuccess,
  getLawnLeisureListSuccess,
  startLoadingUpld
} = slice.actions;

// -------------------- START LAWN & LEISURE TYPE ------------------------

export function getLawnLeisureType() {
  return async (dispatch) => {
    try {
      const params = { type: 'lawnLeisureType' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getLawnLeisureTypeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createLawnLeisureType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, icon, status } = data;
      const newData = {
        name,
        icon,
        type: 'lawnLeisureType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.lawnLeisureType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyLawnLeisureType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status, icon } = data;
      const newData = {
        name,
        icon,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.lawnLeisureType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteLawnLeisureType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END START LAWN & LEISURE TYPE TYPE --------------

// -------------------- LAWN & LEISURE  ---------------------------------

export function createLawnLeisure(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createService(data);
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Create success'));
        navigate(PATH_DASHBOARD.general.lawnLeisure);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyLawnLeisure(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, image } = previousData;
      if (data.image) {
        data.oldImage = image;
      }
      delete data.otherFiles;
      const resp = await webServices.updateService(id, data);
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.lawnLeisure);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getLawnLeisure() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type: 'lawnLeisureType' };
      const resp = await webServices.getService({ params });
      if (resp && resp.success === true) {
        dispatch(getLawnLeisureListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteLawnLeisure(navigate, id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        isDeleted: true
      };
      const resp = await webServices.softDeleteService(id, data);
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Delete success'));
        navigate(PATH_DASHBOARD.general.lawnLeisure);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id, navigate) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const data = { otherFiles, type: 'lawnLeisureType' };
      const resp = await webServices.fileUploadService(id, data);
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Uploaded success'));
        dispatch(createLawnLeisureSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.lawnLeisureEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFiles(fileURL, id, navigate) {
  return async (dispatch) => {
    try {
      const resp = await webServices.fileDeleteService(id, { fileURL });
      if (resp && resp.success === true) {
        dispatch(createLawnLeisureSuccess('Deleted success'));
        dispatch(createLawnLeisureSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.lawnLeisureEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END LAWN & LEISURE  -----------------------------
