import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
// routes
import { PATH_ROOTS } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/',
    ref: 'homeRef'
  },
  {
    title: 'About',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: '/#about',
    ref: 'aboutRef'
  },
  {
    title: 'FAQs',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    path: '/#faqs',
    ref: 'faqsRef'
  },
  // {
  //   title: 'Team',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: '/#team',
  //   ref: 'teamRef'
  // },
  {
    title: 'Pages',
    path: '/pages',
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    children: [
      {
        subheader: '',
        items: [
          { title: 'Blog', path: PATH_ROOTS.blog.root, ref: 'teamRef' },
          { title: 'News', path: PATH_ROOTS.news.root, ref: 'teamRef' },
          // { title: 'FAQs', path: PATH_PAGE.faqs },
          // { title: 'Pricing', path: PATH_PAGE.pricing },
          // { title: 'Payment', path: PATH_PAGE.payment },
          // { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_ROOTS.comingSoon, ref: 'teamRef' }
        ]
      }
      // {
      //   subheader: 'Authentication',
      //   items: [
      //     { title: 'Login', path: PATH_AUTH.loginUnprotected },
      //     { title: 'Register', path: PATH_AUTH.registerUnprotected },
      //     { title: 'Reset password', path: PATH_AUTH.resetPassword },
      //     { title: 'Verify code', path: PATH_AUTH.verify }
      //   ]
      // },
      // {
      //   subheader: 'Error',
      //   items: [
      //     { title: 'Page 404', path: PATH_PAGE.page404 },
      //     { title: 'Page 500', path: PATH_PAGE.page500 }
      //   ]
      // },
      // {
      //   subheader: 'Dashboard',
      //   items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root }]
      // }
    ],
    ref: 'pagesRef'
  }
];

export default menuConfig;
