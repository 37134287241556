import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Container, Skeleton, Button, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import NewForm from './NewForm';
import TasksForm from './TasksForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import {
  createLawnLeisureType,
  modifyLawnLeisureType,
  deleteLawnLeisureType,
  getLawnLeisureType,
  getLawnLeisureTypeListSuccess
} from '../../../redux/slices/lawnLeisure';
import { getTasks, taskListSuccess, createTask, deleteTasks } from '../../../redux/slices/tasks';
import Listing from './Listing';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'icon', label: 'Icon', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

export default function MasterLawnTypeAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { masterlawnLeisureList, error, success, isLoading } = useSelector((state) => state.lawnLeisure);
  const { taskList, isDeleteLoading, taskSuccess, isTaskLoading } = useSelector((state) => state.tasks);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');
  const isCreate = pathname.includes('create');
  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    if (taskSuccess !== '') {
      enqueueSnackbar(taskSuccess, { variant: 'success' });
    }

    dispatch(getLawnLeisureType('admin'));

    if (isEdit) {
      dispatch(getTasks(state.id));
    }
  }, [dispatch, success, taskSuccess, enqueueSnackbar, isEdit, state]);

  const handleEdit = (row) => {
    navigate(PATH_DASHBOARD.admin.lawnLeisureTypeEdit, { state: row });
  };

  const handleAddTask = () => {
    const newData = [{}, ...taskList];
    dispatch(taskListSuccess(newData));
  };

  const taskForm = () =>
    taskList.map((el, i) => (
      <div key={i}>
        <TasksForm
          currentData={el}
          createData={createTask}
          deleteData={deleteTasks}
          deleteLoading={isDeleteLoading}
          isLoading={isTaskLoading}
          type="lawnLeisureType"
          typeReference={`masterLawnLeisureType/${state.id}`}
          typeId={state.id}
          itemType={state}
          addType="lawnLeisureType"
        />
      </div>
    ));

  const listingComponent = (
    <Page title="Master Lawn & Leisure Type">
      <Container>
        <HeaderBreadcrumbs
          heading="Master Lawn & Leisure Type"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Master Lawn & Leisure Type', href: PATH_DASHBOARD.admin.lawnLeisureType }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.admin.lawnLeisureTypeCreate}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New
            </Button>
          }
        />
        {masterlawnLeisureList.length > 0 ? (
          <Listing
            tableHead={TABLE_HEAD}
            title="Master Lawn & Leisure Type"
            dataList={masterlawnLeisureList}
            listSuccess={getLawnLeisureTypeListSuccess}
            dataDelete={deleteLawnLeisureType}
            handleEdit={handleEdit}
          />
        ) : (
          SkeletonLoad
        )}
      </Container>
    </Page>
  );

  const formComponent = (
    <Page title={isEdit ? 'Modify' : 'Create'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Master Lawn & Leisure Type"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Master Lawn & Leisure Type', href: PATH_DASHBOARD.admin.lawnLeisureType },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />
        <NewForm
          isEdit={isEdit}
          currentData={state}
          createData={createLawnLeisureType}
          modifyData={modifyLawnLeisureType}
          error={error}
          isLoading={isLoading}
          isIcon
        />
        {isEdit && (
          <Box sx={{ mt: 4 }}>
            <Button variant="contained" onClick={handleAddTask}>
              Add Task
            </Button>
          </Box>
        )}
        {isEdit && taskForm()}
      </Container>
    </Page>
  );

  return isEdit || isCreate ? formComponent : listingComponent;
}
