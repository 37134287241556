import { useEffect } from 'react';
// material
import { Container } from '@mui/material';
// components
import AddressNewForm from '../../components/_dashboard/address/AddressNewForm';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { getAddress } from '../../redux/slices/residence';
// ----------------------------------------------------------------------

export default function Address() {
  const dispatch = useDispatch();
  const { address, isLoading } = useSelector((state) => state.residence);

  useEffect(() => {
    dispatch(getAddress());
  }, [dispatch]);

  return (
    <Page title="Address: Create or update an address">
      <Container>
        <HeaderBreadcrumbs
          heading={!address.id ? 'Create a new Residence' : 'Modify Residence'}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.general.home }, { name: 'Residence' }]}
        />

        <AddressNewForm isEdit={!!address?.UID || false} currentAddress={address} isLoading={isLoading} />
      </Container>
    </Page>
  );
}
