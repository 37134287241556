import React from 'react';
import { sentenceCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Card, Divider, Skeleton, Container, Typography } from '@mui/material';
import MainNavbar from '../../layouts/main/MainNavbar';
import { PATH_ROOTS } from '../../routes/paths';
import Page from '../../components/Page';
import Markdown from '../../components/Markdown';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { BlogPostHero, BlogPostTags } from '../../components/_dashboard/blog';
import { viewBlogPost } from '../../api';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Skeleton width="100%" height={560} variant="rectangular" sx={{ borderRadius: 2 }} />
    <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
      <Skeleton variant="circular" width={64} height={64} />
      <Box sx={{ flexGrow: 1, ml: 2 }}>
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" height={20} />
      </Box>
    </Box>
  </>
);

export default function BlogPost() {
  const location = useLocation();
  const [post, setPost] = React.useState(null);
  const { title } = useParams();

  React.useEffect(() => {
    viewBlogPost({ title }).then(setPost);
  }, [title, setPost]);

  return (
    <Page title="Post Details">
      <MainNavbar />
      <Container style={{ marginTop: '100px' }}>
        <HeaderBreadcrumbs
          heading="Post Details"
          links={[{ name: 'Blog', href: PATH_ROOTS.blog.root }, { name: sentenceCase(title) }]}
        />
        {post && (
          <Card>
            <BlogPostHero post={post} shareUrl={location.pathname} />

            <Box sx={{ p: { xs: 3, md: 5 } }}>
              <Typography variant="h6" sx={{ mb: 5 }}>
                {post.description}
              </Typography>

              <Markdown children={post.content} />

              <Box sx={{ my: 5 }}>
                <Divider />
                <BlogPostTags post={post} />
                <Divider />
              </Box>
            </Box>
          </Card>
        )}

        {!post && SkeletonLoad}

        {!post && <Typography variant="h6">404 Post not found</Typography>}
      </Container>
    </Page>
  );
}
