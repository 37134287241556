import { createSlice } from '@reduxjs/toolkit';

// firebase
import firebase from 'firebase/app';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isLoadingBtnUpld: false,
  error: false,
  success: '',
  masterIndustryTypeList: [],
  contactList: [],
  contactData: {},
  taskList: []
};

const slice = createSlice({
  name: 'furniture',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startTaskLoading(state) {
      state.isTaskLoading = true;
    },
    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MASTER INDUSTRY TYPE LIST
    getIndustryTypeListSuccess(state, action) {
      state.isLoading = false;
      state.masterIndustryTypeList = action.payload;
      state.success = '';
    },

    // CREAT INDUSTRY SUCCESS
    createIndustrySuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // GET INDUSTRY LIST
    getContactListSuccess(state, action) {
      state.isLoading = false;
      state.contactList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    },
    // GET CONTACT DATA
    getContactDataSuccess(state, action) {
      state.isLoading = false;
      state.contactData = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    }
  },
  taskListSuccess(state, action) {
    state.isLoading = false;
    state.taskList = action.payload;
    state.success = '';
    state.taskSuccess = '';
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  startDeleteLoading,
  getIndustryTypeListSuccess,
  createIndustrySuccess,
  getContactListSuccess,
  startLoadingUpld,
  getContactDataSuccess,
  taskListSuccess,
  startTaskLoading
} = slice.actions;

export function getUserUID() {
  return firebase.auth().currentUser.uid;
}

// -------------------- START INDUSTRY TYPE ----------------------------

export function getIndustryType() {
  return async (dispatch) => {
    try {
      const params = { type: 'industryType' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getIndustryTypeListSuccess(resp.data));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createIndustryType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, icon, status } = data;
      const newData = {
        name,
        icon,
        type: 'industryType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createIndustrySuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.industryType);
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function modifyIndustryType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status, icon } = data;
      const newData = {
        name,
        icon,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createIndustrySuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.industryType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteIndustryType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createIndustrySuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createContact(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      data.industryType = data.name;
      delete data.name;
      const resp = await webServices.createContact(data);
      if (resp && resp.success === true) {
        dispatch(createIndustrySuccess('Create success'));
        navigate(PATH_DASHBOARD.general.contacts);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getContact() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getContact();
      if (resp && resp.success === true) {
        dispatch(getContactListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyContact(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id } = previousData;
      data.industryType = data.name;
      delete data.name;
      const resp = await webServices.updateContact(id, data);
      if (resp && resp.success === true) {
        dispatch(createIndustrySuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.contacts);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteContact(navigate, id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        isDeleted: true,
        ids: [id]
      };
      const resp = await webServices.deleteContact(id, data);
      if (resp && resp.success === true) {
        dispatch(createIndustrySuccess('Delete success'));
        navigate(PATH_DASHBOARD.general.contacts);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
