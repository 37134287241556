import React from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  Box,
  Card,
  InputAdornment,
  OutlinedInput,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableContainer,
  TablePagination,
  TextField,
  Toolbar
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { experimentalStyled as styled } from '@mui/material/styles';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Label from '../../components/Label';
import TaskItemModal from './TaskItemModal';

const FILTER_TASK = ['Completed', 'Current', 'Upcoming', 'Expired'];

const FILTER_CATEGORY = [
  { type: 'exterior', typeValue: 'Exterior' },
  { type: 'appliance', typeValue: 'Appliance' },
  { type: 'utility', typeValue: 'Utility' },
  { type: 'lawnLeisure', typeValue: 'Lawn & Leisure' },
  { type: 'interior', typeValue: 'Interior' },
  { type: 'furniture', typeValue: 'Furniture' },
  { type: 'residence', typeValue: 'Residence' }
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { boxShadow: 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px' },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

TaskList.propTypes = {
  tasks: PropTypes.array
};

export default function TaskList({ tasks }) {
  const [nameSearchText, setNameSearchText] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState('');
  const [typeFilter, setTypeFilter] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortProperty, setSortProperty] = React.useState('nameSort');
  const [isSortAsc, setIsSortAsc] = React.useState(true);
  const [selectedTask, setSelectedTask] = React.useState(null);

  let filteredTasks = tasks.filter(
    (task) => task.name.includes(nameSearchText) && task.status.includes(statusFilter) && task.type.includes(typeFilter)
  );
  filteredTasks = sortBy(filteredTasks, [sortProperty]);
  if (!isSortAsc) filteredTasks.reverse();

  const tasksToShow = filteredTasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const onNameSearchChange = (event) => {
    setNameSearchText(event.target.value);
    setPage(0);
  };

  const onStatusFilterChange = (_, value) => {
    setStatusFilter(value || '');
    setPage(0);
  };

  const onTypeFilterChange = (_, value) => {
    setTypeFilter(value?.type || '');
    setPage(0);
  };

  const onPageChange = (_, newPage) => setPage(newPage);

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSortLabelClick = (property) => () => {
    if (property === sortProperty) setIsSortAsc(!isSortAsc);
    setSortProperty(property);
  };

  const createSortLabelProps = (property) => ({
    onClick: onSortLabelClick(property),
    active: sortProperty === property,
    direction: isSortAsc ? 'asc' : 'desc'
  });

  return (
    <Page style={{ marginTop: '50px' }}>
      <Card>
        <RootStyle sx={{}}>
          <SearchStyle
            fullWidth
            value={nameSearchText}
            onChange={onNameSearchChange}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          <Autocomplete
            fullWidth
            onChange={onStatusFilterChange}
            options={FILTER_TASK}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField fullWidth {...params} label="Filter By Status" variant="outlined" />}
            sx={{ marginLeft: 2 }}
          />
          <Autocomplete
            fullWidth
            onChange={onTypeFilterChange}
            options={FILTER_CATEGORY}
            getOptionLabel={(option) => option.typeValue}
            renderInput={(params) => <TextField fullWidth {...params} label="Filter By Category" variant="outlined" />}
            sx={{ marginLeft: 2 }}
          />
        </RootStyle>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('nameSort')}>Name</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('itemNameSort')}>Type</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('startDateSort')}>Start Date</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('expirationDateSort')}>Expired</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('completionDateSort')}>Completed</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('completedBy')}>Completed By</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('notes')}>Notes</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel {...createSortLabelProps('status')}>Status</TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasksToShow.map((task) => (
                  <TableRow
                    hover
                    key={task.id}
                    tabIndex={-1}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSelectedTask(task)}
                  >
                    <TableCell>{task.name}</TableCell>
                    <TableCell align="left">{task.itemName}</TableCell>
                    <TableCell align="left">{task.startDate}</TableCell>
                    <TableCell align="left">{task.expirationDate}</TableCell>
                    <TableCell align="left">{task.completionDate}</TableCell>
                    <TableCell align="left">{task.completedBy}</TableCell>
                    <TableCell align="left">{task.notes}</TableCell>
                    <TableCell align="left">
                      <Label variant="ghost" color={getColorByStatus(task.status)}>
                        {task.status}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          onPageChange={onPageChange}
          page={page}
          count={filteredTasks.length}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          component="div"
        />
      </Card>
      {selectedTask && <TaskItemModal open parentCallBack={() => setSelectedTask(null)} currentData={selectedTask} />}
    </Page>
  );
}

const getColorByStatus = (status) => {
  if (status === 'Upcoming') return 'default';
  if (status === 'Expired') return 'error';
  if (status === 'Completed') return 'success';
  return 'secondary';
};
