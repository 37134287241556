import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Card, Grid, Stack, TextField } from '@mui/material';
// redux
import { useSelector, useDispatch } from '../../../redux/store';
import { getCompanyType } from '../../../redux/slices/companyType';

// ----------------------------------------------------------------------

const PRO_ROLE = ['Admin', 'Employee'];

UserForm.propTypes = {
  isEdit: PropTypes.bool,
  currentData: PropTypes.object,
  createData: PropTypes.func,
  modifyData: PropTypes.func
};

export default function UserForm({ isEdit, currentData, createData, modifyData }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { error, success, isLoading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCompanyType());
  }, [dispatch]);

  const NewSchema = Yup.object().shape({
    displayName: Yup.string().required('Full name is required'),
    email: Yup.string().required('Email is required'),
    role: Yup.string().required('Account type is required'),
    status: Yup.string().required('Status is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName: currentData?.name || '',
      email: currentData?.email || '',
      role: currentData?.role || 'Employee',
      status: currentData?.status || 'Enabled'
    },
    validationSchema: NewSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (isEdit) {
          dispatch(modifyData(values, navigate, currentData));
        } else {
          dispatch(createData(values, navigate));
        }
      } catch (error) {
        // console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (success) enqueueSnackbar(success, { variant: 'success' });
  }, [success, enqueueSnackbar]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr' }, gap: 2 }}
            >
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Full Name"
                  {...getFieldProps('displayName')}
                  error={Boolean(touched.displayName && errors.displayName)}
                  helperText={touched.displayName && errors.displayName}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <TextField
                  fullWidth
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Autocomplete
                  fullWidth
                  value={values.role}
                  onChange={(e, value) => setFieldValue('role', value || '')}
                  options={PRO_ROLE}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Account Type"
                      variant="outlined"
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    />
                  )}
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Autocomplete
                  fullWidth
                  value={values.status}
                  onChange={(e, value) => setFieldValue('status', value || '')}
                  options={['Enabled', 'Disabled']}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      error={Boolean(touched.status && errors.status)}
                      helperText={touched.status && errors.status}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={isLoading}>
                {!isEdit ? 'Create User' : 'Save Changes'}
              </LoadingButton>
            </Box>
          </Card>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
