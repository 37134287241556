import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import { serviceProAccess, homeowner } from '../utils/role';
import ConfirmLayout from '../layouts/confirm';
import NewUserLayout from '../layouts/newUser';
import Login from '../pages/authentication/Login';
import Walkthrough from '../pages/dashboard/walkthrough/Walkthrough';
import Register from '../pages/authentication/Register';
import ResetPassword from '../pages/authentication/ResetPassword';
import VerifyCode from '../pages/authentication/VerifyCode';
import Address from '../pages/dashboard/Address';
import Residance from '../pages/dashboard/Residence';
import AppliancesCreate from '../pages/dashboard/AppliancesCreate';
import Contacts from '../pages/dashboard/Contact';
import ContactCreate from '../pages/dashboard/ContactCreate';
import Tasks from '../pages/dashboard/Tasks';
import Warranties from '../pages/dashboard/Warranties';
import Warranty from '../pages/dashboard/Warranty';
import WarrantyCreate from '../pages/dashboard/WarrantyCreate';
import Services from '../pages/dashboard/Services';
import BlogPosts from '../pages/dashboard/BlogPosts';
import BlogPost from '../pages/dashboard/BlogPost';
import BlogAction from '../pages/dashboard/admin/BlogAction';
import NewsAction from '../pages/dashboard/admin/NewsAction';
import ComingSoonPostAction from '../pages/dashboard/admin/ComingSoonPostAction';
import ItemAdmin from '../pages/dashboard/admin/ItemAdmin';
import UserProfile from '../pages/dashboard/UserProfile';
import UserCards from '../pages/dashboard/UserCards';
import UserList from '../pages/dashboard/UserList';
import UserAccount from '../pages/dashboard/UserAccount';
import UserCreate from '../pages/dashboard/UserCreate';
import RoleAction from '../pages/dashboard/admin/RoleAction';
import MasterApplianceTypeAction from '../pages/dashboard/admin/MasterApplianceTypeAction';
import MasterIndustryTypeAction from '../pages/dashboard/admin/MasterIndustryTypeAction';
import MasterExteriorTypeAction from '../pages/dashboard/admin/MasterExteriorTypeAction';
import MasterLawnTypeAction from '../pages/dashboard/admin/MasterLawnTypeAction';
import MasterSendEmailNotificationAction from '../pages/dashboard/admin/MasterSendEmailNotificationAction';
import MasterFurnitureTypeAction from '../pages/dashboard/admin/MasterFurnitureTypeAction';
import MasterInteriorTypeAction from '../pages/dashboard/admin/MasterInteriorTypeAction';
import MasterUtilitiesTypeAction from '../pages/dashboard/admin/MasterUtilitiesTypeAction';
import MasterBrandAction from '../pages/dashboard/admin/MasterBrandAction';
import HomeHacksAction from '../pages/dashboard/admin/HomeHacksAction';
import MasterCompanyTypeAction from '../pages/dashboard/service-pro/MasterCompanyTypeAction';
import CompanyAction from '../pages/dashboard/service-pro/CompanyAction';
import AdminCompanyAction from '../pages/dashboard/service-pro/AdminCompanyAction';
import UserAction from '../pages/dashboard/service-pro/UserAction';
import AddressSearch from '../pages/dashboard/service-pro/AddressSearch';
import LandingPage from '../pages/LandingPage';
import About from '../pages/About';
import Faqs from '../pages/Faqs';
import ComingSoon from '../pages/ComingSoon';
import Maintenance from '../pages/Maintenance';
import Page500 from '../pages/Page500';
import NotFound from '../pages/Page404';
import EditItem from '../pages/dashboard/admin/EditItem';
import DashboardAdminItemsItemIdTasksNew from '../pages/DashboardAdminItemsItemIdTasksNew';
import DashboardHome from '../pages/dashboard.home';
import DashboardHomeItems from '../pages/dashboard.home-items';
import DashboardHomeItemsNew from '../pages/dashboard.home-items.new';
import DashboardHomeItemsId from '../pages/dashboard.home-items.$id';

const onlyAdminAccess = ['Admin'];

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'home',
          element: (
            <GuestGuard>
              <About />
            </GuestGuard>
          )
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: 'confirm/:token',
      element: <ConfirmLayout />
    },
    {
      path: 'getToNewUser/:token',
      element: <NewUserLayout />
    },

    // Blog Routes
    {
      path: 'blog',
      children: [
        { path: '/blog', element: <Navigate to="/blog/posts" replace /> },
        { path: 'posts', element: <BlogPosts /> },
        { path: 'posts/:title', element: <BlogPost /> }
      ]
    },

    // News Routes
    {
      path: 'news',
      children: [
        { path: '/news', element: <Navigate to="/news/posts" replace /> },
        { path: 'posts', element: <BlogPosts /> },
        { path: 'posts/:title', element: <BlogPost /> }
      ]
    },

    // Coming Soon Post Routes
    {
      path: 'coming-soon',
      children: [
        { path: '/coming-soon', element: <Navigate to="/coming-soon/posts" replace /> },
        { path: 'posts', element: <BlogPosts /> },
        { path: 'posts/:title', element: <BlogPost /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/dashboard', element: <Navigate to="dashboard/home" replace /> },
        {
          path: 'address',
          element: (
            <RoleBasedGuard accessibleRoles={homeowner()}>
              <Address />
            </RoleBasedGuard>
          )
        },
        {
          path: 'home-items/new',
          element: <DashboardHomeItemsNew />
        },
        {
          path: 'home-items/:id',
          element: <DashboardHomeItemsId />
        },
        {
          path: 'residence',
          element: (
            <RoleBasedGuard accessibleRoles={homeowner()}>
              <Residance />
            </RoleBasedGuard>
          )
        },
        {
          path: 'walkthrough',
          element: <Walkthrough />
        },
        {
          path: 'home-items',
          children: [
            {
              path: '/dashboard/home-items',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <DashboardHomeItems />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <AppliancesCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <AppliancesCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'contacts',
          children: [
            {
              path: '/dashboard/contacts',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <Contacts />{' '}
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <ContactCreate />{' '}
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <ContactCreate />{' '}
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'warranty',
          children: [
            {
              path: '/dashboard/warranty',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <Warranty />{' '}
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <WarrantyCreate />{' '}
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <WarrantyCreate />{' '}
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'warranties',
          element: (
            <RoleBasedGuard accessibleRoles={homeowner()}>
              <Warranties />{' '}
            </RoleBasedGuard>
          )
        },
        {
          path: 'services',
          element: (
            <RoleBasedGuard accessibleRoles={homeowner()}>
              <Services />{' '}
            </RoleBasedGuard>
          )
        },
        {
          path: 'home',
          element: (
            <RoleBasedGuard accessibleRoles={homeowner()}>
              <DashboardHome />
            </RoleBasedGuard>
          )
        },
        {
          path: 'tasks',
          children: [
            {
              path: '/dashboard/tasks',
              element: (
                <RoleBasedGuard accessibleRoles={homeowner()}>
                  <Tasks />{' '}
                </RoleBasedGuard>
              )
            }
            // { path: 'create', element: (
            // <RoleBasedGuard accessibleRoles={homeowner()}><UtilitiesCreate /> </RoleBasedGuard>)},
            // { path: 'edit', element: (
            // <RoleBasedGuard accessibleRoles={homeowner()}><UtilitiesCreate /> </RoleBasedGuard>)}
          ]
        },
        {
          path: 'admin',
          children: [
            { path: '/dashboard/admin', element: <Navigate to="/dashboard/admin/role" replace /> },
            {
              path: 'role',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <RoleAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'role/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <RoleAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'brands',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterBrandAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'brands/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterBrandAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'brands/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterBrandAction />{' '}
                </RoleBasedGuard>
              )
            },
            {
              path: 'appliance-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterApplianceTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'appliance-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterApplianceTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'appliance-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterApplianceTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'industry-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterIndustryTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'industry-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterIndustryTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'industry-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterIndustryTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'items',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <ItemAdmin />
                </RoleBasedGuard>
              )
            },
            {
              path: 'items/new',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <EditItem />
                </RoleBasedGuard>
              )
            },
            {
              path: 'items/:id',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <EditItem />
                </RoleBasedGuard>
              )
            },
            {
              path: 'items/:itemId/tasks/new',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <DashboardAdminItemsItemIdTasksNew />
                </RoleBasedGuard>
              )
            },
            {
              path: 'exterior-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterExteriorTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'exterior-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterExteriorTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'exterior-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterExteriorTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'utilities-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterUtilitiesTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'utilities-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterUtilitiesTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'utilities-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterUtilitiesTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'home-hacks',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <HomeHacksAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'home-hacks/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <HomeHacksAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'home-hacks/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <HomeHacksAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'lawnLeisure-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterLawnTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'lawnLeisure-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterLawnTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'lawnLeisure-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterLawnTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'furniture-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterFurnitureTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'furniture-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterFurnitureTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'furniture-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterFurnitureTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'interior-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterInteriorTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'interior-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterInteriorTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'interior-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterInteriorTypeAction />
                </RoleBasedGuard>
              )
            },
            // { path: 'warranty-type', element: (
            //   <RoleBasedGuard accessibleRoles={onlyAdminAccess}><MasterWarrantyTypeAction /></RoleBasedGuard>
            // ) },
            // { path: 'warranty-type/create', element: (
            //   <RoleBasedGuard accessibleRoles={onlyAdminAccess}><MasterWarrantyTypeAction /></RoleBasedGuard>
            // ) },
            // { path: 'warranty-type/edit', element: (
            //   <RoleBasedGuard accessibleRoles={onlyAdminAccess}><MasterWarrantyTypeAction /></RoleBasedGuard>
            // ) },
            {
              path: 'email-notification',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterSendEmailNotificationAction />
                </RoleBasedGuard>
              )
            },
            { path: 'role', element: <RoleAction /> },
            { path: 'role/edit', element: <RoleAction /> },
            { path: 'brands', element: <MasterBrandAction /> },
            { path: 'brands/create', element: <MasterBrandAction /> },
            { path: 'brands/edit', element: <MasterBrandAction /> },
            { path: 'appliance-type', element: <MasterApplianceTypeAction /> },
            { path: 'appliance-type/create', element: <MasterApplianceTypeAction /> },
            { path: 'appliance-type/edit', element: <MasterApplianceTypeAction /> },
            { path: 'industry-type', element: <MasterIndustryTypeAction /> },
            { path: 'industry-type/create', element: <MasterIndustryTypeAction /> },
            { path: 'industry-type/edit', element: <MasterIndustryTypeAction /> },
            { path: 'exterior-type', element: <MasterExteriorTypeAction /> },
            { path: 'exterior-type/create', element: <MasterExteriorTypeAction /> },
            { path: 'exterior-type/edit', element: <MasterExteriorTypeAction /> },
            { path: 'utilities-type', element: <MasterUtilitiesTypeAction /> },
            { path: 'utilities-type/create', element: <MasterUtilitiesTypeAction /> },
            { path: 'utilities-type/edit', element: <MasterUtilitiesTypeAction /> },
            { path: 'home-hacks', element: <HomeHacksAction /> },
            { path: 'home-hacks/create', element: <HomeHacksAction /> },
            { path: 'home-hacks/edit', element: <HomeHacksAction /> },
            { path: 'lawnLeisure-type', element: <MasterLawnTypeAction /> },
            { path: 'lawnLeisure-type/create', element: <MasterLawnTypeAction /> },
            { path: 'lawnLeisure-type/edit', element: <MasterLawnTypeAction /> },
            { path: 'furniture-type', element: <MasterFurnitureTypeAction /> },
            { path: 'furniture-type/create', element: <MasterFurnitureTypeAction /> },
            { path: 'furniture-type/edit', element: <MasterFurnitureTypeAction /> },
            { path: 'interior-type', element: <MasterInteriorTypeAction /> },
            { path: 'interior-type/create', element: <MasterInteriorTypeAction /> },
            { path: 'interior-type/edit', element: <MasterInteriorTypeAction /> },
            // { path: 'warranty-type', element: <MasterWarrantyTypeAction /> },
            // { path: 'warranty-type/create', element: <MasterWarrantyTypeAction /> },
            // { path: 'warranty-type/edit', element: <MasterWarrantyTypeAction /> },
            { path: 'email-notification', element: <MasterSendEmailNotificationAction /> },
            { path: 'blog', element: <BlogAction /> },
            { path: 'blog/create', element: <BlogAction /> },
            { path: 'blog/edit', element: <BlogAction /> },
            { path: 'news', element: <NewsAction /> },
            { path: 'news/create', element: <NewsAction /> },
            { path: 'news/edit', element: <NewsAction /> },
            { path: 'coming-soon', element: <ComingSoonPostAction /> },
            { path: 'coming-soon/create', element: <ComingSoonPostAction /> },
            { path: 'coming-soon/edit', element: <ComingSoonPostAction /> }
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/dashboard/user', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: '/dashboard/user/:name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        }
      ]
    },
    {
      path: 'service-pro',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/service-pro', element: <Navigate to="dashboard" replace /> },
        {
          path: 'dashboard',
          element: (
            <RoleBasedGuard accessibleRoles={serviceProAccess()}>
              <DashboardHome />
            </RoleBasedGuard>
          )
        },
        {
          path: 'address-search',
          element: (
            <RoleBasedGuard accessibleRoles={serviceProAccess()}>
              <AddressSearch />
            </RoleBasedGuard>
          )
        },
        {
          path: 'pro',
          children: [
            { path: '/service-pro/pro', element: <Navigate to="/company" replace /> },
            {
              path: 'company',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <CompanyAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'company/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <CompanyAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'company/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <CompanyAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'users',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <UserAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'users/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <UserAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'users/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <UserAction />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'admin',
          children: [
            { path: '/service-pro/admin', element: <Navigate to="/company-type" replace /> },
            {
              path: 'company-type',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterCompanyTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'company-type/create',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterCompanyTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'company-type/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <MasterCompanyTypeAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'company',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <AdminCompanyAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'company/add',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <AdminCompanyAction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'company/edit',
              element: (
                <RoleBasedGuard accessibleRoles={onlyAdminAccess}>
                  <AdminCompanyAction />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'home', element: <About /> },
        { path: 'about-us', element: <About /> },
        { path: 'faqs', element: <Faqs /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
