import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  InputAdornment,
  // Switch,
  TextField,
  // FormControlLabel,
  Typography,
  FormHelperText
} from '@mui/material';
// import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import { UploadAvatar } from '../../../upload';
// utils
import { fData } from '../../../../utils/formatNumber';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { modifyUserAccount } from '../../../../redux/slices/user';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { success, error, isLoading } = useSelector((state) => state.user);
  const [uploadedImg, setUploadedImg] = useState(user.photoURL || null);
  const [imgTypeError, setImgTypeError] = useState(false);

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Full Name is required'),
    // phoneNumber: Yup.string().required('Phone Number is required')
    phoneNumber: Yup.string().length(10).matches(new RegExp('[0-9]{7}'), 'Phone number is not valid')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user.email,
      displayName: user.displayName !== user.email ? user.displayName : '',
      // phoneNumber: user?.phoneNumber, // ? user.phoneNumber.slice(2) : '', // && user.phoneNumber === '' ? '' : user.phoneNumber.slice(2),
      phoneNumber: user?.phoneNumber ? user.phoneNumber.slice(2) : '',
      isPublic: user.isPublic || false
      // photoURL: user.photoURL
      // email: user.email,
      // country: user.country,
      // address: user.address,
      // state: user.state,
      // city: user.city,
      // zipCode: user.zipCode,
      // about: user.about,
      //
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        values.UID = user.id;
        values.oldPhotoURL = user.photoURL;
        values.phoneNumber = `${values.phoneNumber.replaceAll('-', '')}`;
        dispatch(modifyUserAccount(values));
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file?.type && ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'].includes(file.type)) {
        const base64 = await convertBase64(file);
        setFieldValue('photoURL', { originalName: file.name, fileBase64: base64 });
        setUploadedImg(URL.createObjectURL(file));
        setImgTypeError(false);
      } else {
        setImgTypeError(true);
      }
      // if (file) {
      //   setFieldValue('photoURL', {
      //     ...file,
      //     preview: URL.createObjectURL(file)
      //   });
      // }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (success) enqueueSnackbar(success, { variant: 'success' });
  }, [success, enqueueSnackbar]);

  // useEffect(() => {
  //   if (getFieldProps('phoneNumber')) {
  //     const phoneNumber = user?.phoneNumber ? user.phoneNumber.slice(2) : '';
  //     // @ts-ignore
  //     phoneNumber.slice(2);
  //     // getFieldProps('phoneNumber') = phoneNumber;
  //     setFieldValue('phoneNumber', phoneNumber);
  //   }
  // }, [getFieldProps, user.phoneNumber, setFieldValue]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <UploadAvatar
                accept="image/*"
                file={uploadedImg}
                maxSize={16777216}
                onDrop={handleDrop}
                error={Boolean(touched.photoURL && errors.photoURL)}
                caption={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
              {imgTypeError && (
                <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                  {touched.photoURL && errors.photoURL}
                </FormHelperText>
              )}

              {/* <FormControlLabel
                control={<Switch checked={values.isPublic} {...getFieldProps('isPublic')} color="primary" />}
                labelPlacement="start"
                label="Public Profile"
                sx={{ mt: 5 }}
              /> */}
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth disabled label="Email Address" {...getFieldProps('email')} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    {...getFieldProps('displayName')}
                    error={Boolean(touched.displayName && errors.displayName)}
                    helperText={touched.displayName && errors.displayName}
                  />
                  {/* <TextField fullWidth label="Phone Number" {...getFieldProps('phoneNumber')} /> */}
                  <TextField
                    fullWidth
                    autoComplete="phoneNumber"
                    type="phoneNumber"
                    label="Phone Number"
                    {...getFieldProps('phoneNumber')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+1</InputAdornment>
                    }}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Stack>

                {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="Phone Number" {...getFieldProps('phoneNumber')} />
                  <TextField fullWidth label="Address" {...getFieldProps('address')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    select
                    fullWidth
                    label="Country"
                    placeholder="Country"
                    {...getFieldProps('country')}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  >
                    <option value="" />
                    {countries.map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextField fullWidth label="State/Region" {...getFieldProps('state')} />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth label="City" {...getFieldProps('city')} />
                  <TextField fullWidth label="Zip/Code" {...getFieldProps('zipCode')} />
                </Stack>

                <TextField {...getFieldProps('about')} fullWidth multiline minRows={4} maxRows={4} label="About" /> */}
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
