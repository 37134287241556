import { createSlice } from '@reduxjs/toolkit';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isLoadingBtnUpld: false,
  error: false,
  success: '',
  masterFurnitureTypeList: [],
  furnitureList: []
};

const slice = createSlice({
  name: 'furniture',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MASTER FURNITURE TYPE LIST
    getFurnitureTypeListSuccess(state, action) {
      state.isLoading = false;
      state.masterFurnitureTypeList = action.payload;
      state.success = '';
    },

    // CREAT FURNITURE SUCCESS
    createFurnitureSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // GET FURNITURE LIST
    getFurnitureListSuccess(state, action) {
      state.isLoading = false;
      state.furnitureList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  startDeleteLoading,
  getFurnitureTypeListSuccess,
  createFurnitureSuccess,
  getFurnitureListSuccess,
  startLoadingUpld
} = slice.actions;

// -------------------- START FURNITURE TYPE ----------------------------

export function getFurnitureType() {
  return async (dispatch) => {
    try {
      const params = { type: 'furnitureType' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getFurnitureTypeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createFurnitureType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, icon, status } = data;
      const newData = {
        name,
        icon,
        type: 'furnitureType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.furnitureType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyFurnitureType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status, icon } = data;
      const newData = {
        name,
        icon,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.furnitureType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFurnitureType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END FURNITURE TYPE ------------------------------

// -------------------- FURNITURE  --------------------------------------

export function createFurniture(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createService(data);
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Create success'));
        navigate(PATH_DASHBOARD.general.furniture);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyFurniture(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, image } = previousData;
      if (data.image) {
        data.oldImage = image;
      }
      delete data.otherFiles;
      const resp = await webServices.updateService(id, data);
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.furniture);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getFurniture() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type: 'furnitureType' };
      const resp = await webServices.getService({ params });
      if (resp && resp.success === true) {
        dispatch(getFurnitureListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFurniture(navigate, id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        isDeleted: true
      };
      const resp = await webServices.softDeleteService(id, data);
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Delete success'));
        navigate(PATH_DASHBOARD.general.furniture);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id, navigate) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const data = { otherFiles, type: 'furnitureType' };
      const resp = await webServices.fileUploadService(id, data);
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Uploaded success'));
        dispatch(createFurnitureSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.furnitureEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFiles(fileURL, id, navigate) {
  return async (dispatch) => {
    try {
      const resp = await webServices.fileDeleteService(id, { fileURL });
      if (resp && resp.success === true) {
        dispatch(createFurnitureSuccess('Deleted success'));
        dispatch(createFurnitureSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.furnitureEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END FURNITURE  ----------------------------------
