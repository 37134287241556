import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Container, Skeleton, Button, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HomeHacksForm from './HomeHacksForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getHomeHackAll, getListSuccess, dataDelete } from '../../../redux/slices/homeHacks';
import HomeHacksListing from './HomeHacksListing';
// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    <Box sx={{ width: '100%' }}>
      {[...Array(3)].map((_, index) => (
        <Skeleton key={index} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      ))}
      <Skeleton animation="wave" sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
      <Skeleton animation={false} sx={{ paddingTop: '5%', marginLeft: 2, marginRight: 2 }} />
    </Box>
  </>
);

const TABLE_HEAD = [
  { id: 'hack', label: 'Hack', aliggetBrandsnRight: false },
  { id: 'category', label: 'Related Item', aliggetBrandsnRight: false },
  { id: 'relatedItem', label: 'Category', aliggetBrandsnRight: false },
  { id: 'image', label: 'Image', aliggetBrandsnRight: false },
  { id: 'status', label: 'Status', aliggetBrandsnRight: false },
  { id: '' }
];

export default function HomeHacksAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { homeHacksList, success, isLoading } = useSelector((state) => state.homeHacks);
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes('edit');
  const isCreate = pathname.includes('create');

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(success, { variant: 'success' });
    }
    dispatch(getHomeHackAll());
  }, [dispatch, success, enqueueSnackbar]);

  const handleEdit = (row) => {
    navigate(PATH_DASHBOARD.admin.homeHacksEdit, { state: row });
  };
  const listingComponent = (
    <Page title="Master Residence">
      <Container>
        <HeaderBreadcrumbs
          heading="Master Residence"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Master Residence' }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.admin.homeHacksCreate}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New
            </Button>
          }
        />
        {!isLoading ? (
          <HomeHacksListing
            tableHead={TABLE_HEAD}
            dataList={homeHacksList}
            listSuccess={getListSuccess}
            dataDelete={dataDelete}
            handleEdit={handleEdit}
          />
        ) : null}
        {isLoading && SkeletonLoad}
      </Container>
    </Page>
  );

  const formComponent = (
    <Page title={isEdit ? 'Modify' : 'Create'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Master Residence"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Admin', href: PATH_DASHBOARD.admin.role },
            { name: 'Master Residence', href: PATH_DASHBOARD.admin.homeHacks },
            { name: isEdit ? 'Edit' : 'Create' }
          ]}
        />
        <HomeHacksForm currentData={state} isEdit={isEdit} />
      </Container>
    </Page>
  );

  return isEdit || isCreate ? formComponent : listingComponent;
}
