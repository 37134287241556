import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { Timeline } from '@mui/lab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { experimentalStyled as styled } from '@mui/material/styles';
import TaskItemModal from '../../../pages/dashboard/TaskItemModal';

const HoverStyle = styled('div')(() => ({
  '&:hover': { background: 'rgb(200, 250, 205)' }
}));

TasksItem.propTypes = {
  item: PropTypes.object,
  parentCallBack: PropTypes.func
};

function TasksItem({ item, parentCallBack }) {
  const { name, description, icon, levelOfEffort, expirationDate } = item;
  const subContent = `${description} | ${levelOfEffort || ''} | ${expirationDate}`;
  const handleViewDetails = (data) => () => {
    parentCallBack({ modalStatus: true, item: data });
  };

  return (
    <HoverStyle sx={{ width: '100%' }}>
      <List
        style={{
          cursor: 'pointer'
        }}
        onClick={handleViewDetails(item)}
      >
        <ListItem>
          <i className={`fa-thin fa-2x fa-${icon}`} />
          <ListItemText primary={name} secondary={subContent} style={{ marginLeft: 10 }} />
        </ListItem>
      </List>
    </HoverStyle>
  );
}

AnalyticsEditedTimeline.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string
};

export default function AnalyticsEditedTimeline({ list, title }) {
  const [selectedTask, setSelectedTask] = React.useState(null);
  const callbackFunction = (data) => setSelectedTask(data);

  const onModalClose = () => setSelectedTask(null);

  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      <CardHeader title={title} />
      <CardContent style={{ marginTop: '-30px' }}>
        <Timeline>
          {list.length > 0 ? (
            list.map((item) => <TasksItem key={item.id} item={item} parentCallBack={callbackFunction} />)
          ) : (
            <ListItemText primary="No record found" style={{ padding: '5%', textAlign: 'center' }} />
          )}
        </Timeline>
      </CardContent>
      {selectedTask && <TaskItemModal open parentCallBack={onModalClose} currentData={selectedTask.item} />}
    </Card>
  );
}
