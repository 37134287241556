import React from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { Container, TextField, Select, MenuItem } from '@mui/material';
import { fetchData } from '../../../services';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { createItem, editItem } from '../../../api';

const categories = ['Appliance', 'Exterior', 'Utility', 'Lawn & Leisure', 'Furniture', 'Interior'];

const ItemSchema = Yup.object().shape({
  category: Yup.string().oneOf(categories),
  name: Yup.string().required('Name is required'),
  icon: Yup.string().required('Icon is required')
});

export default function EditItem() {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [item, setItem] = React.useState({ category: 'Appliance', name: '', icon: '' });
  const isEdit = id && item;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: item,
    validationSchema: ItemSchema,
    onSubmit: async (values) => {
      try {
        if (isEdit) await editItem(id, values);
        else await createItem(values);
        enqueueSnackbar(`${formik.values.name} saved`, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  });

  const itemHeading = isEdit ? formik.values.name : 'New Item';

  React.useEffect(() => {
    if (!id) return;
    fetchData(`/item/${id}`).then(setItem);
  }, [id, setItem]);

  return (
    <Page title="Item">
      <Container>
        <HeaderBreadcrumbs
          heading={itemHeading}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.home },
            { name: 'Items', href: PATH_DASHBOARD.admin.items },
            { name: itemHeading, href: PATH_DASHBOARD.admin.items }
          ]}
        />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-4">
              <Select name="category" {...formik.getFieldProps('category')} fullWidth>
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
              <TextField {...formik.getFieldProps('name')} label="Name" fullWidth />
              <TextField {...formik.getFieldProps('icon')} label="Icon" fullWidth />
              <div className="flex justify-end">
                <button className="button-primary" type="submit">
                  Save
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>

        <Link to={`/dashboard/admin/items/${id}/tasks/new`}>Add Task</Link>
      </Container>
    </Page>
  );
}
