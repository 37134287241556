import * as React from 'react';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, ToggleButtonGroup } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';

// routes
import { PATH_DASHBOARD, PATH_SERVICE_PRO } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { isAccessModule } from '../../utils/role';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';

const ToggleButton = styled(MuiToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#00AB55'
  }
});

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  // {
  //   label: 'Profile',
  //   icon: personFill,
  //   linkTo: PATH_DASHBOARD.user.profile
  // },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.user.account
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const isWalkThrough = pathname.includes('walkthrough');
  const [alignment, setAlignment] = useState(
    user.isServicePro ? PATH_SERVICE_PRO.dashboard : PATH_DASHBOARD.general.app
  );
  const [lastAlignment, setLastAlignment] = useState(
    user.isServicePro ? PATH_SERVICE_PRO.dashboard : PATH_DASHBOARD.general.app
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let win;
  if (typeof window !== 'undefined' && localStorage.getItem('displayName')) {
    win = localStorage.getItem('displayName');
  } else {
    win = user.displayName;
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      localStorage.setItem('user', '');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
      setLastAlignment(newAlignment);
      navigate(`/${newAlignment}`);
    } else {
      setAlignment(lastAlignment);
    }
  };

  return (
    <>
      {isAccessModule('flip') && (
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value={PATH_DASHBOARD.general.app}>Homeowner</ToggleButton>
          <ToggleButton value={PATH_SERVICE_PRO.dashboard}>Service Pro</ToggleButton>
        </ToggleButtonGroup>
      )}

      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {win}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {!isWalkThrough &&
          MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />

              {option.label}
            </MenuItem>
          ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
