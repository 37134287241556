import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, TextField, Typography, FormControlLabel } from '@mui/material';
import NumberFormat from 'react-number-format';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSelector, useDispatch } from '../../../redux/store';
import { createAddress, getStateList } from '../../../redux/slices/residence';

AddressNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentAddress: PropTypes.object,
  isLoading: PropTypes.bool,
  success: PropTypes.string,
  isReadOnly: PropTypes.bool
};

export default function AddressNewForm({ isEdit, currentAddress, isLoading, success, isReadOnly }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { masterStateList } = useSelector((state) => state.residence);

  useEffect(() => {
    dispatch(getStateList());
  }, [dispatch]);

  const NewAddressSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.number().required('Zip is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: currentAddress?.address || '',
      state: currentAddress?.state || '',
      city: currentAddress?.city || '',
      zipCode: currentAddress?.zipCode || '',
      isVerified: currentAddress?.isVerified || false,
      id: currentAddress?.id || ''
      // status: currentAddress?.status || ''
    },
    validationSchema: NewAddressSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        dispatch(createAddress(values));
        navigate(PATH_DASHBOARD);
      } catch (error) {
        // console.error(error);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const APIkey = process.env.REACT_APP_MAP_GOOGLE;
  const location = encodeURIComponent(
    `${currentAddress?.address}  ${currentAddress?.city} ${currentAddress?.state} ${currentAddress?.zipCode}`
  );
  const srcString = `https://www.google.com/maps/embed/v1/place?key=${APIkey}&q=${location}&zoom=18&maptype=satellite`;

  useEffect(() => {
    if (success) {
      enqueueSnackbar(success, { variant: 'success' });
    }
  }, [success, enqueueSnackbar]);
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Address"
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    disabled={isReadOnly}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                    disabled={isReadOnly}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <Autocomplete
                    fullWidth
                    value={{ name: values.state }}
                    onChange={(e, value) => setFieldValue('state', value?.name || '')}
                    options={masterStateList}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    disabled={isReadOnly}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        variant="outlined"
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                        disabled={isReadOnly}
                      />
                    )}
                  />
                  <NumberFormat
                    fullWidth
                    {...getFieldProps('zipCode')}
                    thousandsGroupStyle="thousand"
                    decimalSeparator=""
                    label="Zip/Code"
                    variant="outlined"
                    customInput={TextField}
                    type="text"
                    thousandSeparator={false}
                    allowNegative={false}
                    decimalScale={5}
                    error={Boolean(touched.zipCode && errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                    value={values.zipCode}
                    onChange={formik.handleChange}
                    disabled={isReadOnly}
                  />
                </Stack>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  {!isReadOnly && (
                    <LoadingButton className="button-primary" type="submit" variant="contained" loading={isLoading}>
                      {!isEdit ? 'Create Address' : 'Save Changes'}
                    </LoadingButton>
                  )}
                </Box>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3 }}>
              <Box sx={{ mb: 5 }}>
                <div>
                  <iframe title="maps" frameBorder="0" src={srcString} />
                </div>
              </Box>

              <FormControlLabel
                labelPlacement="start"
                control={<Switch {...getFieldProps('isVerified')} checked={values.isVerified} />}
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Address Verified
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Disabling this will automatically send the user a verification email
                    </Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
