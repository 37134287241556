import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { firebase } from '../../../../contexts/firebase-config';
import { useSelector, useDispatch } from '../../../../redux/store';
import { createAddress, getStateList, getAddress } from '../../../../redux/slices/residence';
import { searchAddressSuccess, searchProperty, getZipCode } from '../../../../redux/slices/user';

StepForm1.propTypes = {
  handleNext: PropTypes.func
};

export default function StepForm1({ handleNext }) {
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.residence);
  const { getZipCodeData } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getAddress());
    // dispatch(getZipCode());
    return () => {
      dispatch(searchAddressSuccess({}));
      // dispatch(searchAddressResiviewSuccess({}));
    };
  }, [dispatch]);

  const formSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zip_code: Yup.number().required('Zip is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: address?.address || '',
      state: address?.state || '',
      city: address?.city || '',
      zip_code: address?.zipCode || '',
      isVerified: address?.isVerified || false,
      id: address?.id || '',
      abbreviation: ''
      // status: address?.status || ''
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        searchProperty(
          {
            address: values.address,
            state: values.state,
            abbreviation: values.abbreviation,
            city: values.city,
            zipcode: values.zip_code
          },
          dispatch
        );
        values.zipCode = values.zip_code;
        delete values.zip_code;
        const valuesAddress = values;
        // delete valuesAddress.abbreviation;
        const getDataForCreate = await dispatch(createAddress(valuesAddress));
        if (getDataForCreate) {
          handleNext('step2');
        } else {
          setOpen(true);
        }
      } catch (error) {
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfrim = async () => {
    await firebase.auth().signOut();
    localStorage.clear();
    window.location.href = '/';
  };
  const handleChangeZipCode = () => {
    if (values.zip_code.length === 5) {
      dispatch(
        getZipCode({
          zip_code: values.zip_code
        })
      );
    }
  };
  useEffect(() => {
    const data = getZipCodeData.length > 0 ? getZipCodeData[0] : [];
    setFieldValue('zipCode', getZipCodeData.length > 0 ? data?.zip_code.toString() : '');
    setFieldValue('zip_code', getZipCodeData.length > 0 ? data?.zip_code.toString() : '');
    setFieldValue('abbreviation', getZipCodeData.length > 0 ? data?.state_code : '');
    setFieldValue('state', getZipCodeData.length > 0 ? data?.state_name : '');
    setFieldValue('city', getZipCodeData.length > 0 ? data?.city : '');
  }, [getZipCodeData, setFieldValue]);

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
            Welcome!
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            Lets get you started by adding the location of your residence.
          </Typography>
        </Box>
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                label="Address"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                label="City"
                {...getFieldProps('city')}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
                disabled="disabled"
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              {/* <Autocomplete
                fullWidth
                value={{ name: values.state }}
                // onChange={(e, value) => setFieldValue('state', value?.name || '')}
                options={masterStateList}
                onChange={handleChangeState}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => ( */}
              <TextField
                // {...params}
                {...getFieldProps('state')}
                label="State"
                variant="outlined"
                disabled="disabled"
                error={Boolean(touched.state && errors.state)}
                helperText={touched.state && errors.state}
              />
              {/* )}
              /> */}
              {/* <NumberFormat
                fullWidth
                {...getFieldProps('zipCode')}
                thousandsGroupStyle="thousand"
                decimalSeparator=""
                label="Zip/Code"
                variant="outlined"
                customInput={TextField}
                type="text"
                thousandSeparator={false}
                allowNegative={false}
                decimalScale={5}
                error={Boolean(touched.zipCode && errors.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
                value={values.zipCode}
                onChange={formik.handleChange}
              /> */}
              {/* <Autocomplete
                fullWidth
                value={{ zip_code: values.zip_code }}
                // onChange={(e, value) => setFieldValue('state', value?.name || '')}
                options={getZipCodeData}
                onChange={handleChangeZipCode}
                getOptionLabel={(option) => `${option.zip_code}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Zip/Code"
                    variant="outlined"
                    error={Boolean(touched.zip_code && errors.zip_code)}
                    helperText={touched.zip_code && errors.zip_code}
                  />
                )}
              /> */}
              <TextField
                fullWidth
                label="Zip/Code"
                {...getFieldProps('zip_code')}
                onKeyUp={handleChangeZipCode}
                error={Boolean(touched.zip_code && errors.zip_code)}
                helperText={touched.zip_code && errors.zip_code}
              />
            </Stack>
          </Stack>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <LoadingButton
              className="button-primary"
              size="large"
              type="submit"
              variant="contained"
              sx={{ width: 120, marginRight: '15px', marginLeft: '15px' }}
              onClick={handleSubmit}
            >
              Next
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>
                As part of the address verification process, ResiView needs to do additional checks to confirm the
                details of this address. This process can take up to 2 weeks, but often under 48 hours. We will notify
                you as soon as possible to continue setting up your account.
              </strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="button-primary" onClick={handleConfrim} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
