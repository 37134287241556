import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
//
import { varFadeIn, MotionInView } from '../../animate';

// ----------------------------------------------------------------------

const ACCORDIONS = [
  {
    value: `panel1`,
    heading: `What is the cost?`,
    detail: `ResiView plans to always offer a fully free version for all single residences with the ability to track on unlimited items and reminders.`
  },
  {
    value: `panel2`,
    heading: `What can I track and get reminders for?`,
    detail: `Current categories of items include Utilities, Appliances, Exterior, Interior, Furniture, and Lawn & Leisure.  There are a wide variety of items in each of those categories.  
    ResiView will also allow users to add their own custom categories and items.`
  },
  {
    value: `panel3`,
    heading: `How does ResiView handle my privacy and security?`,
    detail: `We take security seriously and follow all the latest security best practices for data access and encryption. You have direct control over the data saved to your account.`
  },
  {
    value: `panel4`,
    heading: `Can I track multiple addresses?`,
    detail: `Current functionality allows for a user to track one home per login.  Upcoming functionality will allow for tracking on multiple addresses.`
  },
  {
    value: `panel5`,
    heading: `What are we working on next?`,
    detail: `Upcoming features include tracking multiple addresses, change in residence, ordering items that help a homeowner complete tasks, and helpful calculators and calendars to assist with planning and tracking.`
  }
];

// ----------------------------------------------------------------------

export default function FaqsList() {
  return (
    <MotionInView variants={varFadeIn}>
      {ACCORDIONS.map((accordion) => (
        <Accordion key={accordion.value}>
          <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}>
            <Typography variant="subtitle1">{accordion.heading}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{accordion.detail}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </MotionInView>
  );
}
