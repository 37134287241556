import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Stack, Box, Button, Typography } from '@mui/material';
import { finishWalkthrough } from '../../../../api';

StepForm6.propTypes = {
  handlePrevious: PropTypes.func
};

export default function StepForm6({ handlePrevious }) {
  const navigate = useNavigate();

  const handleFinish = async () => {
    await finishWalkthrough();
    navigate('/dashboard/home');
  };

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
            Good Job!
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            Now go explore the dashboard, view your items in the left hand menu, and continue to add more items to
            track. You're well on your way to gaining a great view of your residence!
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button
          size="large"
          type="button"
          variant="outlined"
          sx={{ width: 120, marginRight: '15px', marginLeft: '15px' }}
          onClick={() => handlePrevious('step5')}
        >
          Previous
        </Button>
        <LoadingButton
          className="button-primary"
          size="large"
          type="button"
          variant="contained"
          sx={{ width: 120, marginRight: '15px', marginLeft: '15px' }}
          onClick={handleFinish}
        >
          Finish
        </LoadingButton>
      </Box>
    </>
  );
}
