import React from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Card, Grid, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SvgIconStyle from '../../../SvgIconStyle';

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    backgroundColor: '#d3d3d3'
  }
}));
const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  position: 'absolute'
});

const CoverBlankImgStyle = styled('div')({
  top: '18px',
  zIndex: 8,
  width: '115px',
  height: '115px',
  position: 'absolute'
});

const useStyles = makeStyles(() => ({
  iconSize: {
    width: 36,
    objectFit: 'contain',
    zIndex: 11,
    position: 'absolute',
    borderRadius: '0',
    transform: 'translateY(-43%)'
  }
}));

HomeItemCard.propTypes = {
  item: PropTypes.object.isRequired
};

export default function HomeItemCard({ item, ...other }) {
  const classes = useStyles();
  const { description, brand, category, purchaseDate, lastMaintenanceDate, image, icon } = item;
  return (
    <Card {...other} style={{ border: '1px solid #ccc' }}>
      <CardMediaStyle>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        />

        <i className={`${classes.iconSize} fa-thin fa-2x fa-${icon}`} />

        {image ? <CoverImgStyle alt="cover" src={image} /> : <CoverBlankImgStyle />}
      </CardMediaStyle>

      <Typography variant="subtitle1" align="center" sx={{ mt: 5, fontSize: 20 }}>
        {description}
      </Typography>
      <Typography variant="body2" align="center" sx={{ color: 'text.secondary', fontSize: 17 }}>
        {brand}
      </Typography>
      <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mb: 1, fontSize: 17 }}>
        {category}
      </Typography>

      <Divider />

      <Grid container sx={{ py: 3, textAlign: 'center' }}>
        <Grid item xs={4}>
          <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary', display: 'block', fontSize: 14 }}>
            Purchase Date
          </Typography>
          <Typography variant="subtitle1">{purchaseDate}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary', display: 'block', fontSize: 14 }}>
            Last Maint. Date
          </Typography>
          <Typography variant="subtitle1">{lastMaintenanceDate}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
