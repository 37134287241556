import { createSlice } from '@reduxjs/toolkit';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  isLoadingBtnUpld: false,
  error: false,
  success: '',
  masterInteriorTypeList: [],
  interiorList: []
};

const slice = createSlice({
  name: 'interior',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    startLoadingUpld(state) {
      state.isLoadingBtnUpld = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MASTER INTERIOR TYPE LIST
    getInteriorTypeListSuccess(state, action) {
      state.isLoading = false;
      state.masterInteriorTypeList = action.payload;
      state.success = '';
    },

    // CREAT INTERIOR SUCCESS
    createInteriorSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
      state.isLoadingBtnUpld = false;
    },

    // GET INTERIOR LIST
    getInteriorListSuccess(state, action) {
      state.isLoading = false;
      state.interiorList = action.payload;
      state.success = '';
      state.startDeleteLoading = false;
      state.isDeleteLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  startDeleteLoading,
  getInteriorTypeListSuccess,
  createInteriorSuccess,
  getInteriorListSuccess,
  startLoadingUpld
} = slice.actions;

// -------------------- START INTERIOS TYPE ----------------------------

export function getInteriorType() {
  return async (dispatch) => {
    try {
      const params = { type: 'interiorType' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getInteriorTypeListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createInteriorType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, icon, status } = data;
      const newData = {
        name,
        icon,
        type: 'interiorType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.interiorType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyInteriorType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status, icon } = data;
      const newData = {
        name,
        icon,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.interiorType);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteInteriorType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END INTERIOS TYPE -------------------------------

// -------------------- INTERIOS  ---------------------------------------

export function createInterior(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createService(data);
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Create success'));
        navigate(PATH_DASHBOARD.general.interior);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyInterior(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, image } = previousData;
      if (data.image) {
        data.oldImage = image;
      }
      delete data.otherFiles;
      const resp = await webServices.updateService(id, data);
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Modify success'));
        navigate(PATH_DASHBOARD.general.interior);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getInterior() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type: 'interiorType' };
      const resp = await webServices.getService({ params });
      if (resp && resp.success === true) {
        dispatch(getInteriorListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteInterior(navigate, id) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const data = {
        isDeleted: true
      };
      const resp = await webServices.softDeleteService(id, data);
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Delete success'));
        navigate(PATH_DASHBOARD.general.interior);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function uploadFiles(otherFiles, id, navigate) {
  return async (dispatch) => {
    dispatch(startLoadingUpld());
    try {
      const data = { otherFiles, type: 'interiorType' };
      const resp = await webServices.fileUploadService(id, data);
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Uploaded success'));
        dispatch(createInteriorSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.interiorEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteFiles(fileURL, id, navigate) {
  return async (dispatch) => {
    try {
      const resp = await webServices.fileDeleteService(id, { fileURL });
      if (resp && resp.success === true) {
        dispatch(createInteriorSuccess('Deleted success'));
        dispatch(createInteriorSuccess(''));
        const result = resp.data;
        result.id = result._id;
        navigate(PATH_DASHBOARD.general.interiorEdit, { state: result });
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END INTERIOS  -----------------------------------
