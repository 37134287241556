import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_SERVICE_PRO } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated) {
    let redirect;
    if (pathname === '/auth/register') {
      redirect = <Navigate to={PATH_DASHBOARD.general.walkthrough} />;
    } else if (['Admin', 'Homeowner'].includes(user.role)) {
      redirect = (
        <Navigate to={user.completed_walkthrough ? PATH_DASHBOARD.general.app : PATH_DASHBOARD.general.walkthrough} />
      );
    } else {
      redirect = <Navigate to={PATH_SERVICE_PRO.dashboard} />;
    }
    return redirect;
  }

  return <>{children}</>;
}
