import { createSlice } from '@reduxjs/toolkit';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  error: false,
  success: '',
  homeHacksList: [],
  relatedItemList: []
};

const slice = createSlice({
  name: 'Master Residence',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START DELETE LOADING
    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREAT SUCCESS
    createSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET LIST
    getListSuccess(state, action) {
      state.isLoading = false;
      state.homeHacksList = action.payload;
      state.success = '';
      state.isDeleteLoading = false;
    },

    // GET RELATED ITEM LIST
    getRelatedItemListSuccess(state, action) {
      state.isLoading = false;
      state.relatedItemList = action.payload;
      state.success = '';
      state.isDeleteLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { hasError, startLoading, startDeleteLoading, createSuccess, getListSuccess, getRelatedItemListSuccess } =
  slice.actions;

// -------------------- HOME HACK  --------------------------------------

export function getRelatedItem(type) {
  return async (dispatch) => {
    try {
      const params = { type };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getRelatedItemListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createHomeHacks(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createHomeHack(data);
      if (resp && resp.success === true) {
        dispatch(createSuccess('Create success'));
        navigate(PATH_DASHBOARD.admin.homeHacks);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyHomeHacks(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, image } = previousData;
      if (data.image) {
        data.oldImage = image;
      }
      const resp = await webServices.updateHomeHack(id, data);
      if (resp && resp.success === true) {
        dispatch(createSuccess('Modify success'));
        navigate(PATH_DASHBOARD.admin.homeHacks);
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getHomeHacks() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = {};
      const resp = await webServices.getHomeHack({ params });
      if (resp && resp.success === true) {
        dispatch(getListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getHomeHackAll() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.getHomeHackAll();
      if (resp && resp.success === true) {
        dispatch(getListSuccess(resp.data));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function dataDelete(hacks) {
  return async (dispatch) => {
    dispatch(startDeleteLoading());
    try {
      const fileURL = [];
      hacks.forEach((el) => {
        const { id, image } = el;
        fileURL.push({ id, image });
      });
      const resp = await webServices.deleteHomeHack({ fileURL });
      if (resp && resp.success === true) {
        dispatch(createSuccess('Delete success'));
      } else {
        dispatch(hasError(resp.message));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END HOME HACK  -----------------------------------
