import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent } from '@mui/material';
import { Timeline } from '@mui/lab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { experimentalStyled as styled } from '@mui/material/styles';
import { PATH_DASHBOARD } from '../../../routes/paths';

const REDIRECT = {
  exteriorType: PATH_DASHBOARD.general.exteriorEdit,
  applianceType: PATH_DASHBOARD.general.appliancesEdit,
  utilityType: PATH_DASHBOARD.general.utilitiesEdit,
  lawnLeisureType: PATH_DASHBOARD.general.lawnLeisureEdit,
  interiorType: PATH_DASHBOARD.general.interiorEdit,
  furnitureType: PATH_DASHBOARD.general.furnitureEdit
};

const HoverStyle = styled('div')(() => ({
  '&:hover': { background: 'rgb(200, 250, 205)' }
}));

OrderItem.propTypes = {
  item: PropTypes.object
};

function OrderItem({ item }) {
  const navigate = useNavigate();
  const { name, icon, description } = item;
  const handleEdit = (data) => () => {
    navigate(REDIRECT[data.type], { state: data });
  };

  return (
    <HoverStyle>
      <List
        style={{
          cursor: 'pointer'
        }}
        onClick={handleEdit(item)}
      >
        <ListItem>
          <i className={`fa-thin fa-2x fa-${icon}`} />
          <ListItemText primary={description} secondary={name} style={{ marginLeft: 10 }} />
        </ListItem>
      </List>
    </HoverStyle>
  );
}

AnalyticsEditedTimeline.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string
};

export default function AnalyticsEditedTimeline({ list, title }) {
  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      <CardHeader title={title} />
      <CardContent style={{ marginTop: '-30px' }}>
        <Timeline>
          {list.length > 0 ? (
            list.map((item) => <OrderItem key={item.id} item={item} />)
          ) : (
            <ListItemText primary="No record found" style={{ padding: '5%', textAlign: 'center' }} />
          )}
        </Timeline>
      </CardContent>
    </Card>
  );
}
