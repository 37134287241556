import { Link as RouterLink } from 'react-router-dom';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography, Button } from '@mui/material';
import { PATH_PAGE, PATH_AUTH } from '../../routes/paths';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const InnerStyle = styled('div')(() => ({}));

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle
      title="ResiView"
      style={{
        backgroundImage: `url("/static/home/home_bg.jpg")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <MHidden width="mdDown">
        <SectionStyle
          style={{
            boxShadow: 'none',
            textAlign: 'center',
            margin: '150px 0px 150px 15%',
            backgroundColor: 'rgba(255, 255, 255, 0.7'
          }}
        >
          <img src="/static/brand/Logo-04.png" alt="login" style={{ maxWidth: '96%' }} />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} style={{ fontSize: '28px' }}>
            Helping improve the health of homes since Quarantine 2020!
          </Typography>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <InnerStyle
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8',
              borderRadius: 16,
              borderWidth: 1,
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 10,
              paddingBottom: 10
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to ResiView
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Link
                  underline="none"
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_PAGE.home}
                  style={{ top: -15, right: -20, position: 'relative' }}
                >
                  Return Home
                </Link>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                Don't have an account? &nbsp;
                <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Register Now
                </Link>
              </Box>
            </Stack>
            {method === 'firebase' && <AuthFirebaseSocials />}
            {method !== 'auth0' ? (
              <LoginForm />
            ) : (
              <Button
                className="button-primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleLoginAuth0}
              >
                Login
              </Button>
            )}
            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            </MHidden>
          </InnerStyle>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
