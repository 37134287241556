import useAuth from '../hooks/useAuth';

const ROLE_NAME = ['ResiView', 'Admin', 'Employee', 'Homeowner'];
const ADMIN_ACCESS_MODULE = ['admin', 'flip'];
const SERVICE_PRO = ['ResiView', 'Employee'];
const HOMEOWNER = ['Admin', 'Homeowner'];
const SERVICE_PRO_ACCESS_PAGE = ['Admin', 'ResiView', 'Employee'];

export function getRoles() {
  return ROLE_NAME;
}

export function servicePro() {
  return SERVICE_PRO;
}

export function homeowner() {
  return HOMEOWNER;
}

export function serviceProAccess() {
  return SERVICE_PRO_ACCESS_PAGE;
}

export function isAccessModule(title = '') {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useAuth();
  return user.role === 'Admin' || !ADMIN_ACCESS_MODULE.includes(title);
}

export function isAccessModulePage(page) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useAuth();
  return user.role !== 'Admin' && page;
}

export function isAuthProviderLocal(value) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useAuth();
  return value === 'change_password' ? user.authProvider === 'password' : true;
}
