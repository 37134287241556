import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import shareFill from '@iconify/icons-eva/share-fill';
// material
import { alpha, experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card, Grid, Typography, CardContent } from '@mui/material';
// redux
import { useDispatch } from '../../../redux/store';
import { createPostView } from '../../../redux/slices/post';
// routes
import { PATH_ROOTS } from '../../../routes/paths';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgIconStyle from '../../SvgIconStyle';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled('div')({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
});

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  // objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
  postType: PropTypes.string
};

export default function BlogPostCard({ post, index, postType }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { coverImage, title, createdAt, totalViews, totalShared } = post;
  const getType = () => (postType === 'news' ? PATH_ROOTS.news.root : PATH_ROOTS.comingSoon);
  const linkTo = `${postType === 'blog' ? PATH_ROOTS.blog.root : getType()}/posts/${paramCase(title)}`;
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;

  const POST_INFO = [
    // { number: comment, icon: messageCircleFill },
    { number: totalViews, icon: eyeFill },
    { number: totalShared, icon: shareFill }
  ];

  const handleClickRedirect = () => {
    dispatch(createPostView(post.id, 'view'));
    const shareUrl = `${process.env.REACT_APP_HOST_NAME}${linkTo}`;
    navigate(linkTo, { state: { post, shareUrl } });
  };

  return (
    <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute'
              // ...((latestPostLarge || latestPost) && { display: 'none' })
            }}
          />
          {/* <AvatarStyle
            alt={author.name}
            src={author.avatarUrl}
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40
              })
            }}
          /> */}

          <CoverImgStyle alt={title} src={coverImage} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4
            // ...((latestPostLarge || latestPost) && {
            //   bottom: 0,
            //   width: '100%',
            //   position: 'absolute'
            // })
          }}
        >
          <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
            {fDate(createdAt)}
          </Typography>

          <TitleStyle
            // to="#"
            onClick={handleClickRedirect}
            color="inherit"
            variant="subtitle2"
            component={RouterLink}
            sx={
              {
                // ...(latestPostLarge &&
                //   {
                //     typography: 'h5'
                //     height: 60
                //   })
                // ...((latestPostLarge || latestPost) && {
                //   color: 'common.white'
                // })
              }
            }
          >
            {title}
          </TitleStyle>

          <InfoStyle>
            {POST_INFO.map((info, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                  ...((latestPostLarge || latestPost) && {
                    color: 'grey.500'
                  })
                }}
              >
                <Box component={Icon} icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant="caption">{fShortenNumber(info.number)}</Typography>
              </Box>
            ))}
          </InfoStyle>
        </CardContent>
      </Card>
    </Grid>
  );
}
