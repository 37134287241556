import { createSlice } from '@reduxjs/toolkit';
// routes
import { PATH_SERVICE_PRO } from '../../routes/paths';
import webServices from '../../services/webServices';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDeleteLoading: false,
  error: false,
  success: '',
  List: [],
  companyTypeList: []
};

const slice = createSlice({
  name: 'company type',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // SUCCESS MESSAGE
    success(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LIST
    getListSuccess(state, action) {
      state.isLoading = false;
      state.List = action.payload;
      state.success = '';
    },

    // GET LIST COMPANY TYPE
    getListCompanyTypeSuccess(state, action) {
      state.isLoading = false;
      state.companyTypeList = action.payload;
      state.success = '';
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { success, hasError, startLoading, getListSuccess, getListCompanyTypeSuccess } = slice.actions;

// -------------------- START COMPaNY TYPE -------------------------------------

export function getCompanyType() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = { type: 'companyType' };
      const resp = await webServices.getMasterData({ params });
      if (resp && resp.success === true) {
        dispatch(getListCompanyTypeSuccess(resp.data));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createCompanyType(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { name, status } = data;
      const newData = {
        name,
        type: 'companyType',
        status: status === 'Active'
      };
      const resp = await webServices.createMasterData(newData);
      if (resp && resp.success === true) {
        dispatch(success('Create success'));
        navigate(PATH_SERVICE_PRO.admin.CompanyType);
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyCompanyType(data, navigate, previousData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id, type } = previousData;
      const { name, status } = data;
      const newData = {
        name,
        type,
        status: status === 'Active'
      };
      const resp = await webServices.updateMasterData(id, newData);
      if (resp && resp.success === true) {
        dispatch(success('Modify success'));
        navigate(PATH_SERVICE_PRO.admin.CompanyType);
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteCompanyType(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteMasterData({ ids });
      if (resp && resp.success === true) {
        dispatch(success('Delete success'));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END COMPaNY TYPE -------------------------------------

// -------------------- START COMPaNY -------------------------------------

export function getCompany() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const params = {};
      const resp = await webServices.getCompany({ params });
      if (resp && resp.success === true) {
        dispatch(getListSuccess(resp.data));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function modifyCompany(data, navigate, previousData, redirectTo) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const { id } = previousData;
      const resp = await webServices.updateCompany(id, data);
      if (resp && resp.success === true) {
        dispatch(success('Modify success'));
        navigate(redirectTo);
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END COMPaNY -------------------------------------

// -------------------- START ADMIN  COMPaNY -------------------------------------

export function createCompany(data, navigate) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resp = await webServices.createCompany(data);
      if (resp && resp.success === true) {
        dispatch(success('Create success'));
        navigate(PATH_SERVICE_PRO.pro.company);
      } else {
        dispatch(hasError());
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteCompany(ids) {
  return async (dispatch) => {
    try {
      const resp = await webServices.deleteCompany({ ids });
      if (resp && resp.success === true) {
        dispatch(success('Delete success'));
      }
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// -------------------- END ADMIN COMPaNY -------------------------------------
