import RoomIcon from '@mui/icons-material/Room';
import HomeIcon from '@mui/icons-material/Home';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import BuildIcon from '@mui/icons-material/Build';
import HouseIcon from '@mui/icons-material/House';
import LawnLeisureIcon from '@mui/icons-material/Grass';
import WarrantyIcon from '@mui/icons-material//AutoAwesomeMosaic';
import InteriorIcon from '@mui/icons-material/NightShelter';
import FurnitureIcon from '@mui/icons-material/Chair';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Contact from '@mui/icons-material/Contacts';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import GroupIcon from '@mui/icons-material/Group';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// routes
import React from 'react';
import { PATH_DASHBOARD } from '../../routes/paths';
// components

// ----------------------------------------------------------------------

const ICONS = {
  account: <AccountCircleIcon />,
  dashboard: <DashboardIcon />,
  location: <RoomIcon />,
  home: <HomeIcon />,
  build: <BuildIcon />,
  appliances: <SettingsPowerIcon />,
  warranties: <InsertDriveFileIcon />,
  exterior: <HouseIcon />,
  interior: <InteriorIcon />,
  lawnLeisure: <LawnLeisureIcon />,
  furniture: <FurnitureIcon />,
  admin: <ManageAccounts />,
  contacts: <Contact />,
  task: <ListAltIcon />,
  warranty: <WarrantyIcon />,
  addressSearch: <TravelExploreIcon />,
  customers: <GroupIcon />,
  suggestedCustomers: <SupervisedUserCircleIcon />,
  pro: <AdminPanelSettingsIcon />
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'residence',
        path: PATH_DASHBOARD.general.residence,
        icon: ICONS.home
      },
      {
        title: 'exterior',
        path: '/dashboard/home-items?category=exterior',
        icon: ICONS.exterior
      },
      {
        title: 'appliances',
        path: '/dashboard/home-items?category=appliance',
        icon: ICONS.appliances
      },
      {
        title: 'utilities',
        path: '/dashboard/home-items?category=utility',
        icon: ICONS.build
      },
      {
        title: 'Lawn & Leisure',
        path: '/dashboard/home-items?category=lawnAndLeisure',
        icon: ICONS.lawnLeisure
      },
      {
        title: 'Interior',
        path: '/dashboard/home-items?category=interior',
        icon: ICONS.interior
      },
      {
        title: 'Furniture',
        path: '/dashboard/home-items?category=furniture',
        icon: ICONS.furniture
      },
      {
        title: 'tasks',
        path: PATH_DASHBOARD.general.tasks,
        icon: ICONS.task
      },
      {
        title: 'Warranty',
        path: PATH_DASHBOARD.general.warranty,
        icon: ICONS.warranty
      },
      {
        title: 'contacts',
        path: PATH_DASHBOARD.general.contacts,
        icon: ICONS.contacts
      },
      {
        title: 'admin',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.admin,
        children: [
          { title: 'user role', path: PATH_DASHBOARD.admin.role },
          { title: 'master Brands', path: PATH_DASHBOARD.admin.brands },
          { title: 'Items', path: PATH_DASHBOARD.admin.items },
          { title: 'master industry type', path: PATH_DASHBOARD.admin.industryType },
          { title: 'Master Residence', path: PATH_DASHBOARD.admin.homeHacks },
          { title: 'Send Email Notification', path: PATH_DASHBOARD.admin.emailNotication },
          { title: 'Create Blog', path: PATH_DASHBOARD.admin.blog },
          { title: 'Create News', path: PATH_DASHBOARD.admin.news },
          { title: 'Create Coming Soon', path: PATH_DASHBOARD.admin.comingSoon }
        ]
      }
    ]
  }
];

export default sidebarConfig;
